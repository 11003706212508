<template>
    <div class="home-channel">
        <div v-if="isLoading" class="d-flex justify-content-center container-loader">
            <flower-spinner class="loading-component" :animation-duration="2000" :size="30" color="#06C755" />
        </div>
        <div v-if="!isLoading">
            <div class="new-channel">
                <h1 class="h1-title">Tập mới nhất</h1>
                <li v-if="latest_channel && latest_channel.link_youtube" class="latest_channel">
                    <iframe class="mx-auto" :src="this.$getYouTubeEmbedUrl(latest_channel.link_youtube)" frameborder="0" allowfullscreen></iframe>
                </li>
            </div>
            <hr class="m-6">
            <div class="recently-channel">
                <h1 class="h1-title">Các tập gần đây</h1>
                <ul class="ul-channel">
                    <li class="li-channel" v-for="(record, index) in records" :key="index">
                        <iframe class="mx-auto" :src="this.$getYouTubeEmbedUrl(record.link_youtube)" frameborder="0" allowfullscreen></iframe>
                    </li>
                </ul>
                <div id="divpaginate" class="mt-2">
                    <paginate v-if="paginateVisible" :page-count="last_page" :page-range="3" :margin-pages="1"
                        :click-handler="clickCallback" :initial-page="big_search.page" :prev-text="'Prev'"
                        :next-text="'Next'" :container-class="'pagination'" :page-class="'page-item'">
                    </paginate>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import UserRequest from '@/restful/UserRequest';
import Paginate from 'vuejs-paginate-next';
import { FlowerSpinner } from 'epic-spinners';
export default {
    name: "HomeChannel",
    components: {
        FlowerSpinner,
        paginate: Paginate,
    },
    data() {
        return {
            isLoading: false,
            total: 0,
            last_page: 1,
            paginateVisible: true,
            big_search: {
                per_page: 12, // const 
                page: 1,
                order_by: 'id',
                order_direction: 'desc',
            },
            query: '',
            records: null,
            latest_channel: {
                id: null,
                link_youtube: null,
                created_at: null,
                updated_at: null,
            }
        }
    },
    mounted() {
        this.$setDocumentTitle('Channel');
        this.$emitEvent('NameRouting', 'Channel');

        const queryString = window.location.search;
        const searchParams = new URLSearchParams(queryString);
        this.big_search = {
            per_page: parseInt(searchParams.get('per_page')) || 12,
            page: searchParams.get('page') || 1,
            order_by: searchParams.get('order_by') || 'id',
            order_direction: searchParams.get('order_direction') || 'desc',
        }

        this.getDataRecords();
    },
    beforeUnmount() {
        this.$emitEvent('NameRouting', '');
    },
    methods : {
        getDataRecords: async function () {
            this.isLoading = true;
            this.query = `?order_by=${this.big_search.order_by}&order_direction=${this.big_search.order_direction}&page=${this.big_search.page}&per_page=${this.big_search.per_page}`;
            // window.history.pushState({}, null, this.query);
            window.history.replaceState({}, null, this.query);
            try {
                const { data } = await UserRequest.get('user/channel/get-data' + this.query)
                // console.log(data);
                this.records = data.data;
                this.latest_channel = data.latest_channel;
                // console.log(this.latest_channel);
                this.total = data.total;
                this.last_page = data.last_page;
                this.isLoading = false;
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
                this.isLoading = false;
            }
            this.reRenderPaginate();
        },
        reRenderPaginate: function () {
            if (this.big_search.page > this.last_page) this.big_search.page = this.last_page;
            this.paginateVisible = false;
            this.$nextTick(() => { this.paginateVisible = true; });
        },
        clickCallback: function (pageNum) {
            this.big_search.page = pageNum;
        },
    },
    watch: {
        big_search: {
            handler: function () {
                this.getDataRecords();
            },
            deep: true
        },
    }
}
</script>
<style scoped>

.loading-component {
    margin: 150px 0px
}

.home-channel {
    background-color: silver;
    background-image: linear-gradient(180deg, #eeeeee 0%, #ffffff 100%);
    padding: 0px 0px;
}

.new-channel {
    padding-top: 20px;
}

.recently-channel {
    margin-top: 20px;
    background-color: rgb(240, 240, 240);
    padding: 0px 10%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.h1-title {
    text-transform: uppercase;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    color: var(--user-color);
    margin-bottom: 10px;
}

.ul-channel {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 cột bằng nhau */
    gap: 16px; /* Khoảng cách giữa các phần tử */
    list-style: none; /* Xóa dấu chấm trước các phần tử li */
    padding: 0;
    margin: 0;
}

.li-channel {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 3px;
    /* border-radius: 10px; */
    text-align: center;
}

.li-channel iframe {
    width: 100%;
    /* border-radius: 10px; */
    height: auto;
    aspect-ratio: 16 / 9; /* Đảm bảo iframe có tỷ lệ khung hình chuẩn YouTube */
}

.latest_channel {
    padding: 8px;
    text-align: center;
}
.latest_channel iframe {
    width: 60%;
    height: auto;
    border-radius: 10px;
    aspect-ratio: 16 / 9; /* Đảm bảo iframe có tỷ lệ khung hình chuẩn YouTube */
}

@media (max-width: 992px) {
    .ul-channel {
        grid-template-columns: repeat(2, 1fr); /* Chuyển sang 2 cột khi màn hình nhỏ hơn 768px */
    }
}

@media (max-width: 768px) {
    .ul-channel {
        grid-template-columns: 1fr; /* Chuyển sang 1 cột khi màn hình nhỏ hơn 480px */
    }
}


</style>
<template>
    <div :class="{'big-home-top-bar': true, 'big-home-sticky': isSticky }" :style="isHomeIntroductPage ? { backgroundImage: `url(${require('@/assets/home/background.jpg')})` } : {}">
        <div :class="{'home-top-bar-bgr' : isHomeIntroductPage,'home-top-bar':true }">
            <div class="action-bar">
                <div class="action-bar-inner">
                    <div class="contact">
                        <p><span><i class="fa-solid fa-phone"></i></span> <span>Tư vấn & hỗ trợ: <a
                                    href="tel:+840349667218">097 515 9695</a></span></p>
                        <p><span><i class="fa-regular fa-envelope"></i></span> <span><a
                                    href="mailto:lehongphuongweb@gmail.com">lehongphuongweb@gmail.com</a></span></p>
                    </div>
                    <div v-if="!isUserLoggedIn">
                        <router-link :to="{ name: 'HomeRegister' }">
                        <button type="button" class="btn btn-outline-secondary"><i class="fa-solid fa-user-plus"></i> Sign up</button>
                        </router-link>
                        <router-link :to="{ name: 'HomeLogin' }">
                            <button type="button" class="btn btn-outline-secondary">
                                <i class="fa-solid fa-arrow-right-to-bracket"></i> Login
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
            <div :class="{ 'tab-bar': true, sticky: isSticky }" ref="stickyBar">
                <div class="tab-bar-inner">
                    <div class="tab-bar-logo">
                        <router-link :to="{ name: 'HomeIntroduct' }"><img :src="require('@/assets/home/logo.png')" alt="Logo Academy"></router-link>
                    </div>
                    <div class="tab-bar-menu">
                        <ul>
                            <a href="#" @click="gotoHomePublic()">
                                <li data-path="cong-dong bai-viet" class="check-tab tab-li"><span><i class="fa-solid fa-earth-americas"></i></span> <span>Cộng đồng</span></li>
                            </a>
                            <router-link :to="{ name: 'HomeChannel' }">
                                <li data-path="channel" class="check-tab tab-li"><span><i class="fa-brands fa-youtube"></i></span> <span>Channel</span></li>
                            </router-link>
                            <router-link :to="{ name: 'HomeCourse' }">
                                <li data-path="khoa-hoc" class="check-tab tab-li"><span><i
                                            class="fa-solid fa-tv"></i></span> <span>Khóa học</span></li>
                            </router-link>
                            <router-link :to="{ name: 'HomeLibrary' }">
                                <li data-path="library" class="check-tab tab-li"><span><i
                                            class="fa-solid fa-cart-shopping"></i></span> <span>Thư viện</span></li>
                            </router-link>
                            <router-link v-if="isUserLoggedIn" :to="{ name: 'AccountSetting' }">
                                <li class="tab-li"><span><i class="fa-solid fa-user"></i></span> <span>Tài khoản</span></li>
                            </router-link>
                        </ul>
                    </div>
                    <NotifyUser v-if="isUserLoggedIn"></NotifyUser>
                </div>
            </div>
            <div v-if="isHomeIntroductPage" :class="{'container-banner-sticky': isSticky }" class="container-banner">
                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                    <!-- Indicators -->
                    <ol class="carousel-indicators">
                    <li
                        v-for="(image, index) in images"
                        :key="index"
                        :data-target="'#carouselExampleIndicators'"
                        :data-slide-to="index"
                        :class="{ active: index === 0 }"
                    ></li>
                    </ol>

                    <!-- Carousel items -->
                    <div class="carousel-inner">
                    <div
                        v-for="(image, index) in images"
                        :key="index"
                        :class="['carousel-item', { active: index === 0 }]"
                    >
                        <img class="d-block" :src="image" :alt="'Slide ' + (index + 1)" />
                    </div>
                    </div>

                    <!-- Controls -->
                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                    </a>
                </div>
                <!-- <img :src="require('@/assets/home/banner.jpg')" alt="Banner"> -->
            </div>
            <div v-if="name_routing" class="top-bar-routing">
                <div class="subheader" ><span class="subheader-home"><router-link :to="{ name: 'HomeIntroduct' }">Home</router-link></span> <span class="icon-chevron"><i class="fa-solid fa-chevron-right"></i></span> <span>{{ this.$truncatedTitle(name_routing, 100) }}</span></div>
                <!-- <div class="h1-subheader"><h1>{{ this.$truncatedTitle(name_routing, 100) }}</h1></div> -->
            </div>
        </div>
    </div>
</template>
<script>

import NotifyUser from '@/components/home/modal-notify/NotifyUser.vue';
import $ from 'jquery';
import config from '@/config';
export default {
    name: "HomeTopBar",
    components: {
        NotifyUser,
    },
    data() {
        return {
            images: [
                require('@/assets/home/banner.jpg'),
                require('@/assets/home/banner.jpg'),
                require('@/assets/home/banner.jpg'),
                require('@/assets/home/banner.jpg'),
            ],
            isSticky: false,
            stickyTop: 0,
            isHomeIntroductPage: false,
            name_routing:'',
            user: {
                id: null,
                email: null,
                google_id: null,
                is_have_password: null,
                role: null,
                line_user_id: null,
                channel_id: null,
                name: null,
                phone: null,
                avatar: null,
                address: null,
                gender: null,
                date_of_birth: null,
                is_block: null,
                is_delete: null,
                email_verified_at: null,
                created_at: null,
                updated_at: null,
                expires_in: null,
                token_type: null,
                access_token: null,
            },
            isUserLoggedIn: false,
        };
    },
    beforeRouteUpdate(to, from, next) {
        this.checkTabActive();
        next();
    },
    mounted() {
        this.checkTabActive();
        this.stickyTop = this.$refs.stickyBar.offsetTop;
        window.addEventListener("scroll", this.handleScroll);
        this.$onEvent('HomeIntroductPage', (status) => {
            this.isHomeIntroductPage = status;
        })

        this.$onEvent('NameRouting', (name) => {
            this.name_routing = name;
        })
        this.user = JSON.parse(window.localStorage.getItem('user'));
        if (this.user) {
            this.isUserLoggedIn = true; 
        }
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.isSticky = window.scrollY > this.stickyTop;
            this.$emitEvent('eventSticky', this.isSticky);
        },
        checkTabActive: function () {
            $('.check-tab').removeClass('tab-active');
            // var currentLocation = window.location.pathname;
            var currentLocation = window.location.href;
            // console.log();
            $('.check-tab').each(function () {
                var path = $(this).data('path');
                var pathArray = path.split(' ');
                var isActive = pathArray.some(function (item) {
                    return currentLocation.includes(`${config.DOMAIN_CLIENT}/${item}`); // an toàn hơn nhiều 
                });
                if (isActive) {
                    $(this).addClass('tab-active');
                }
            });
        },
        gotoHomePublic:function () { // lí do dùng riêng là khi đứng tại Personal thì click vào HomePublic nó không reload lại
            this.$router.push({ name: 'HomePublic'})
            .then(() => {
                window.location.reload();
            });
        }
    },
    watch: {
        $route() {
            this.checkTabActive();
        }
    }
};
</script>

<style src="./home-top-bar-responsive.css"></style>
<style scoped>
a:hover {
    text-decoration: none;
}

/* home-top-bar */
.big-home-top-bar {
    grid-template-columns: repeat(2, 1fr);
    list-style-type: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* Tạo viền bo tròn cho ul nếu cần */
}

/* background dành cho các trang còn lại */
.home-top-bar {
    background-color: #F7F7F7;
    padding-bottom: 10px;
}

/* background dành cho trang giới thiệu */
.home-top-bar-bgr { 
    background-color: #ffffffcc;
}

/* action-bar */
.action-bar {
    /* background: red; */
    /* border: 1px solid red; */
    padding: 10px 10%;
}

.action-bar-inner {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    font-size: 14px !important;
}

.action-bar-inner button {
    font-size: 14px ;
    margin-left: 10px;
}

.action-bar-inner .contact {
    display: flex;
}

.action-bar-inner .contact p {
    margin-right: 20px;
}

/* sticky */
.tab-bar {
    top: 0;
    width: 100%;
    padding: 0px 10%;
    /* background-color: green; */
    /* border: 1px solid red; */
}

.big-home-sticky {
    /* margin-bottom: 30px; */
}
.sticky {
    position: fixed;
    background-color: #d8d8d8;
    top: 0;
    width: 100%;
    z-index: 1000;
    /* background-color: green; */
}


/*  */
.tab-bar-inner {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 6px 0px;
}

.tab-bar .tab-bar-logo img {
    max-height: 80px;
}
.sticky .tab-bar-logo img {
    max-height: 50px;
}

.tab-bar-menu {
    width: 100%;
    margin-left: 10%;
}
.tab-bar-menu ul {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tab-li {
    /* margin-left: 20px; */
    padding: 10px;
    position: relative;
    padding: 10px 20px;
    cursor: pointer;
}

.tab-li i {
    margin-right: 5px;
}

.tab-li::after {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0;
    height: 5px;
    background: #1E1E1E;
    transition: width 0.3s;
}

.tab-li:hover::after {
    width: 100%;
    transition: width 0.3s;
}

/* active */
.tab-li.tab-active::after {
    width: 100%;
}

/* fix slide phình ra */
.container-banner {
    margin: 0px 10%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.container-banner .carousel.slide {
    max-width: 1200px; /* Giới hạn chiều rộng tối đa */
}
.carousel-inner {
    width: 100%; /* Đảm bảo carousel chiếm toàn bộ chiều rộng của container */
}
.carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Đảm bảo ảnh lấp đầy không gian mà không bị méo */
}
/* fix slide phình ra */

.container-banner-sticky {
    padding-top: 100px;
}

/* top-bar-routing */
.top-bar-routing {
    margin: 0px 10%;
    margin-top: 10px;
}

.subheader {
    color: silver;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.subheader-home a:hover {
    text-decoration: underline;
    color: silver;
}
.subheader span {
    margin-right: 20px;
}

.subheader .icon-chevron {
    font-size: 10px;
}
.h1-subheader {
    color: #565656;
    font-size: 48px;
    line-height: 58px;
    font-weight: 500;
    letter-spacing: 0px;
}
</style>
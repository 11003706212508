<template>
    <div id="main">
        <div class="row m-0 pl-1 pr-1">
            <div class="col-md-3 col-12 p-1 pt-0 left-bar-room">
                <!-- Chapter -->
                <div v-if="isLoadingChapter" class="d-flex justify-content-center container-loader">
                    <flower-spinner class="loading-component" :animation-duration="2000" :size="30" color="#06C755" />
                </div>
                <div class="p-2 list-chapter-lesson" v-if="!isLoadingChapter">
                    <p class="title-left-bar" data-toggle="collapse" href="#container-chapter-lesson" role="button"
                        aria-expanded="false" aria-controls="container-chapter-lesson"> <span><i
                                class="fa-solid fa-bars-staggered"></i></span> Danh sách bài học</p>
                    <div class="collapse show" id="container-chapter-lesson">
                        <div v-for="(chapter, index) in chapters" :key="index" class="mb-3">
                            <p :class="{ 'title-chapter-active': activeChapter(chapter) }"
                                class="title-chapter mt-1 mb-1 " data-toggle="collapse"
                                :href="'#chapter-group-' + chapter.id" role="button" aria-expanded="false"
                                :aria-controls="'#chapter-group-' + chapter.id">
                                <span><i class="fa-solid fa-caret-right" v-if="activeChapter(chapter)"></i> {{ index + 1
                                    }}. {{ chapter.chapter_name }}</span>
                            </p>
                            <ul class="ml-6 collapse" :class="{ 'show': activeChapter(chapter) }"
                                :id="'chapter-group-' + chapter.id">
                                <li @click="gotoLesson(lesson)" v-for="(lesson, index2) in chapter.lessons"
                                    :key="index2">
                                    <p class="title-lesson mb-2" :class="{ 'lesson-active': lesson.id == lesson_id }">
                                        <span v-if="lesson.id == lesson_id"><i
                                                class="fa-solid fa-caret-right"></i></span> <span>{{
                                                    index + 1 }}.{{ index2 + 1 }}.</span> {{ lesson.lesson_name }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- Document -->
                <div v-if="isLoadingDocument" class="d-flex justify-content-center container-loader">
                    <flower-spinner class="loading-component" :animation-duration="2000" :size="30" color="#06C755" />
                </div>
                <div class="mt-3 mb-2 p-2 list-document" v-if="!isLoadingDocument">
                    <p class="title-left-bar" data-toggle="collapse" href="#container-document" role="button"
                        aria-expanded="false" aria-controls="container-document"> <span><i class="fa-solid fa-file"></i></span> Tài liệu học tập</p>
                    <div class="collapse show" id="container-document">
                        <ul class="ml-3">
                            <li v-for="(doc, index) in documents" :key="index"><a :href="doc.document_file"><span>{{ index+1 }}</span>. {{ doc.document_name }}</a></li>
                        </ul>
                    </div>
                </div>

            </div>
            <div class="col-md-9 col-12 p-1 pt-0 right-bar-room">
                <div v-if="!isChildRoute">
                    <div v-if="isLoadingChapter" class="d-flex justify-content-center container-loader">
                        <flower-spinner class="loading-component" :animation-duration="2000" :size="30"
                            color="#06C755" />
                    </div>
                    <div class="pl-3 pr-3 pt-3 pb-3 introduct-course" v-if="!isLoadingChapter">
                        <p class="text-center name-course"><i class="fa-solid fa-chalkboard"></i> {{ cource.course_name
                            }}</p>
                        <div class="row mt-2 pl-3 pr-3 infor-intro">
                            <p><span class="color-span-first"><i class="fa-solid fa-layer-group"></i> Tổng số
                                    Chapter</span> : <span>{{ cource.total_chapter }}</span></p>
                            <p><span class="color-span-first"><i class="fa-solid fa-video"></i> Tổng số Bài học</span> :
                                <span>{{ cource.total_lesson }}</span></p>
                            <p><span class="color-span-first"><i class="fa-solid fa-calendar-day"></i> Ngày tạo</span> : <span>{{
                                this.$formatDate2(cource.created_at) }}</span></p>
                            <p><span class="color-span-first"><i class="fa-solid fa-calendar-day"></i> Ngày cập nhật</span> : <span>{{
                                this.$formatDate2(cource.created_at) }}</span></p>
                        </div>
                        <!-- <div class="row mt-2">
                            <div class="col-12 image-intro">
                                <img :src="cource.course_image ? cource.course_image : require('@/assets/admin/image-default.jpg')">
                            </div>
                        </div> -->
                        <div class="row mt-2">
                            <div class="col-12 video-intro">
                                <iframe v-if="cource.course_trailer"
                                    :src="this.$getYouTubeEmbedUrl(cource.course_trailer)" frameborder="0"
                                    allowfullscreen></iframe>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <span class="color-span-first span-title"><strong><i class="fa-solid fa-heading"></i> Giới thiệu về khóa học</strong></span>
                                <p v-html="cource.course_introduce"></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isChildRoute" class="inner-lesson">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import UserRequest from '@/restful/UserRequest';
import { FlowerSpinner } from 'epic-spinners';

export default {
    name: "MyClassRoom",
    components: {
        FlowerSpinner,
    },
    setup() {
        document.title = "Phòng học | Tech BIM"
    },
    data() {
        return {
            course_id: null,
            lesson_id: null,
            isLoadingChapter: false,
            isLoadingDocument: false,
            cource: {
                id: null,
                course_name: null,
                course_slug: null,
                course_price: null,
                course_image: null,
                course_trailer: null,
                course_introduce: null,
                is_block: null,
                is_delete: null,
                created_at: null,
                updated_at: null,
                total_chapter: null,
                total_lesson: null,
            },
            chapters: null,
            documents: null,
        }
    },
    computed: {
        isChildRoute() { // HAY , ĐỂ KHI ĐI VÀO COMPONENT CON THÌ ẨN PHẦN GIỚI THIỆU ĐI // Để cho đỡ chiếm chỗ , chỉ hiển thị phần video của lesson và phần chat // Kiểm tra nếu route hiện tại không phải là route chính
            return this.$route.name !== 'MyClassRoom'; // Thay 'MyClassRoom' bằng tên route chính
        }
    },
    mounted() {
        this.$emitEvent('eventTitleHeader', 'Phòng học');
        this.$emitEvent('eventActiveTab', '');

        this.course_id = this.$extractIdFromSlug(this.$route.params.slug_name_course);
        // console.log('Record ID:', this.course_id);

        this.getListChapterLesson();
        this.getDocumentOfCourse();
        this.getLessonId();

    },

    methods: {
        gotoLesson: function (record) {
            this.$router.push({ name: 'LessonAndChat', params: { slug_name_lesson: record.lesson_slug + '-id-record-' + record.id } });
        },
        getListChapterLesson: async function () {
            this.isLoadingChapter = true;
            try {
                const { data } = await UserRequest.get('user/my-course/get-data/' + this.course_id)
                // console.log(data);
                this.cource = data.cource;
                this.chapters = data.chapters;
                this.isLoadingChapter = false;
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
                this.isLoadingChapter = false;
            }
        },
        getDocumentOfCourse: async function () {
            this.isLoadingDocument = true;
            try {
                const { data } = await UserRequest.get('user/document/get-data?course_id=' + this.course_id)
                // console.log(data);
                this.documents = data;
                this.isLoadingDocument = false;
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
                this.isLoadingDocument = false;
            }
        },
        getLessonId: function () {
            // left-bar
            if (this.$route.params.slug_name_lesson) {
                // Nếu có slug_name_lesson trong route.params thì lấy lesson_id và gọi getLesson
                this.lesson_id = this.$extractIdFromSlug(this.$route.params.slug_name_lesson);
                // console.log('this.lesson_id', this.lesson_id);
            }
            this.$watch('$route.params.slug_name_lesson', (newVal, oldVal) => {
                if (newVal && newVal !== oldVal) {
                    this.lesson_id = this.$extractIdFromSlug(newVal);
                }
            });
            // left-bar
        },
        activeChapter: function (chapter) {
            return chapter.lessons.some(lesson => lesson.id == this.lesson_id)
        },
    },
    watch: {

    },
}
</script>

<style scoped>

.inner-lesson {
    height: 100%;
}

/* left bar room */
.left-bar-room {
    /* height: 90vh; */
    max-height: 90vh;
    overflow: hidden;
    overflow-y: scroll;
}

.list-chapter-lesson {
    border-radius: 5px;
    box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
    /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */
}

.list-document {
    border-radius: 5px;
    box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
    /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */
}

/* title-left-bar */
.title-left-bar {
    font-size: 19px;
    color: var(--user-color);
    transition: all 0.5s ease;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}

.title-left-bar:hover {
    color: #06C755;
    transition: all 0.5s ease;
}


/* title-chapter */
.title-chapter {
    align-items: center;
    align-content: center;
    display: flex;
    line-height: 17px;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.5s ease;
    text-transform: capitalize;
    cursor: pointer;
}


.title-chapter-active {
    color: #06C755;
}

.title-chapter:hover {
    color: #06C755;
    transition: all 0.5s ease;
}

/* title-lesson */
.title-lesson {
    font-size: 14px;
    transition: all 0.5s ease;
    text-transform: capitalize;
    line-height: 17px;
    cursor: pointer;
}

.lesson-active {
    color: #007BFF;
}

.title-lesson:hover {
    color: #007BFF;
    transition: all 0.5s ease;
    /* text-decoration: underline; */
}

/* loading */
.container-loader {
    height: 300px;
    align-items: center;
    align-content: center;
    display: flex !important;
    justify-content: center;
}

.container-loader .loading-component {
    margin: 0 !important;
}

/* loading */

/* introduct-course */
.introduct-course {
    border-radius: 5px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
}

.name-course {
    font-size: 22px;
    font-weight: bold;
    color: #06C755;
}

.infor-intro {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.span-intro {
    font-weight: bold;
    font-size: 18px;
}


.color-span-first {
    color: #007BFF;
}

.image-intro {
    display: flex;
    justify-content: center;
}
.image-intro img {
    width: 50%;
    border-radius: 10px;
}

.video-intro iframe {
    border-radius: 10px;
    width: 100%;
    height: 300px;
}


.div_microphone {
    cursor: pointer;
}

.form-control {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: 0.2rem;
    line-height: 1.5;
}

@media screen and (min-width: 1201px) {
    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .coverRecord {
        min-width: 150px;
    }

    .displaytext {
        min-width: 150px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;
    }

    table img {
        min-width: 60px;
        min-height: 60px;
        /* max-width: 60px; */
        /* max-height: 60px; */
        object-fit: cover;
    }

    td .fa-solid {
        font-size: 20px;
    }

}

@media screen and (max-width: 1200px) {
    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .coverRecord {
        min-width: 120px;
    }

    .displaytext {
        min-width: 100px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 11px;
    }

    .fa-solid {
        font-size: 15px;
    }

    table img {
        min-width: 50px;
        min-height: 50px;
        /* max-width: 50px;
        max-height: 50px; */
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 8px;
    }

    .form-control,
    .pagination {
        font-size: 12px !important;
    }

    #main {
        padding: 1% 1%;
        margin: 0;
    }
}

@media screen and (max-width: 992px) {
    .title-left-bar {
        font-size: 15px;
    }

    .colorTitle {
        font-size: 14px;
    }

    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .coverRecord {
        min-width: 140px;
    }

    .displaytext {
        min-width: 110px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 11px;
    }

    .fa-solid {
        font-size: 16px;
    }

    table img {
        min-width: 50px;
        min-height: 50px;
        max-width: 50px;
        max-height: 50px;
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 8px;
    }

    .form-control,
    .pagination {
        font-size: 12px !important;
    }

    #main {
        padding: 1% 1%;
        margin: 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        padding-left: 0;
        padding-right: 10px;
    }

    .btn {
        padding: 0px 4px;
        margin-top: 3px;
    }

    .input-group-text {
        padding: 0 8px;
    }
}

@media screen and (max-width: 768px) {

    .title-left-bar,
    .colorTitle {
        font-size: 13px;
    }

    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .coverRecord {
        min-width: 100px;
    }

    .displaytext {
        min-width: 90px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 11px;
    }

    .fa-solid {
        font-size: 13px;
    }

    table img {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 5px;
    }

    .form-control,
    .pagination {
        font-size: 12px !important;
    }

    #page {
        min-width: 45px;
    }

    .form-control {
        padding: 1px 1px;
    }

    #main {
        padding: 1% 1%;
        margin: 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        padding-right: 5px;
    }

    .btn {
        padding: 0px 4px;
        margin-top: 3px;
    }

    .input-group-text {
        padding: 0 4px;
    }

    .input-group-prepend {
        font-size: 12px;

    }
}

@media screen and (max-width: 576px) {

    .title-left-bar,
    .colorTitle {
        font-size: 12px;
    }

    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .nameMember {
        margin-left: 8px;
    }

    .coverRecord {
        min-width: 100px;
    }

    .displaytext {
        min-width: 70px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 10px;
    }

    .fa-solid {
        font-size: 10px;
    }

    table img {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 4px;
    }

    .form-control,
    .pagination {
        font-size: 10px !important;
    }

    .form-control {
        padding: 1px 1px;
        height: 25px;
    }

    #page {
        min-width: 45px;
    }

    #main {
        padding: 1% 1%;
        margin: 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        padding-right: 5px;
    }

    .btn {
        padding: 0px 4px;
    }

    .input-group-text {
        padding: 0 0.5px;
    }

    .input-group-prepend {
        font-size: 11px;

    }
}

@media screen and (max-width: 424px) {

    .title-left-bar,
    .colorTitle {
        font-size: 11px;
    }

    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .nameMember {
        margin-left: 8px;
    }

    .coverRecord {
        min-width: 80px;
    }

    .displaytext {
        min-width: 70px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 9px;
    }

    .fa-solid {
        font-size: 10px;
    }

    table img {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 4px;
    }

    .form-control,
    .pagination {
        font-size: 9px !important;
    }

    .form-control {
        padding: 0.5px 0;
        height: 25px;
    }

    #page {
        min-width: 40px;
    }

    #main {
        padding: 1% 1%;
        margin: 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        padding-right: 0;
    }

    .btn {
        padding: 0px 4px;
    }

    .input-group-text {
        padding: 0 0.5px;
    }

    .input-group-prepend {
        font-size: 10px;

    }

    #main .ml-2 {
        margin-left: 3px !important;
    }
}
</style>
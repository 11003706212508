<template>
	<div>
		<a href="https://www.facebook.com/messages/t/100075925281383" target="_blank" class="messenger social"> <img src="@/assets/messenger.png" alt="messenger" /></a>
		<a href="https://zalo.me/0975159695" target="_blank" class="zalo social"> <img src="@/assets/zalo.png" alt="messenger" /> </a>
	</div>
</template>

<script>
export default {
	data() {
		return {

		};
	},
	methods: {

	},
	mounted() {

	},
	beforeMount() {

	},
};
</script>

<style scoped>
.social img {
	width: 60px;
	height: 60px;
	border-radius: 100%;
}
.messenger {
	bottom: 80px;
}
.zalo {
	bottom: 155px;
}
.social {
	display: flex;
	border-radius: 100%;
	position: fixed;
	right: 20px;
	cursor: pointer;
	font-size: 16px;
	z-index: 1000;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	transition: opacity 0.3s ease;
	border: 2px solid white;
}


.p-chapter-lesson {
    font-size: 12px;
    margin-bottom: 10px;
}

/* .home-course {
    padding: 4% 15% !important;
} */

.p-chapter-lesson {
    font-weight: bold;
}

.p-author {
    font-weight: bold;
    display: flex;
    align-items: center;
    align-content: center;
}

.h1-price {
    color: green;
    font-weight: bold !important;
}

li.course-item > img {
    width: 200px;
    height: 200px;
    min-width: 200px;
    min-height: 200px;
    object-fit: cover;
    /* border-radius: 10px; */
    margin-right: 20px;
}

/* search-sort */
.search-sort > div {
    margin-right: 20px;
}

@media screen and (min-width: 1301px) {
    
}

@media screen and (max-width: 1300px) {
   
}

@media screen and (max-width: 1250px) {

}

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 1150px) {
	.social img {
		width: 50px !important;
		height: 50px !important;
		border-radius: 100% !important;
	}
	.messenger {
		bottom: 80px;
	}
	.zalo {
		bottom: 140px;
	}
}

@media screen and (max-width: 1100px) {
   
}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 992px) {
   
}

@media screen and (max-width: 900px) {
    
}

@media screen and (max-width: 950px) {

}

@media screen and (max-width: 800px) {
	.social img {
		width: 40px !important;
		height: 40px !important;
		border-radius: 100% !important;
	}
	.messenger {
		bottom: 70px;
	}
	.zalo {
		bottom: 120px;
	}
}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 750px) {

}

@media screen and (max-width: 700px) {
    
}

@media screen and (max-width: 650px) {
 
}

@media screen and (max-width: 650px) {
    
}

@media screen and (max-width: 576px) {

}

@media screen and (max-width: 550px) {
   
}

@media screen and (max-width: 500px) {

}

@media screen and (max-width: 450px) {
	.social img {
		width: 35px !important;
		height: 35px !important;
		border-radius: 100% !important;
	}
	.messenger {
		bottom: 60px;
	}
	.zalo {
		bottom: 100px;
	}
}

@media screen and (max-width: 400px) {

}

@media screen and (max-width: 374px) {

}

@media screen and (max-width: 350px) {

}
</style>
<template>
	<div class="demo">
		<div class="container-add-cmt">
			<div class="input-add-cmt">
				<Mentionable :keys="['@']" :items="items" offset="6" filtering-disabled @open="loadIssues()"
				@search="loadIssues($event)">
				<textarea v-model="text" rows="1" class="input"
					placeholder="Nhập văn bản rồi nhập @ để nhắc đến người dùng."></textarea>
				<template #no-result>
					<div class="dim" style="padding: 5px;">
						{{ loading ? 'Loading...' : 'No result' }}
					</div>
				</template>

				<template #item="{ item }">
					<div class="issue" style="display: flex; align-items: center;">
						<img class="avatar-user"
							:src="item.avatar ? item.avatar : require('@/assets/home/avatar-default.jpg')" alt="Avatar">
						<span class="dim">
							{{ item.user_name }} ({{ item.name }})
						</span>
					</div>
				</template>
			</Mentionable>
			</div>
			<div class="btn-add-cmt">
				<button @click="updateComment()" type="button" class="btn btn-secondary"><i class="fa-solid fa-paper-plane"></i></button>
			</div>
		</div>
		<!-- <div class="preview-htmltext" v-html="htmlText"></div> -->
	</div>
</template>

<script>
import { Mentionable } from 'vue-mention'
import UserRequest from '@/restful/UserRequest';

import { 
	setDoc, 
	serverTimestamp, 
	doc, 
	addDoc, 
	// updateDoc, 
	// deleteDoc, 
	// query, 
	// orderBy, 
	// limit, 
	// onSnapshot, 
} from 'firebase/firestore';
import { 
	notifiesRef, 
	commentsRef, 
} from '@/firebase';

export default {
	components: {
		Mentionable,
	},
	props: {
		issues: Array,
		comment_id: String,
		comment_parent_id: String,
		lesson_id: Number,
		comment_content: String,
	},
	data() {
		return {
			text: this.comment_content,
			htmlText: '',
			items: [],
			loading: false,
			dataSubmit : {
				lesson_id: null,
				comment_id: null,
				comment_content: null,
				comment_parent_id: null,
				from_user_id: null,
				to_user_ids: null,
			},
			user: {
				id: null,
				email: null,
                google_id: null,
                is_have_password: null,
				user_name: null,
				role: null,
				is_delete: null,
				is_block: null,
				name: null,
				phone: null,
				email_verified_at: null,
				created_at: null,
				updated_at: null,
				access_token: null,
				token_type: null,
				expires_in: null,
			},
		}
	},
    async mounted() {
		this.user = JSON.parse(window.localStorage.getItem('user'));
	},
	methods: {
		updateComment: async function () {
            try {
				this.dataSubmit = {
					comment_id: this.comment_id,
					comment_content: this.text,
				};

				// console.log(this.dataSubmit);

				const { data, messages } = await UserRequest.post('user/comment/update', this.dataSubmit);
				// console.log(data);
				this.updateCommentToFirebase(data, messages);
				this.addNotifyToFirebase(data, messages);
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
            }
        },
		async updateCommentToFirebase(data, messages) { // thông báo lên lại cho firebase để nó phát ra realtime 
            try { // vẫn phải đảm bảo là giữ nguyên data.comment_id để nó get phần tử đầu tiên để check  
                await setDoc(doc(commentsRef, data.comment_id), {
                    fb_lesson_id: data.lesson_id,
                    fb_from_user_id: data.comment_from_user_id,
                    fb_to_user_ids: data.comment_to_user_ids,
                    fb_content: data.comment_content,
                    fb_parent_id: data.comment_parent_id,
                    fb_created_at: serverTimestamp(), 
                    fb_updated_at: serverTimestamp(), 
                });
				this.$emitEvent('eventSuccess', messages[0]);
				this.text = '';
            } catch (error) {
                console.error("Error adding document: ", error);
            } finally {
				this.$emitEvent('closeEditCmtBox');
			}
        },
		async addNotifyToFirebase(data) {
            try {
                await addDoc(notifiesRef, {
                    from_user_id: data.comment_from_user_id,
                    ids_notify_to: data.ids_notify_to,
                    fb_created_at: serverTimestamp(), 
                    fb_updated_at: serverTimestamp(), 
                });
            } catch (error) {
                console.error("Error adding notify document: ", error);
            }
        },
		async loadIssues(searchText = null) {
			this.loading = true
			this.items = await this.fetchIssues(searchText)
			this.loading = false
		},
		fetchIssues(searchText = null) {
			return new Promise(resolve => {
				setTimeout(() => {
					if (!searchText) {
						resolve(this.issues)
					} else {
						const reg = new RegExp(searchText, 'i')
						resolve(this.issues.filter(issue => reg.test(issue.user_name)))
					}
				}, 100)
			})
		},
		formatText(newValue) {
			return newValue.replace(/@(\w+)/g, '<span class="user_class_mention">@$1</span>');
		}
	},
	watch: {
		text(newValue) {
			this.htmlText = this.formatText(newValue);
		},
		comment_content(newVal) {
			this.text = newVal;
		}
	}
}
</script>

<style>
/* LƯU Ý : Nếu bỏ css này vào style scope sẽ không được */
.user_class_mention {
	background-color: #f8f8f8;
	font-weight: bold;
	color: red;
	padding: 3px;
	border-radius: 6px;
}
</style>

<style scoped>
.demo {
	width: 100%;
	margin: 6px 0;
}

.input {
	width: 100%;
	border: #ccc 1px solid;
	border-radius: 6px;
	resize: vertical;
	min-height: 40px;
	padding: 6px;
	box-sizing: border-box;
	line-height: 1.2em;
	font-size: inherit;
}

.issue {
	padding: 4px 6px;
	border-radius: 4px;
	cursor: pointer;
	margin: 0px 5px;
	margin-top: 5px;
}

.issue:last-child {
	margin-bottom: 5px;
}

.mention-selected .issue {
	/* background: rgb(139, 212, 255); */
	background: #AFE1CB;
}

.dim {
	color: #666;
}

.preview-htmltext {
	font-family: monospace;
	/* white-space: pre-wrap; */
	margin-top: 12px;
	padding: 12px;
	border: 1px solid silver;
	/* background: #f8f8f8; */
	border-radius: 6px;
}

.avatar-user {
	width: 40px;
	height: 40px;
	object-fit: cover;
	object-position: center;
	border-radius: 50%;
	margin-right: 10px;
}

/* container-add-cmt */
.container-add-cmt {
	display: flex;
	justify-content: space-between;
}
.input-add-cmt {
	width: calc(100% - 50px);
	margin-right: 10px;
}

</style>

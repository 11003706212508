<template>
    <div class="home-course">
        <div v-if="isLoading" class="d-flex justify-content-center container-loader">
            <flower-spinner class="loading-component" :animation-duration="2000" :size="30" color="#06C755" />
        </div>
        <div v-if="!isLoading" class="parent">
            <div class="left-bar">
                <div class="left-course">
                    <div class="course-name"> {{ record.cource.course_name }}</div>
                    <div v-if="record.cource.course_trailer" class="video-container mt-2">
                        <iframe :src="this.$getYouTubeEmbedUrl(record.cource.course_trailer)" frameborder="0"
                            allowfullscreen></iframe>
                    </div>
                    <div v-for="(chapter, index) in record.chapters" :key="index">
                        <div class="uppercase bold mt-3">CHAPTER {{ chapter.position }} : {{ chapter.chapter_name }}
                        </div>
                        <ul class="list-chapter">
                            <li v-for="(lesson, index2) in chapter.lessons" :key="index2">{{ lesson.lesson_name }}</li>
                        </ul>
                    </div>
                    <div class="course-introduce" v-html="record.cource.course_introduce"></div>
                </div>
                <div class="instructor-card">
                    <div class="instructor-image">
                        <img :src="require('@/assets/home/avatar-logo.jpg')" alt="Instructor Image">
                    </div>
                    <div class="instructor-info">
                        <div class="instructor-title">Giảng viên</div>
                        <div class="instructor-name">Lê Hồng Phương</div>
                        <div class="instructor-description">
                            <strong>Giảng viên:</strong> KTS Lê Hồng Phương với hơn 10 năm kinh nghiệm giảng dạy và làm
                            các dự án sử dụng Corona Render và UAE5
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-bar">
                <div>
                    <img v-if="showThumbnail" class="course-thumbnail"
                        :src="record.cource.course_image ? record.cource.course_image : require('@/assets/home/item-source.jpg')"
                        alt="Course Thumbnail">
                    <p class="course-price" v-if="record.cource.course_price"> <span><i
                                class="fa-solid fa-tags"></i></span> {{ this.$formatNumber(record.cource.course_price)
                        }} VNĐ</p>
                    <p class="course-detail mt-1 mb-2"><span class="mr-1"><i class="fa-solid fa-bookmark"></i></span>
                        Tên khóa học: {{ record.cource.course_name }}</p>
                    <p class="course-detail mt-1 mb-2"><span class="mr-1"><i class="fa-solid fa-user-tie"></i></span>
                        Giảng viên: 10 năm kinh nghiệm</p>
                    <p class="course-detail mt-1 mb-2"><span class="mr-1"><i class="fa-solid fa-layer-group"></i></span>
                        Chapters: {{ record.cource.total_chapter }}</p>
                    <p class="course-detail mt-1 mb-2"><span class="mr-1"><i class="fa-solid fa-video"></i></span>
                        Lessons: {{ record.cource.total_lesson }}</p>
                    <p class="course-detail mt-1 mb-2"><span class="mr-1"><i class="fa-solid fa-file-lines"></i></span>
                        Có file thực hành kèm theo</p>
                    <p class="course-detail mt-1 mb-2"><span class="mr-1"><i class="fa-solid fa-life-ring"></i></span>
                        Hỗ trợ trực tiếp từ giáo viên</p>
                    <p class="course-detail mt-1 mb-2"><span class="mr-1"><i
                                class="fa-solid fa-mobile-screen-button"></i></span> Học trên mọi thiết bị</p>
                </div>
                <hr>
                <div class="payment">
                    <p class="course-payment mb-2 text-success" > ĐẶT MUA KHÓA HỌC</p>
                    <p class="mb-2 payment-infor"><span><i class="fa-solid fa-headset"></i></span> SĐT LIÊN HỆ TƯ VẤN : <strong>0123456789</strong></p>
                    <p class="mb-2 payment-infor"><span><i class="fa-solid fa-envelope"></i></span> EMAIL : <a
                            href="mailto:lehongphuongweb@gmail.com"><strong>lehongphuongweb@gmail.com</strong></a></p>
                    <p class="mb-2 payment-infor"><strong>QUÉT MÃ QR ĐỂ LIÊN HỆ</strong></p>
                    <img class="zalo-qr-group" :src="require('@/assets/contact-zalo.jpg')" alt="Course Thumbnail">
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import UserRequest from '@/restful/UserRequest';
import { FlowerSpinner } from 'epic-spinners';

export default {
    name: "HomeCourseDetail",
    components: {
        FlowerSpinner,
    },
    data() {
        return {
            course_id: null,
            isLoading: false,
            record: {
                cource: {
                    id: null,
                    course_name: null,
                    course_slug: null,
                    course_price: null,
                    course_image: null,
                    course_trailer: null,
                    course_introduce: null,
                    is_block: null,
                    is_delete: null,
                    created_at: null,
                    updated_at: null,
                    total_chapter: null,
                    total_lesson: null,
                },
                chapters: null,
            },
            showThumbnail: true,
        }
    },
    mounted() {
        this.$setDocumentTitle('Chi tiết khóa học');
        this.$emitEvent('NameRouting', 'Courses');

        this.course_id = this.$extractIdFromSlug(this.$route.params.slug_name_course);
        // console.log('Record ID:', this.course_id);

        this.getDataRecord();

        // v-if thumbnail 
        // window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        },
        getDataRecord: async function () {
            this.isLoading = true;
            window.history.replaceState({}, null, this.query);
            try {
                const { data } = await UserRequest.get('user/course/get-data/' + this.course_id)
                this.record = data;
                this.isLoading = false;
                this.$emitEvent('NameRouting', this.record.cource.course_name);
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
                this.isLoading = false;
            }
        },
        handleScroll() {
            const scrollPosition = window.scrollY; // Lấy vị trí hiện tại của cuộn trang

            if (scrollPosition > 100) { // Khi cuộn xuống quá 100px thì ẩn ảnh
                this.showThumbnail = false;
            } else {
                this.showThumbnail = true; // Khi cuộn lên thì hiện lại ảnh
            }
        },
    },
    beforeUnmount() {
        this.$emitEvent('NameRouting', '');
        window.removeEventListener("scroll", this.handleScroll);
    },
}
</script>
<style scoped src="./homecoursedetail-responsive.css"></style>
<style scoped>
.home-course {
    padding: 0px 10%;
    background-color: #ECE9E9;
    padding-top: 20px;
    padding-bottom: 20px;
}

.parent {
    display: flex;
    justify-content: space-between;
}

.left-bar {
    flex: 8;
    margin-right: 10px;
}

.left-course {
    background-color: #FFFFFF;
    padding: 40px 20px;
}

.right-bar {
    flex: 4;
    margin-left: 10px;
    background-color: #FFFFFF;
    padding: 10px;
    height: fit-content;

    position: sticky;
    top: 100px;
}

.course-introduce {
    font-size: 20px;
}

/* video */
.video-container {
    max-width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* course-name */
.course-name {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    letter-spacing: 0px;
    /* margin-top: 30px; */
    margin-bottom: 15px;
    font-weight: bold;
}

.list-chapter {
    padding-left: 30px;
}

.list-chapter li {
    list-style: initial;
    margin: 5px 0px;
    font-size: 15px;
}

/* intro-teacher */
.instructor-card {
    display: flex;
    align-items: center;
    padding: 20px;
    /* border-radius: 8px; */
    border: 1px solid #ddd;

    margin-top: 30px;
    padding: 40px;
    background-color: #F7F8FA;
}

.instructor-image img {
    width: 80px;
    height: 80px;
    max-width: none !important;
    object-fit: cover;
    border-radius: 100%;
}

.instructor-info {
    margin-left: 15px;
}

.instructor-title {
    font-size: 14px;
    color: #888;
}

.instructor-name {
    font-size: 18px;
    color: #4A90E2;
    font-weight: bold;
}

.instructor-description {
    font-size: 14px;
    color: #333;
    margin-top: 8px;
}

.instructor-description strong {
    font-weight: bold;
}

/* course-price */
.course-price {
    margin-bottom: 20px;
    text-align: left;
    color: #D41701;
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;

}

/* payment */
.course-payment {
    margin-bottom: 20px;
    text-align: left;
    color: black;
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
}

.zalo-qr-group {
    width: 60%;
}

/* loading */
.container-loader {
    height: 300px;
    align-items: center;
    align-content: center;
    display: flex !important;
    justify-content: center;
}
.container-loader .loading-component {
    margin: 0 !important;
}

/* loading */
</style>
<template>
	<div id="header" class="row">
		<div class="title col-md-4 col-9">
			<span> <i class="fa-solid fa-graduation-cap"></i> Tech BIM</span><span>/</span>
			<span v-html="title"></span>
		</div>
		<div id="contact" class="col-md-8 col-3 justify-content-end">
			<div class="col-md-3 float-right d-none d-lg-block">
				<li class="list_contact ">
					<a class="facebook" href="https://www.facebook.com/profile.php?id=100075925281383" target="_blank"><i
							class="fa-brands fa-square-facebook"></i></a>
					<a class="instagram" href="https://www.instagram.com"
						target="_blank"><i class="fa-brands fa-square-instagram"></i></a>
					<a class="linkedin" href="https://www.linkedin.com" target="_blank"><i
							class="fa-brands fa-linkedin"></i></a>
				</li>
			</div>
			<div class="email-phone d-none d-lg-block col-md-6">
				<li class="contact_email"><a href="mailto:lehongphuongweb@gmail.com"><i
							class="fa-solid fa-envelope"></i> <span class="hidde">Email:</span>
						<span> lehongphuongweb@gmail.com</span></a></li>
				<li class="contact_phone"><a href="tel:0975159695"><i class="fa-solid fa-phone"></i> <span
							class="hidde">Hotline
							24/7 Phone:</span>
						<span> 0975159695</span></a></li>
			</div>
			<!-- <div class="col-3 row ml-8 float-right"> -->
			<div class="notify-user ml-8">
				<!-- Modal Open notify -->
				<NotifyUser></NotifyUser>
			</div>
			<li class="contact_help d-none d-lg-block"><a href="#"><i class="fa-regular fa-circle-question"></i> <span
						class="hidde">Help</span>
				</a></li>
			<!-- </div> -->
		</div>
	</div>
</template>

<script>

import NotifyUser from '@/components/user/my-class-room/NotifyUser.vue';

export default {
	name: "CommonHeader",
	data() {
		return {
			title: 'Tech BIM',
		}
	},
	components: {
		NotifyUser,
	},
	mounted() {
		this.$onEvent('eventTitleHeader', (title) => {
			this.title = title;
		});
	},
}
</script>

<style scoped>
#header {
	position: sticky;
	top: 6px;
	/* margin: 0px 10px; */
	margin-left: 10px;
	position: sticky;
	border: 2px solid var(--user-color);
	background-color: white;
	padding: 2px 6px;
	display: flex;
	align-items: center;
	align-content: center;
	border-radius: 4px;
	padding-right: 30px;
	z-index: 99;
	/* đè lên thẻ video và pagination */
	width: calc(100% - 18px);
}

.title {
	width: 40%;
	font-weight: bold;
}

.title span:nth-child(1) {
	color: var(--user-color);
}

.title span:nth-child(2) {
	margin: 0px 6px;
}

.title span:nth-child(3) {
	color: rgb(80, 80, 80);
}

#contact a {
	transition: all 0.5s ease;
	text-decoration: none;
}

#contact a:hover {
	text-decoration: none;
	transition: all 0.5s ease;
}

#contact {
	width: 100%;
	height: 30px;
	display: flex;
	justify-content: space-around;
	color: black;
	line-height: 30%;
	align-content: center;
	align-items: center;
}

#contact li {
	margin-left: 10px;
}

.contact_email,
.contact_phone,
.contact_help {
	color: rgb(112, 112, 112);
	line-height: 1.5;
}

.contact_email a:hover {
	cursor: pointer;
	color: red;
}

.contact_phone a:hover {
	cursor: pointer;
	color: #0186ce;
}

.contact_help a:hover {
	cursor: pointer;
	color: green;
}

.list_contact a {
	font-size: 24px;
	margin-left: 6px;
	color: rgb(112, 112, 112);
}

.facebook:hover {
	color: #0863F7;
}

.instagram:hover {
	color: #E56D37;
}

.linkedin:hover {
	color: #0073B1;
}

.email-phone {
	display: flex;
}

.list_contact a {
	font-size: 145%;
}

.list_contact {
	min-width: 80px;
}

#header {
	padding: 7px 6px !important;
}

@media screen and (min-width: 1301px) {

}

@media screen and (max-width: 1300px) {
    .email-phone {
        font-size: 14px;
    }
}

@media screen and (max-width: 1200px) {
	#header {
		font-size: 15px;
	}

	.title {
		width: 80%;
	}


	.list_contact a {
		font-size: 140%;
	}

	#contact {
		padding: 20px 0;
	}

	.email-phone {
		display: block;
	}
}

@media screen and (max-width: 1024px) {
    .email-phone {
        font-size: 12px;
    }
}

@media screen and (max-width: 992px) {
	#header {
		font-size: 80%;
	}

	.title {
		width: 70%;
	}

	#contact li {
		margin-left: 10px;
	}

	.list_contact a {
		font-size: 150%;
	}

	#contact {
		padding: 20px 0;
	}

	.email-phone {
		display: block;
	}
}

@media screen and (max-width: 768px) {
	#header {
		font-size: 80%;
		padding: 2px 5px;
	}

	.title {
		width: 50%;
	}

	#contact li {
		margin-left: 10px;
	}

	.list_contact a {
		font-size: 120%;
	}

	#contact {
		padding: 20px 0;
		;
	}

	.email-phone {
		display: block;
	}

	.hidde {
		display: none;
	}
}

@media screen and (max-width: 576px) {
	#header {
		font-size: 70%;
		padding: 2px 5px;
		margin-right: 1px;
	}

	.title {
		width: 55%;
	}

	#contact li {
		margin-left: 0px;
	}

	.list_contact a {
		font-size: 120%;
	}

	#contact {
		padding: 20px 0;
		;
	}

	.email-phone {
		font-size: 95%;
		display: block;
	}

	.hidde {
		display: none;
	}
}

@media screen and (max-width: 424px) {
	#header {
		font-size: 70%;
		padding: 2px 5px;
		margin-right: 1px;
	}

	.title {
		width: 55%;
	}

	#contact li {
		margin-left: 0px;
	}

	.list_contact a {
		font-size: 120%;
	}

	#contact {
		padding: 20px 0;
		;
	}

	.email-phone {
		font-size: 95%;
		display: block;
	}

	.hidde {
		display: none;
	}
}

@media screen and (max-width: 374px) {
	#header {
		font-size: 70%;
		padding: 2px 5px;
		margin-right: 1px;
	}

	.title {
		width: 55%;
	}

	#contact li {
		margin-left: 0px;
	}

	.list_contact a {
		font-size: 120%;
	}

	#contact {
		padding: 20px 0;
		;
	}

	.email-phone {
		font-size: 95%;
		display: block;
	}

	.hidde {
		display: none;
	}
}
</style>
<template>
    <div>
        <HomeTopBar></HomeTopBar> <!-- để trực tiếp ngay đây thì thanh header mới hoạt động -->
        <router-view></router-view>
        <div>
            <HomeFooter></HomeFooter>
        </div>
        <ButtonSocial></ButtonSocial>
        <ButtonToTop></ButtonToTop>
    </div>
</template>
<script>

import HomeFooter from './home-common/HomeFooter.vue';
import HomeTopBar from './home-common/HomeTopBar.vue';
import ButtonSocial from '@/components/common/ButtonSocial.vue'
import ButtonToTop from '@/components/common/ButtonToTop.vue'

export default {
    name: "HomeMain",
    components: {
        HomeFooter,
        HomeTopBar,
        ButtonToTop,
        ButtonSocial
    },
}
</script>
<style scoped>

</style>
<template>
    <div>
        <div class="modal fade" id="deleteRecord" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel"><i class="fa-solid fa-triangle-exclamation"></i>
                            Warning</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" class="text-danger"><i class="fa-regular fa-circle-xmark"></i></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-warning" role="alert">
                            <p>Cảnh báo : Link Youtube này sẽ bị xóa vĩnh viễn khỏi hệ thống !</p>
                        </div>
                        <div class="course-video mb-2">
                            <label><strong>Video Youtube</strong></label>
                            <iframe v-if="recordSelected.link_youtube" style="width: 100%;height: 300px;" :src="this.$getYouTubeEmbedUrl(recordSelected.link_youtube)" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" ref="closeButton"
                            id="close">Đóng</button>
                        <button type="button"
                            class="btn btn-outline-danger"
                            @click="functionDeleteRecord">
                            <i class="fa-solid fa-trash"></i> Xóa
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import UserRequest from '@/restful/UserRequest';

export default {
    name: "DeleteRecord",

    props: {
        recordSelected: Object
    },

    data() {
        return {

        }
    },

    methods: {
        functionDeleteRecord: async function () {
            try {
                const { messages } = await UserRequest.get(`admin/channel/destroy/${this.recordSelected.id}`, true);
                this.$emitEvent('eventSuccess', messages[0]);
                const closeButton = this.$refs.closeButton;
                closeButton.click();
                this.$emitEvent('eventRegetDataRecords', this.recordSelected.id);
            }
            catch (error) {
                if (error.errors) this.errors = error.errors;
                else for (let key in this.errors) this.errors[key] = null;
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
            }
        },
    }

}
</script>

<style scoped src="@/components/modal-responsive.css"></style>
<style scoped>
.modal-header .close {
    outline: none;
}
</style>
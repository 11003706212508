<template>
	<div id="main" :class="{'container-content-sticky-login': isSticky }">
		<div id="container-inside">
			<div id="big">
				<div class="container">
					<form @submit.prevent="login()">
						<h4 class="title-form">Đăng nhập</h4>
						<div class="input-form form-margin">
							<input v-model="loginUser.email" required>
							<div class="underline"></div>
							<label><i class="fa-solid fa-envelope"></i> Email</label>
						</div>
						<span v-if="errors.email" class="text-danger">{{ errors.email[0] }}</span>
						<div class="input-form form-margin">
							<input required id="inputPassword" :type="isShow ? 'text' : 'password'"
								v-model="loginUser.password">
							<strong id="iconEye"><i @click="isShow = !isShow"
									:class="{ 'fa-solid': true, 'fa-eye': !isShow, 'fa-eye-slash': isShow }"></i></strong>
							<div class="underline"></div><label><i class="fa-solid fa-lock"></i> Password</label>
						</div>
						<span v-if="errors.password" class="text-danger">{{ errors.password[0] }}</span>
						<a class="under a-for-got-pw" style="text-decoration: none;color: var(--user-color);" href="#"
							data-toggle="modal" data-target="#exampleModalForgotPassword">Quên mật khẩu ?
						</a>
						<button type="submit" class="btn-pers  btn-login" id="login_button"><i
								class="fa-solid fa-arrow-right-to-bracket"></i> Đăng nhập</button>
					</form>
					<hr class="hr-form">
					<div class="social google" @click="loginWithGoogle">
						<span>
							<img :src="require('@/assets/home/google.png')" alt=""> Đăng nhập với Google
						</span>
					</div>
				</div>
				<div class="modal fade" id="exampleModalForgotPassword" tabindex="-1" role="dialog"
					aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div class="modal-dialog" role="document" style="margin: 1.75rem auto; ">
						<div class="modal-content">
							<div class="modal-header" style="padding: 10px 20px; ">
								<h5 class="modal-title" id="exampleModalLabel"><i class="fa-brands fa-keycdn"></i>
									Quên mật khẩu !</h5>
								<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
										aria-hidden="true"><i class="fa-regular fa-circle-xmark"></i></span></button>
							</div>
							<form @submit.prevent="userResetPassword">
								<div class="modal-body" style="padding: 20px; ">
									<div class="form-group">
										<label for="recipient-name" class="col-form-label"><i
												class="fa-solid fa-envelope"></i> Email</label>
										<input required v-model="resetPassword.email" type="email" class="form-control"
											id="input-font-size" style=" margin: 15px 0; padding: 0 10px;">
									</div>
									<span v-if="errorsPW.password" class="text-danger">{{ errorsPW.password[0] }}</span>
									<br>
								</div>
								<div class="modal-footer" style=" padding: 15px 20px; gap: 10px;">
									<button type="button" class="btn btn-outline-secondary" data-dismiss="modal"
										style=" padding: 6px 15px; " id="closePW">Close</button>
									<button type="submit" class="btn btn-outline-primary"
										style=" padding: 6px 15px; ">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import UserRequest from '@/restful/UserRequest'
import useEventBus from '@/composables/useEventBus'
const { emitEvent } = useEventBus();
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { firebaseApp } from '@/firebase';
const auth = getAuth(firebaseApp);

export default {
	name: "HomeLogin",
	data() {
		return {
            isSticky: false,
			user_google: null,
			user: {
				id: null,
				google_id: null,
                is_have_password: null,
				email: null,
				user_name: null,
				role: null,
				is_delete: null,
				is_block: null,
				name: null,
				phone: null,
				email_verified_at: null,
				created_at: null,
				updated_at: null,
				access_token: null,
				token_type: null,
				expires_in: null,
			},
			isShow: false,
			loginUser: {
				email: '',
				password: ''
			},
			resetPassword: {
				email: ''
			},
			errors: {
				email: null,
				password: null
			},
			errorsPW: {
				email: null,
			}
		}
	},
	setup() {

	},
	mounted() {
		var appMain = window.document.getElementById('appMain');
		appMain.style.paddingLeft = '0px';
		this.$onEvent('eventSticky', (isSticky) => {
            this.isSticky = isSticky;
        });
        this.$setDocumentTitle('Đăng nhập');
	},
	methods: {
		async login() {
			try {
				const { data, messages } = await UserRequest.post('login', this.loginUser, true)
				this.user = data;
				window.localStorage.setItem('user', JSON.stringify(this.user));
				emitEvent('eventSuccess', messages[0]);
				this.$router.push({ name: 'AccountSetting' }); 
			}
			catch (error) {
				if (error.errors) this.errors = error.errors;
				else for (let key in this.errors) this.errors[key] = null;
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
			}
		},
		async loginWithGoogle() {
			const provider = new GoogleAuthProvider();
			try {
				const result = await signInWithPopup(auth, provider);
				const user = result.user;
				const token = await user.getIdToken(); // Lấy Firebase ID Token
				// // console.log(user); // Xử lý thông tin người dùng
				// console.log(token); // Gửi token này tới API của bạn để xác thực
				this.loginToApi(token);
			} catch (error) {
				console.error('Lỗi đăng nhập: ', error);
			}
		},
		loginToApi: async function (token) {
			var dataSubmit = {
				token: token
			};
			try {
				const { data, messages } = await UserRequest.post('login-google', dataSubmit, true)
				this.user = data;
				window.localStorage.setItem('user', JSON.stringify(this.user));
				this.$router.push({ name: 'AccountSetting' });
				emitEvent('eventSuccess', messages[0]);
			}
			catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
			}
		},
		userResetPassword: async function () {
			try {
				const { messages } = await UserRequest.post('forgot-pw-sendcode', this.resetPassword, true)
				this.resetPassword.email = '';
				emitEvent('eventSuccess', messages[0]);
			}
			catch (error) {
				if (error.errors) this.errorsPW = error.errors;
				else for (let key in this.errorsPW) this.errors[key] = null;
				if (error.messages) this.$emitEvent('eventError', error.messages[0]);
			}
			var closePW = window.document.getElementById('closePW');
			closePW.click();
		}
	},
}
</script>
<style scoped src="./homelogin-responsive.css"></style>
<style scoped src="@/components/modal-responsive.css"></style>
<style scoped>
h4 {
	letter-spacing: 2.5px;
	font-weight: 700;
	/* color: var(--user-color); */
	color: #5e5e5e;
	text-align: center;
	font-size: 20px;
}

.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary:visited {
	border-color: var(--user-color);
	color: var(--user-color);
	outline-color: var(--user-color);
}

.btn {
	transition: all 0.6s ease;
}

.btn:focus,
.btn:active {
	outline: none !important;
	box-shadow: none !important;
}

.btn-outline-primary:hover {
	background-color: #092b6a !important;
	border-color: var(--user-color);
}

#main {
	background-color: white;
	/* background: var(--user-color); */
	/* background: -moz-linear-gradient(-45deg, var(--user-color) 0%, #0076e5 100%);
	background: -webkit-linear-gradient(-45deg, var(--user-color) 0%, #0076e5 100%);
	background: linear-gradient(135deg, var(--user-color) 0%, #0076e5 100%); */
	/* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00adef', endColorstr='#0076e5', GradientType=1); */
	position: relative;
	width: 100%;
	margin: 0px auto;
	padding: 0px auto;
	overflow: hidden;
}

/* login google */
.btn-google {
	color: black !important;
	background-color: white;
}

.social {
	height: 40px;
	border-radius: 5px;
	display: flex;
	cursor: pointer;
	align-items: center;
	line-height: 40px;
	align-content: center;
	margin-bottom: 10px;
	justify-content: center;
}

.social span {
	display: flex;
	height: 100%;
	color: black;
	line-height: 100%;
	align-content: center;
	align-items: center;
	font-weight: 500;
	padding: 5px;
	color: white;
	width: 100%;
	justify-content: center;
}

.social.google {
	background-color: #4284F3;
}

/* login google */

@-webkit-keyframes circle-small-scale {
	0% {
		-webkit-transform: scale(1.0);
	}

	100% {
		-webkit-transform: scale(1.1);
	}
}

@keyframes circle-small-scale {
	0% {
		transform: scale(1.0);
	}

	100% {
		transform: scale(1.1);
	}
}

* {
	margin: 0;
	padding: 0;
	outline: none;
	box-sizing: border-box;
	font-family: sans-serif;
}

#main {
	/* background-color: #F2F4F6; */
	padding-left: 30px;
	padding-right: 30px;
	/* height: 100vh; */
}

.container {
	border-radius: 10px;
	width: 450px;
	background: #fff;
	padding: 30px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.container .input-form {
	height: 40px;
	width: 100%;
	position: relative;
}

.container .input-form input {
	height: 100%;
	width: 100%;
	border: none;
	font-size: 17px;
	border-bottom: 2px solid silver;
}

.input-form input:focus~label,
.input-form input:valid~label {
	transform: translateY(-20px);
	font-size: 15px;
	color: var(--user-color);
}

.input-form .underline.fix2:before {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	background: var(--user-color);
	transform: scaleX(0);
	transform-origin: center;
	transition: transform 0.3s ease;
}

.container .input-form label {
	position: absolute;
	bottom: 10px;
	left: 0;
	color: grey;
	pointer-events: none;
	transition: all 0.3s ease;
}

.input-form .underline {
	position: absolute;
	height: 2px;
	width: 100%;
	bottom: 0;
}

.input-form .underline:before {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	background: var(--user-color);
	transform: scaleX(0);
	transform-origin: center;
	transition: transform 0.3s ease;
}

.input-form input:focus~.underline:before,
.input-form input:valid~.underline:before {
	transform: scaleX(1);
}

@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi+Ink');

#big {
	justify-content: center;
	display: flex;
	position: relative;
	margin-top: 20px;
	margin-bottom: 20px;
}

.btn-pers {
	position: relative;
	left: 50%;
	padding: 1em 2.5em;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 2.5px;
	font-weight: 700;
	color: #000;
	background-color: #fff;
	border: none;
	border-radius: 45px;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease 0s;
	cursor: pointer;
	outline: none;
	transform: translateX(-50%);
}

.btn-pers:hover {
	background-color: var(--user-color);
	box-shadow: var(--btn-hover);
	color: #fff;
	transform: translate(-50%, -7px);
}

.btn-pers:active {
	transform: translate(-50%, -1px);
}

.under {
	position: relative;
	padding: 0px 0px;
}

.under::after {
	content: ' ';
	position: absolute;
	left: 0;
	bottom: -4px;
	width: 0;
	height: 2px;
	background: var(--user-color);
	transition: width 0.3s;
}

.under:hover::after {
	width: 100%;
	transition: width 0.3s;
}

#iconEye {
	position: absolute;
	top: 10px;
	right: 0px;
	padding-left: 5px;
	cursor: pointer;
}

#inputPassword {
	padding-right: 26px;
}

#input-font-size {
	font-size: 16px !important;
}
</style>
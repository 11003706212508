<template>
	<div id="main" :class="{'container-content-sticky-register': isSticky }">
		<div id="container-inside">
			<div id="big">
				<div class="container">
					<form @submit.prevent="register()">
						<h4 class="title-form">Đăng kí</h4>
						<div class="input-form form-margin">
							<input v-model="registerUser.email" required>
							<div class="underline"></div>
							<label><i class="fa-solid fa-envelope"></i> Email</label>
						</div>
						<span v-if="errors.email" class="text-danger">{{ errors.email[0] }}</span>
						<div class="input-form form-margin">
							<input v-model="registerUser.user_name" required>
							<div class="underline"></div>
							<label><i class="fa-solid fa-user"></i> Username</label>
						</div>
						<span v-if="errors.phone" class="text-danger">{{ errors.user_name[0] }}</span>
						<div class="input-form form-margin">
							<input v-model="registerUser.name" required>
							<div class="underline"></div>
							<label><i class="fa-solid fa-signature"></i> Name</label>
						</div>
						<span v-if="errors.name" class="text-danger">{{ errors.name[0] }}</span>
                        <div class="input-form form-margin">
							<input v-model="registerUser.phone" required>
							<div class="underline"></div>
							<label><i class="fa-solid fa-phone"></i> Phone</label>
						</div>
						<span v-if="errors.phone" class="text-danger">{{ errors.phone[0] }}</span>
                        <div class="input-form form-margin">
							<input required id="inputPassword" :type="isShow ? 'text' : 'password'"
								v-model="registerUser.password">
							<strong id="iconEye"><i @click="isShow = !isShow"
									:class="{ 'fa-solid': true, 'fa-eye': !isShow, 'fa-eye-slash': isShow }"></i></strong>
							<div class="underline"></div><label><i class="fa-solid fa-lock"></i> Mật khẩu</label>
						</div>
						<span v-if="errors.password" class="text-danger">{{ errors.password[0] }}</span>
						<div class="input-form form-margin">
							<input required id="inputPassword" :type="isShow2 ? 'text' : 'password'"
								v-model="registerUser.confirm_password">
							<strong id="iconEye"><i @click="isShow2 = !isShow2"
									:class="{ 'fa-solid': true, 'fa-eye': !isShow2, 'fa-eye-slash': isShow2 }"></i></strong>
							<div class="underline"></div><label><i class="fa-solid fa-lock"></i> Xác nhận Mật khẩu</label>
						</div>
						<span v-if="errors.confirm_password" class="text-danger">{{ errors.confirm_password[0] }}</span>
						<button type="submit" class="btn-pers" id="login_button"><i class="fa-solid fa-plus"></i> Đăng kí</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
  
<script>
import UserRequest from '@/restful/UserRequest'
import useEventBus from '@/composables/useEventBus'
const { emitEvent } = useEventBus();

export default {
	name: "HomeRegister",
	data() {
		return {
            isSticky: false,
			isShow: false,
			isShow2: false,
			registerUser: {
				name: '',
				email: '',
                user_name: '',
                phone: '',
				password: '',
				confirm_password: '',
			},
			errors: {
				name: null,
				email: null,
                user_name: null,
                phone: null,
				password: null,
				confirm_password: null,
			}
		}
	},
	setup() {

	},
	mounted() {
		var appMain = window.document.getElementById('appMain');
		appMain.style.paddingLeft = '0px';
		this.$onEvent('eventSticky', (isSticky) => {
            this.isSticky = isSticky;
        });
        this.$setDocumentTitle('Đăng kí');
	},
	methods: {
		register: function () {
			UserRequest.post('register', this.registerUser, true)
				.then(data => {
					data.messages.forEach(message => {
						emitEvent('eventSuccess', message);
					});
					this.$router.push({ name: 'HomeLogin' }); 
				})
				.catch(error => {
					if (error.errors) this.errors = error.errors;
					else for (let key in this.errors) this.errors[key] = null;

					if (error.messages) {
						error.messages.forEach(message => {
							emitEvent('eventError', message);
						});
					}
				})
		},
	},
}
</script>
<style scoped src="./homeregister-responsive.css"></style>
<style scoped>
h4 {
	letter-spacing: 2.5px;
	font-weight: 700;
	/* color: var(--user-color); */
	color: #5e5e5e;
	text-align: center;
	font-size: 20px;
}

.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary:visited {
	border-color: var(--user-color);
	color: var(--user-color);
	outline-color: var(--user-color);
}

.btn {
	transition: all 0.6s ease;
}

.btn:focus,
.btn:active {
	outline: none !important;
	box-shadow: none !important;
}

.btn-outline-primary:hover {
	background-color: #092b6a !important;
	border-color: var(--user-color);
}

#main {
    background-color: white;
	/* background: var(--user-color); */
	/* background: -moz-linear-gradient(-45deg, var(--user-color) 0%, #0076e5 100%);
	background: -webkit-linear-gradient(-45deg, var(--user-color) 0%, #0076e5 100%);
	background: linear-gradient(135deg, var(--user-color) 0%, #0076e5 100%); */
	/* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00adef', endColorstr='#0076e5', GradientType=1); */
	position: relative;
	width: 100%;
	/* margin: 0px auto; */
	display: flex;
	justify-content: center;
	padding: 0px auto;
	/* overflow: hidden; */
}

@-webkit-keyframes circle-small-scale {
	0% {
		-webkit-transform: scale(1.0);
	}

	100% {
		-webkit-transform: scale(1.1);
	}
}

@keyframes circle-small-scale {
	0% {
		transform: scale(1.0);
	}

	100% {
		transform: scale(1.1);
	}
}

* {
	margin: 0;
	padding: 0;
	outline: none;
	box-sizing: border-box;
	font-family: sans-serif;
}

.container {
	border-radius: 10px;
	width: 450px;
	background: #fff;
	padding: 30px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	margin-bottom: 20px !important;
}

.container .input-form {
	height: 40px;
	width: 100%;
	position: relative;
}

.container .input-form input {
	height: 100%;
	width: 100%;
	border: none;
	font-size: 17px;
	border-bottom: 2px solid silver;
}

.input-form input:focus~label,
.input-form input:valid~label {
	transform: translateY(-20px);
	font-size: 15px;
	color: var(--user-color);
}

.input-form .underline.fix2:before {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	background: var(--user-color);
	transform: scaleX(0);
	transform-origin: center;
	transition: transform 0.3s ease;
}

.container .input-form label {
	position: absolute;
	bottom: 10px;
	left: 0;
	color: grey;
	pointer-events: none;
	transition: all 0.3s ease;
}

.input-form .underline {
	position: absolute;
	height: 2px;
	width: 100%;
	bottom: 0;
}

.input-form .underline:before {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	background: var(--user-color);
	transform: scaleX(0);
	transform-origin: center;
	transition: transform 0.3s ease;
}

.input-form input:focus~.underline:before,
.input-form input:valid~.underline:before {
	transform: scaleX(1);
}

@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi+Ink');

.btn-pers {
	position: relative;
	left: 50%;
	padding: 1em 2.5em;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 2.5px;
	font-weight: 700;
	color: #000;
	background-color: #fff;
	border: none;
	border-radius: 45px;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease 0s;
	cursor: pointer;
	outline: none;
	transform: translateX(-50%);
}

.btn-pers:hover {
	background-color: var(--user-color);
	box-shadow: var(--btn-hover);
	color: #fff;
	transform: translate(-50%, -7px);
}

.btn-pers:active {
	transform: translate(-50%, -1px);
}

.under {
	position: relative;
	padding: 0px 0px;
}

.under::after {
	content: ' ';
	position: absolute;
	left: 0;
	bottom: -4px;
	width: 0;
	height: 2px;
	background: var(--user-color);
	transition: width 0.3s;
}

.under:hover::after {
	width: 100%;
	transition: width 0.3s;
}

#iconEye {
	position: absolute;
	top: 10px;
	right: 0px;
	padding-left: 5px;
	cursor: pointer;
}

#inputPassword {
	padding-right: 26px;
}

#input-font-size{
	font-size: 16px !important;
}
</style>
  
  

<template>
    <div class="home-introduct">
        <div v-if="isLoading" class="d-flex justify-content-center container-loader">
            <flower-spinner class="loading-component" :animation-duration="2000" :size="30" color="#06C755" />
        </div>
        <div class="container-course" v-if="!isLoading">
            <div>
                <div class="title-container">
                    <h1>KHOÁ HỌC NỔI BẬT</h1>
                </div>
                <ul class="ul-course">
                    <li class="course-item" v-for="(record, index) in featured_courses" :key="index" @click="gotoCourse(record)">
                        <img class="img-cover-course" :src="record.course_image ? record.course_image : require('@/assets/home/item-source.jpg')" alt="Course Thumbnail">
                        <div class="content">
                            <p class="p-chapter-lesson"><i class="fa-solid fa-video"></i> {{record.total_chapters}} phần/{{record.total_lessons}} bài </p> 
                            <!-- - <i class="fa-solid fa-eye"></i> 4504 -->
                            <h2 class="h2-name-course">{{ this.$truncatedTitle(record.course_name, 100) }}</h2>
                            <p class="p-author"><img :src="require('@/assets/home/avatar-logo.jpg')" alt=""><span>TECH BIM</span></p>
                            <h1 class="h1-price"><i class="fa-solid fa-tags"></i> {{ this.$formatNumber(record.course_price) }} VNĐ</h1>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="home-news">
            <div class="title-container">
                <h1>THÔNG BÁO TỪ QUẢN TRỊ VIÊN</h1>
            </div>
            <div>
                <ul>
                    <HomeNews></HomeNews>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import UserRequest from '@/restful/UserRequest';
import { FlowerSpinner } from 'epic-spinners';
import HomeNews from '@/components/home/HomeNews.vue';
export default {
    name: "HomeIntroduct",
    components: {
        FlowerSpinner,
        HomeNews,
    },
    data() {
        return {
            isLoading: false,
            featured_courses: null,
        }
    },
    mounted() {
        document.title = 'Tech BIM'
        this.$emitEvent('HomeIntroductPage', true);
        this.getDataRecords();
    },
    beforeUnmount() { // sự kiện hủy component Home Introduct 
        this.$emitEvent('HomeIntroductPage', false);
    },
    methods: {
        gotoCourse: function(record) {
            this.$router.push({ name: 'HomeCourseDetail', params: { slug_name_course: record.course_slug + '-id-record-' + record.id } });
        },
        getDataRecords: async function () {
            this.isLoading = true;
            try {
                const { data } = await UserRequest.get('home/featured-course')
                this.featured_courses = data;
                this.isLoading = false;
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
                this.isLoading = false;
            }
        },
    }
}
</script>
<style scoped src="./homeintroduct-responsive.css"></style>
<style scoped>
.home-introduct {
    /* margin-top: 10px; */
    /* padding: 0px 10%; */
}

/*  */
.container-banner {
    margin-bottom: 60px;
}

/*  */
.title-container {
    margin: 20px 0px;
    font-size: 42px;
    line-height: 42px;
    text-align: center;
    font-family: "Quicksand", Helvetica, Arial, sans-serif;
    font-weight: 400;
}

/* course li */
.container-course {
    display: flex;
    justify-content: center;
    padding: 5% 20%;
    background-color: white;
}

.ul-course {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    list-style-type: none;
}

li.course-item {
    cursor: pointer;
    box-sizing: border-box;
    list-style: none;
    display: flex;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    transition: all 0.5s ease;
    scale: 1;
}

li.course-item:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
    scale: 1.1;
}

li.course-item > img {
    width: 200px;
    height: 200px;
    min-width: 200px;
    min-height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 20px;
}

li.course-item .content {
    display: flex;
    flex-direction: column;
}

li.course-item .content h2 {
    font-size: 18px;
    margin: 0 0 10px;
}

li.course-item .content p {
    font-size: 14px;
    color: #666;
}

.content .p-author {
    display: flex;
    margin-top: 6px;
    margin-bottom: 10px;
}

.content .p-author img {
    max-width: 20px;
    border-radius: 100px;
    margin-right: 6px;
}

.content h1 {
    font-size: 20px;
    font-weight: 500;
}

.home-news {
    margin-top: 120px;
    margin-bottom: 120px;
}
    
/* feeling */
/* .feeling-student {
    display: flex;
    justify-content: center;
    margin: 0px 5%;
    margin-top: 120px;
}
.ul-feeling {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.li-feeling {
    cursor: pointer;
    padding: 20px;
    margin: 15px;
    border-radius: 6px;
    box-shadow: rgba(147, 170, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    transition: all 0.5s ease;
}
.li-feeling:hover {
    transition: all 0.5s ease;
    scale: 1.02;
    box-shadow: rgba(110, 172, 226, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.avatar-student {
    display: flex;
    align-items: center;
}
.avatar-student img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    margin-right: 10px;
}

.student-name {
    font-weight: bold;
    font-size: 20px;
}

.student-job {
    color: silver;
} */

/* gallery */
/* .container-gallery {
    margin-top: 120px;
    display: flex;
    justify-content: center;
}

.intro-gallery {
    color: #7A7A7A;
    font-size: 14px;
    text-align: center;
    margin-bottom: 30px;
}

.ul-gallery {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    list-style-type: none;
}
.ul-gallery li {
    margin-bottom: 20px;
    cursor: pointer;
}
.ul-gallery li img {
    width: 200px;
    border-radius: 6px;
    height: 200px;
    object-fit: cover;
    object-position: center;
} */
</style>
<template>
    <div>
        <div id="big">
            <div class="bigContainer">
                <div class="modal fade" id="updateRecord" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel"><strong>
                                        <i class="fa-solid fa-pen-to-square"></i> Chỉnh sửa bài học</strong></h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" class="text-danger"><i
                                            class="fa-regular fa-circle-xmark"></i></span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="updateRecord()">
                                    <div class="form-group">
                                        <label><strong>Tên bài học</strong></label>
                                        <input v-model="record.lesson_name" type="text"
                                            class="form-control form-control-sm" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Tên bài học">
                                        <span v-if="errors.lesson_name" class="text-danger">{{ errors.lesson_name[0]
                                            }}<br></span>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1"><strong>Mô tả bài học</strong></label>
                                        <div>
                                            <RichTextEditor v-model="record.lesson_introduce" />
                                        </div>
                                        <span v-if="errors.lesson_introduce" class="text-danger">{{
                                            errors.lesson_introduce[0] }}<br></span>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <label for="exampleInputEmail1"><strong>Video của bài học</strong></label>
                                            <input class="mb-2" v-if="!nameFile" type="file" ref="fileInput"
                                                id="file" @change="handleFileUpload" accept="video/*" />
                                            <div v-if="nameFile" class="container-file-default">
                                                <img src="@/assets/video_file_default.png" alt="File Image" class="file-default-image" />
                                                <p class="name-file-upload">{{ nameFile }}</p>
                                                <img @click="removeFile" src="@/assets/error.png" class="close-img"
                                                    alt="Remove">
                                            </div>
                                            <div>
                                                <span v-if="errors.lesson_video" class="text-danger">{{
                                                    errors.lesson_video[0]
                                                    }}<br></span>
                                            </div>
                                        </div>
                                        <div class="form-group col-6">
                                            <label for="exampleInputEmail1"><strong>Vị trí của bài học</strong> </label>
                                            <input v-model="record.position" type="number"
                                                class="form-control form-control-sm" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Vị trí bài học">
                                            <span v-if="errors.position" class="text-danger">{{ errors.position[0]
                                                }}<br></span>
                                        </div>
                                    </div>
                                    <button type="submit" class="mt-4 btn-pers" id="login_button"><i
                                            class="fa-solid fa-paper-plane"></i> Cập nhật</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserRequest from '@/restful/UserRequest';
import RichTextEditor from '@/components/common/RichTextEditor.vue'

export default {
    name: "UpdateRecord",
    props: {
        chapter_id: String,
    },
    setup() {

    },
    data() {
        return {
            selectedFile: null,
            nameFile: null,
            linkFileBackup: null,
            updateFile: false,
            record: {
                id: null,
                chapter_id: null,
                lesson_name: null,
                lesson_video: null,
                lesson_introduce: null,
                position: null,
            },
            errors: {
                id: null,
                chapter_id: null,
                lesson_name: null,
                lesson_video: null,
                lesson_introduce: null,
                position: null,
            }
        }
    },
    mounted() {
        this.$onEvent('eventSelectedRecord', (recordSelected) => {
            this.record = { ...recordSelected };
            this.nameFile = this.getFileNameFromUrl(this.record.lesson_video);
            this.linkFileBackup = this.record.lesson_video;
        });
    },
    created() {
    },
    components: {
        RichTextEditor,
    },
    computed: {

    },
    methods: {
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedFile = file;
                this.nameFile = this.selectedFile.name;
                this.record.lesson_video = file;
                this.updateFile = true;
            }
            else this.updateFile = false;
        },
        removeFile() {
            this.selectedFile = null;
            this.updateFile = false;
            this.nameFile = null;
        },
        getFileNameFromUrl: function (url) {
            const parts = url.split('lesson_video/');
            if (parts.length > 1) {
                return parts[1];
            }
            return '';
        },
        updateRecord: async function () {
            try {
                this.record.chapter_id = this.chapter_id;
                const formData = new FormData();
                var fields = ['id', 'chapter_id', 'lesson_name', 'lesson_introduce', 'position'];
                for (let key in fields) formData.append(fields[key], this.record[fields[key]]);
                if (this.updateFile) {
                    formData.append('lesson_video', this.record.lesson_video);
                } else {
                    formData.append('lesson_video', this.linkFileBackup);
                }

                const { data, messages } = await UserRequest.post('admin/lesson/update', formData, true);
                this.$emitEvent('eventSuccess', messages[0]);
                for (let key in this.errors) this.errors[key] = null;
                var closePW = window.document.getElementById('updateRecord');
                closePW.click();
                this.record = data;
                this.$emitEvent('eventRegetDataRecords', '');
            }
            catch (error) {
                if (error.errors) this.errors = error.errors;
                else for (let key in this.errors) this.errors[key] = null;
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
            }

        },
    },
    watch: {

    },
}
</script>

<style scoped src="@/components/modal-responsive.css"></style>
<style scoped>
/*  */
.close-img {
    position: absolute;
    top: -6px;
    left: 6px;
    width: 16px;
    cursor: pointer;
}

.container-file-default {
    position: relative;
}

/*  */
.modal-dialog {
    max-width: 800px;
}

/*  */


.modal.fade.show {
    padding-left: 0px;
}

.modal-content {
    /* margin-top: 100px; */
    border-radius: 10px;
}

.bigContainer .input-form {
    height: 40px;
    width: 100%;
    position: relative;
}

.bigContainer .input-form input {
    height: 100%;
    width: 100%;
    border: none;
    font-size: 17px;
    border-bottom: 2px solid silver;
    outline: none !important;
}

.input-form input:focus~label,
.input-form input:valid~label {
    transform: translateY(-20px);
    font-size: 15px;
    color: var(--user-color);
}

.input-form .underline.fix2:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: var(--user-color);
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease;
}

.bigContainer .input-form label {
    position: absolute;
    bottom: 0px;
    left: 0;
    color: grey;
    pointer-events: none;
    transition: all 0.3s ease;
}

.input-form .underline {
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: 0;
}

.input-form .underline:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: var(--user-color);
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease;
}

.input-form input:focus~.underline:before,
.input-form input:valid~.underline:before {
    transform: scaleX(1);
}

@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi+Ink');

#big {
    display: flex;
    position: relative;
}

.btn-pers {
    position: relative;
    left: 50%;
    padding: 1em 2.5em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 700;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    transform: translateX(-50%);
}

.btn-pers:hover {
    background-color: var(--user-color);
    box-shadow: var(--btn-hover);
    color: #fff;
    transform: translate(-50%, -7px);
}

.btn-pers:active {
    transform: translate(-50%, -1px);
}

#inputPassword {
    padding-right: 26px;
}
</style>

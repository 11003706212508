<template>
    <div>
        <div class="modal fade" id="viewRecord" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title bold" id="exampleModalLabel">
                            Xem chi tiết thư viện </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" class="text-danger"><i class="fa-regular fa-circle-xmark"></i></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p><label for="exampleInputEmail1"><strong>Tên Thư viện</strong></label> : {{ recordSelected.library_name }}</p>
                        <p><label for="exampleInputEmail1"><strong>Giá Thư viện</strong></label> : {{ recordSelected.library_price }} VNĐ</p>
                        <p v-if="recordSelected"><label for="exampleInputEmail1"><strong>Tên file</strong></label> : <a :href="recordSelected.library_file" target="_blank" rel="noopener noreferrer">{{ this.$extractFileName(recordSelected.library_file) }}</a></p>
                        <div class="cover-course mb-2">
                            <label for="exampleInputEmail1"><strong>Ảnh bìa</strong></label>
                            <div class="inner-cover-course">
                                <img :src="recordSelected.library_image ? recordSelected.library_image : require('@/assets/admin/image-default.jpg')" alt="">
                            </div>
                        </div>
                        <div class="library_introduce">
                            <label for="exampleInputEmail1"><strong>Giới thiệu về thư viện</strong></label>
                            <p v-html="recordSelected.library_introduce"></p>
                        </div>
                    </div>
                    <br>
                    <div class="modal-footer mt-2">
                        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" ref="closeButton"
                            id="close">Đóng</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "ViewRecord",

    props: {
        recordSelected: Object
    },

    data() {
        return {

        }
    },

    methods: {

    }

}
</script>

<style scoped src="@/components/modal-responsive.css"></style>
<style scoped>
.library_introduce {
    margin-top: 40px;
}
.library_introduce p {
    padding: 10px;
    border: 1px solid silver;
}
.modal-header .close {
    outline: none;
}

.modal-dialog {
    max-width: 800px;
}

.cover-course {
    width: 100%;
    height: 40vh;
}
.inner-cover-course {
    border: 1px solid silver;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.cover-course img {
    object-fit: cover;
    height: 100%;
    margin: auto;
}

.course-video {
    margin-top: 60px;
}
</style>
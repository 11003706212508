<template>
    <div :class="{'container-shadow': !isLoadingLesson}" class="pl-3 pt-2 main-component">
        <div class="right-lesson">
            <div v-if="isLoadingLesson" class="d-flex justify-content-center container-loader">
                <flower-spinner class="loading-component" :animation-duration="2000" :size="30" color="#06C755" />
            </div>
            <div v-if="!isLoadingLesson">
                <LessonComponent :lesson="lesson" :chapter="chapter" :course="course"></LessonComponent>
            </div>
        </div>

        <!-- Chat Room -->
        <div v-if="!isLoadingLesson" class="right-chat mt-6">
            <ChatComponent :lesson="lesson" :chapter="chapter" :course="course"></ChatComponent>
        </div>
    </div>
</template>
<script>

import LessonComponent from '@/components/user/my-class-room/LessonComponent.vue'
import ChatComponent from '@/components/user/my-class-room/ChatComponent.vue'
import UserRequest from '@/restful/UserRequest';
import { FlowerSpinner } from 'epic-spinners';

export default {
    name: "LessonAndChat",
    components: {
        LessonComponent,
        ChatComponent,
        FlowerSpinner,
    },
    setup() {
        Document.title = "Bài học | Tech BIM"
    },
    data() {
        return {
            course_id: null,
            lesson_id: null,
            isLoadingLesson: false,
            isLoadingChat: false,
            lesson: {
                id: null,
                chapter_id: null,
                is_block: null,
                is_delete: null,
                lesson_introduce: null,
                lesson_name: null,
                lesson_slug: null,
                lesson_video: null,
                position: null,
                created_at: null,
                updated_at: null,
            },
            chapter: {
                id: null,
                chapter_name: null,
                course_id: null,
                position: null,
                created_at: null,
                updated_at: null,
            },
            course: {
                id: null,
                course_image: null,
                course_introduce: null,
                course_name: null,
                course_price: null,
                course_slug: null,
                course_trailer: null,
                is_block: null,
                is_delete: null,
                created_at: null,
                updated_at: null,
            },

        }
    },
    mounted() {
        this.$emitEvent('eventTitleHeader', 'Bài học');
        this.$emitEvent('eventActiveTab', '');

        this.lesson_id = this.$extractIdFromSlug(this.$route.params.slug_name_lesson);
        // console.log('Lesson ID:', this.lesson_id);

        this.getLesson();
    },

    methods: {
        getLesson: async function () {
            this.isLoadingLesson = true;
            try {
                const { data } = await UserRequest.get('user/lesson/get-data/' + this.lesson_id)
                // console.log(data);
                this.lesson = data.lesson;
                this.chapter = data.chapter;
                this.course = data.course;
                this.isLoadingLesson = false;
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
                this.isLoadingLesson = false;
            }
        },
    },
    watch: {
        '$route.params.slug_name_lesson': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.lesson_id = this.$extractIdFromSlug(newVal);
                this.getLesson(); // Gọi lại hàm để tải dữ liệu bài học mới
            }
        }
    },
}
</script>

<style src="./my-class-room-responsive.css"></style>
<style scoped>
/* đổi id main thành class main-component để scroll được mượt hợn, lí do chắc là conflic với id của mấy component khác,
trong vuejs thì hạn chế đặt id hết mức có thể, mấy component khác vẫn còn id=main nhưng chuyển sau */
.main-component { 
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}
/* right-lesson */
.container-shadow {
    border-radius: 5px;
    box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
    /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
}
.right-chat {
    height: 300px; 
    border-radius: 10px;
}

/* loading */
.container-loader {
    height: 300px;
    align-items: center;
    align-content: center;
    display: flex !important;
    justify-content: center;
}

.container-loader .loading-component {
    margin: 0 !important;
}

/* loading */

.div_microphone {
    cursor: pointer;
}

.title-right {
    font-size: 19px;
    color: var(--user-color);
}

.form-control {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: 0.2rem;
    line-height: 1.5;
}

@media screen and (min-width: 1201px) {
    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .coverRecord {
        min-width: 150px;
    }

    .displaytext {
        min-width: 150px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;
    }

    table img {
        min-width: 60px;
        min-height: 60px;
        /* max-width: 60px; */
        /* max-height: 60px; */
        object-fit: cover;
    }

    td .fa-solid {
        font-size: 20px;
    }

}

@media screen and (max-width: 1200px) {
    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .coverRecord {
        min-width: 120px;
    }

    .displaytext {
        min-width: 100px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 11px;
    }

    .fa-solid {
        font-size: 15px;
    }

    table img {
        min-width: 50px;
        min-height: 50px;
        /* max-width: 50px;
        max-height: 50px; */
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 8px;
    }

    .form-control,
    .pagination {
        font-size: 12px !important;
    }

    .main-component{
        padding: 1% 1%;
        margin: 0;
    }
}

@media screen and (max-width: 992px) {
    .title-right {
        font-size: 15px;
    }

    .colorTitle {
        font-size: 14px;
    }

    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .coverRecord {
        min-width: 140px;
    }

    .displaytext {
        min-width: 110px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 11px;
    }

    .fa-solid {
        font-size: 16px;
    }

    table img {
        min-width: 50px;
        min-height: 50px;
        max-width: 50px;
        max-height: 50px;
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 8px;
    }

    .form-control,
    .pagination {
        font-size: 12px !important;
    }

    .main-component{
        padding: 1% 1%;
        margin: 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        padding-left: 0;
        padding-right: 10px;
    }

    .btn {
        padding: 0px 4px;
        margin-top: 3px;
    }

    .input-group-text {
        padding: 0 8px;
    }
}

@media screen and (max-width: 768px) {

    .title-right,
    .colorTitle {
        font-size: 13px;
    }

    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .coverRecord {
        min-width: 100px;
    }

    .displaytext {
        min-width: 90px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 11px;
    }

    .fa-solid {
        font-size: 13px;
    }

    table img {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 5px;
    }

    .form-control,
    .pagination {
        font-size: 12px !important;
    }

    #page {
        min-width: 45px;
    }

    .form-control {
        padding: 1px 1px;
    }

    .main-component{
        padding: 1% 1%;
        margin: 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        padding-right: 5px;
    }

    .btn {
        padding: 0px 4px;
        margin-top: 3px;
    }

    .input-group-text {
        padding: 0 4px;
    }

    .input-group-prepend {
        font-size: 12px;

    }
}

@media screen and (max-width: 576px) {

    .title-right,
    .colorTitle {
        font-size: 12px;
    }

    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .nameMember {
        margin-left: 8px;
    }

    .coverRecord {
        min-width: 100px;
    }

    .displaytext {
        min-width: 70px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 10px;
    }

    .fa-solid {
        font-size: 10px;
    }

    table img {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 4px;
    }

    .form-control,
    .pagination {
        font-size: 10px !important;
    }

    .form-control {
        padding: 1px 1px;
        height: 25px;
    }

    #page {
        min-width: 45px;
    }

    .main-component{
        padding: 1% 1%;
        margin: 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        padding-right: 5px;
    }

    .btn {
        padding: 0px 4px;
    }

    .input-group-text {
        padding: 0 0.5px;
    }

    .input-group-prepend {
        font-size: 11px;

    }
}

@media screen and (max-width: 424px) {

    .title-right,
    .colorTitle {
        font-size: 11px;
    }

    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .nameMember {
        margin-left: 8px;
    }

    .coverRecord {
        min-width: 80px;
    }

    .displaytext {
        min-width: 70px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 9px;
    }

    .fa-solid {
        font-size: 10px;
    }

    table img {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 4px;
    }

    .form-control,
    .pagination {
        font-size: 9px !important;
    }

    .form-control {
        padding: 0.5px 0;
        height: 25px;
    }

    #page {
        min-width: 40px;
    }

    .main-component{
        padding: 1% 1%;
        margin: 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        padding-right: 0;
    }

    .btn {
        padding: 0px 4px;
    }

    .input-group-text {
        padding: 0 0.5px;
    }

    .input-group-prepend {
        font-size: 10px;

    }

    .main-component .ml-2 {
        margin-left: 3px !important;
    }
}
</style>

import { initializeApp } from 'firebase/app';
import { getFirestore, collection } from 'firebase/firestore';

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyAJ4GZCT-ovXY8ayoubwBmncGBoggpNdIs",
  authDomain: "academy-93ca7.firebaseapp.com",
  projectId: "academy-93ca7",
  storageBucket: "academy-93ca7.firebasestorage.app",
  messagingSenderId: "556032543922",
  appId: "1:556032543922:web:4768a68674017238f08dab",
  measurementId: "G-0QQ04KKR00"
});

export const db = getFirestore(firebaseApp);
export const messagesRef = collection(db, 'messages');
export const notifiesRef = collection(db, 'notifies');
export const commentsRef = collection(db, 'comments');

export const articleCommentsRef = collection(db, 'article_comments');
export const articleNotifiesRef = collection(db, 'article_notifies');

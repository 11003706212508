<template>
    <div>
        <div class="modal fade" id="viewRecord" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title bold" id="exampleModalLabel">
                            Xem chi tiết khóa học </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" class="text-danger"><i class="fa-regular fa-circle-xmark"></i></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p><label for="exampleInputEmail1"><strong>Tên Khóa học</strong></label> : {{ recordSelected.course_name }}</p>
                        <p v-if="recordSelected.course_price"><label for="exampleInputEmail1"><strong>Giá khóa học</strong></label> : <span class="text-success bold">{{ this.$formatNumber(recordSelected.course_price) }} VNĐ</span></p>
                        <div class="mb-2">
                            <label for="exampleInputEmail1"><strong>Giới thiệu về khóa học</strong></label>
                            <p class="container-html" v-html="recordSelected.course_introduce"></p>
                        </div>
                        <div class="cover-course mb-2">
                            <label for="exampleInputEmail1"><strong>Ảnh bìa</strong></label>
                            <div class="inner-cover-course">
                                <img :src="recordSelected.course_image ? recordSelected.course_image : require('@/assets/admin/image-default.jpg')" alt="">
                            </div>
                        </div>
                        <div class="course-video mb-2">
                            <label><strong>Video trailer</strong></label>
                            <!-- sử dụng v-if để chỉ khi có record được select -->
                            <iframe class="modal-course-table-youtube" v-if="recordSelected.course_trailer" :src="this.$getYouTubeEmbedUrl(recordSelected.course_trailer)" frameborder="0" allowfullscreen></iframe>
                            <!-- <iframe width="200" :src="this.$getYouTubeEmbedUrl(recordSelected.course_trailer)" frameborder="0" allowfullscreen></iframe> -->
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" ref="closeButton"
                            id="close">Đóng</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "ViewRecord",

    props: {
        recordSelected: Object
    },

    data() {
        return {

        }
    },

    methods: {

    }

}
</script>

<style scoped src="@/components/modal-responsive.css"></style>
<style scoped>
.modal-header .close {
    outline: none;
}

.modal-dialog {
    max-width: 800px;
}

.cover-course {
    width: 100%;
    height: 40vh;
}
.inner-cover-course {
    border: 1px solid silver;
    padding: 10px;
    width: 100%;
    height: 100%;
}
.container-html {
    border: 1px solid silver;
    padding: 3px;
}
.cover-course img {
    /* object-fit: cover; */
    object-fit: contain;
    height: 100%;
    margin: auto;
}

.course-video {
    margin-top: 40px;
}

</style>
<template>
    <div class="home-public">
        <div v-if="isLoading" class="d-flex justify-content-center container-loader">
            <flower-spinner class="loading-component" :animation-duration="2000" :size="30" color="#06C755" />
        </div>
        <div class="big-container-article row pl-0 pr-0 m-0" v-if="!isLoading">
            <div class="big-middle mx-auto news-container">
                <div class="middle-article">
                    <div id="carouselNews" class="carousel slide" data-interval="false"> 
                        <!-- bỏ đi data-ride="carousel" là hết tự động chuyển slide và thêm thuộc tính này data-interval="false" -->
                        <!-- Carousel Items -->
                        <div class="carousel-inner">
                            <div v-for="(article, index) in articles" :key="'item-' + index"
                                :class="['carousel-item', { active: index === 0 }]">
                                <li>
                                    <div class="big_article">
                                        <div class="header_article">
                                            <div class="avatar_article"
                                                @click="this.$gotoHomePersonal(article.user_id)">
                                                <img :src="article.user_avatar ? article.user_avatar : require('@/assets/avatar.jpg')"
                                                    alt="Avatar User">
                                            </div>
                                            <div class="infor_article">
                                                <div class="infor_left">
                                                    <p class="infor_fullname"
                                                        @click="this.$gotoHomePersonal(article.user_id)">{{
                                                        article.user_fullname }}</p>
                                                    <p class="infor_created" @click="gotoArticleDetail(article)">{{
                                                        this.$formatDateTime(article.created_at) }}</p>
                                                </div>
                                                <!-- nếu chưa đăng nhập -->
                                                <div v-if="(isUserLoggedIn) && (user.id === article.user_id)"
                                                    class="infor_right">
                                                    <button class="btn_setting"
                                                        @click="toggleModalSetting(article.id)"><i
                                                            class="fa-solid fa-ellipsis"></i></button>
                                                    <div class="show_setting" v-if="showModalSetting(article.id)">
                                                        <div>
                                <li class="li_edit">
                                    <router-link :to="{ name: 'ManageArticle' }">
                                        <span class="setting_icon"><i class="fa-solid fa-pen-nib"></i></span>
                                        <span>Chỉnh sửa</span>
                                    </router-link>
                                </li>
                                <li class="li_delete">
                                    <a @click="deleteArticle(article.id)" :to="{ name: 'ManageArticle' }">
                                        <span class="setting_icon"><i class="fa-solid fa-trash"></i></span>
                                        <span>Xóa</span>
                                    </a>
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content_main_article">
                <div class="main_title ajax_load_article" data-toggle="modal"
                    :data-target="'#modalArticleDetail_' + article.id">
                    <i class="fa-solid fa-play mr-2"></i>{{ article.article_title }}
                </div>
                <div class="main_center">
                    <i class="fa-solid fa-blog"></i>
                </div>
                <div class="main_content slide_article">
                    <div v-html="article.article_content"></div>
                </div>
            </div>
            <div class="footer_article">
                <div class="footer_number_comment ">
                    <div @click="selectArticle(article)" v-if="article.like_user_ids.length > 0" class="openModalLike"
                        data-toggle="modal" data-target="#modalLiked">
                        <span class="number_user_like">{{ article.like_user_ids.length }} Like</span>
                        <!-- for tối đa 10 item -->
                        <div v-for="(user_like, index2) in article.like_users.slice(0, 10)" :key="index2"
                            :id="isUserLoggedIn && user.id === article.user_id ? 'my_like_' + article.id : null"
                            class="avatar_user_like">
                            <img v-if="article.like_user_ids.includes(user_like.id)"
                                :src="user_like.avatar ? user_like.avatar : require('@/assets/avatar.jpg')"
                                alt="Avatar User">
                        </div>
                    </div>
                    <div @click="selectArticle(article)" v-else class="openModalLike" data-toggle="modal"
                        data-target="#modalLiked">
                        <span class="number_user_like">0 Like</span>
                    </div>
                    <div class="ajax_load_article" data-toggle="modal"
                        :data-target="'#modalArticleDetail_' + article.id">
                        {{ article.total_comment }} Comments
                    </div>
                </div>
                <div class="footer_comment_article">
                    <div v-if="isUserLoggedIn">
                        <div v-if="article.like_user_ids.includes(user.id)" @click="toggleLike(article, 'unlike')"
                            class="liked ajax_load_article like_article">
                            <span><i class="fa-solid fa-heart"></i></span> <span>Like</span>
                        </div>
                        <div v-else @click="toggleLike(article, 'like')" class="ajax_load_article like_article">
                            <span><i class="fa-regular fa-heart"></i></span> <span>Like</span>
                        </div>
                    </div>
                    <div class="ajax_load_article" data-toggle="modal"
                        :data-target="'#modalArticleDetail_' + article.id">
                        <div v-if="isUserLoggedIn"><span><i class="fa-regular fa-message"></i></span> <span>Bình
                                luận</span>
                        </div>
                        <div v-else @click="gotoLogin()"><span><i class="fa-solid fa-arrow-right-to-bracket"></i> Đăng
                                nhập để
                                Bình luận</span></div>
                    </div>
                    <!-- <div class="ajax_load_article" data-toggle="modal" :data-target="'#modalArticleDetail_' + article.id">
                                        <span><i class="fa-regular fa-eye"></i></span> <span>Xem chi tiết</span>
                                    </div> -->
                    <div @click="selectArticle(article)" data-toggle="modal" data-target="#modalShare"
                        class="ajax_load_article share_article">
                        <span><i class="fa-solid fa-share-from-square"></i></span> <span>Chia sẻ</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-home-article modal fade" :id="'modalArticleDetail_' + article.id" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="home-article modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="big_article">
                            <div class="header_article">
                                <div class="avatar_article">
                                    <img :src="article.user_avatar ? article.user_avatar : require('@/assets/avatar.jpg')"
                                        alt="Avatar User">
                                </div>
                                <div class="infor_article">
                                    <div class="infor_left">
                                        <p class="infor_fullname">
                                            {{ article.user_fullname }}</p>
                                        <p class="infor_created" @click="gotoArticleDetail(article)">
                                            {{ this.$formatDateTime(article.created_at) }}</p>
                                    </div>
                                    <!-- nếu chưa đăng nhập -->
                                    <div class="group-btn-edit-modal">
                                        <div v-if="(isUserLoggedIn) && (user.id === article.user_id)" class="infor_right">
                                            <button class="btn_setting" @click="toggleModalSetting(article.id)"><i
                                                class="fa-solid fa-ellipsis"></i></button>
                                            <div class="show_setting" v-if="showModalSetting(article.id)">
                                                <div>
                                                    <li class="li_edit"><router-link :to="{ name: 'ManageArticle' }">
                                                            <span class="setting_icon"><i
                                                                    class="fa-solid fa-pen-nib"></i></span>
                                                            <span>Chỉnh sửa</span>
                                                        </router-link></li>
                                                    <li class="li_delete" data-toggle="modal"
                                                        data-target="#exampleModalDelete">
                                                        <a @click="deleteArticle(article.id)"
                                                            :to="{ name: 'ManageArticle' }">
                                                            <span class="setting_icon"><i
                                                                    class="fa-solid fa-trash"></i></span>
                                                            <span>Xóa</span>
                                                        </a>
                                                    </li>
                                                </div>
                                            </div>
                                        </div>
                                        <button style="outline: none" type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="content_main_article">
                                <div class="main_title" data-toggle="modal"
                                    :data-target="'#modalArticleDetail_' + article.id">
                                    <i class="fa-solid fa-play mr-2"></i>{{ article.article_title }}
                                </div>
                                <div class="main_center">
                                    <i class="fa-solid fa-blog"></i>
                                </div>
                                <div class="main_content">
                                    <div v-html="article.article_content"></div>
                                </div>
                            </div>
                            <!-- Comment -->
                            <br v-if="article.comments.length > 0">
                            <hr v-if="article.comments.length > 0">
                            <br v-if="article.comments.length > 0">
                            <div class="container-home-comment">
                                <CommentComp :isUserLoggedIn="isUserLoggedIn" :article_id="article.id"
                                    :commentsWithReplies="article.comments" :issues="issues" />
                            </div>
                            <!-- nếu chưa đăng nhập -->
                            <div v-if="(isUserLoggedIn)" id="add_comment">
                                <div class="header_comment ">
                                    <div class="avatar_article">
                                        <img :src="user.avatar ? user.avatar : require('@/assets/avatar.jpg')"
                                            alt="Avatar User">
                                    </div>
                                    <div class="send_content_comment">
                                        <div>
                                            <div class="input-group">
                                                <VueMention :article_id="article.id" :issues="issues"
                                                    :comment_parent_id="null"></VueMention>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="p-tip">
                                    Mẹo: Nhấn tổ hợp phím <span style="font-weight: bold;"><i
                                            class="fa-brands fa-windows"></i> + .</span> để biết thêm biểu tượng .
                                    Đối với Macos, nhấn <span style="font-weight: bold;">Command + Control +
                                        Space</span> .</p>
                            </div>
                            <!-- Comment -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Like -->
        <!-- <ModalLiked ></ModalLiked> -->
        </li>
    </div>
    </div>

    <!-- Carousel Controls -->
    <a class="carousel-control-prev" href="#carouselNews" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselNews" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
    </div>
    <!-- Modal Like -->
    <ModalLiked :article="articleSelected"></ModalLiked>
    <ModalShare :article="articleSelected"></ModalShare>
    </div>
    </div>
    </div>
    <div class="row p-0 m-0">
        <div class="p-0 mx-auto right-bar-public">
            <!-- Phân trang và sắp xếp chuyển sang thành right bar -->
            <div class="row">
                <div class="col-6 pl-0 mb-2">
                    <select content="Sắp xếp theo" v-tippy class="form-control form-control-sm"
                        v-model="big_search.order_by">
                        <option value="id">Mới nhất</option>
                        <option value="article_like">Nổi bật</option>
                    </select>
                </div>
                <div class="col-6 pl-0">
                    <select content="Kiểu sắp xếp" v-tippy class="form-control form-control-sm"
                        v-model="big_search.order_direction">
                        <option value="asc">Tăng dần</option>
                        <option value="desc">Giảm dần</option>
                    </select>
                </div>
            </div>
            <div id="home-news-paginate" class="col-12 mt-2 mb-2 container-paginate">
                <paginate v-if="paginateVisible" :page-count="last_page" :page-range="3" :margin-pages="1"
                    :click-handler="clickCallback" :initial-page="big_search.page" :prev-text="'Prev'"
                    :next-text="'Next'" :container-class="'pagination'" :page-class="'page-item'">
                </paginate>
            </div>
        </div>
    </div>
    </div>
</template>

<script>

import UserRequest from '@/restful/UserRequest';
import Paginate from 'vuejs-paginate-next';
import { FlowerSpinner } from 'epic-spinners';
import CommentComp from '@/components/home/home-public/CommentComp.vue';
import VueMention from '@/components/home/home-public/VueMention.vue';
import ModalLiked from '@/components/home/home-public/ModalLiked.vue';
import ModalShare from '@/components/home/home-public/ModalShare.vue';
import {
    // setDoc, 
    // serverTimestamp, 
    // doc, 
    // addDoc, 
    // updateDoc, 
    // deleteDoc, 
    query,
    orderBy,
    limit,
    onSnapshot,
} from 'firebase/firestore';
import {
    // notifiesRef, 
    articleCommentsRef,
} from '@/firebase';

export default {
    name: "HomeNews",
    components: {
        FlowerSpinner,
        paginate: Paginate,
        CommentComp,
        VueMention,
        ModalLiked,
        ModalShare,
    },
    data() {
        return {
            isLoading: false,
            isUserLoggedIn: false,
            total: 0,
            last_page: 1,
            paginateVisible: true,
            big_search: {
                per_page: 6, // const 
                page: 1,
                order_by: 'id', // article_like , id 
                order_direction: 'desc', // desc , asc
            },
            query: '',
            articles: null,
            modalSettings: {},
            articleSelected: {},
        }
    },
    created() {
        this.getCmtRealtime();
    },
    mounted() {
        this.$setDocumentTitle('Thông báo');

        // Đăng ký sự kiện click toàn cục khi component được mounted
        document.addEventListener('click', this.handleClickOutside);

        const queryString = window.location.search;
        const searchParams = new URLSearchParams(queryString);
        this.big_search = {
            per_page: parseInt(searchParams.get('per_page')) || 6,
            page: searchParams.get('page') || 1,
            order_by: searchParams.get('order_by') || 'id',
            order_direction: searchParams.get('order_direction') || 'desc',
        }

        this.user = JSON.parse(window.localStorage.getItem('user'));
        if (this.user) {
            this.isUserLoggedIn = true;
        }

        this.getDataRecords();
        this.getUsersMention();
    },
    beforeUnmount() {
        this.$emitEvent('NameRouting', '');

        // Gỡ bỏ sự kiện click toàn cục khi component bị destroyed
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        gotoLogin() {
            this.$router.push({ name: 'HomeLogin' });
        },
        gotoArticleDetail: function (record) {
            this.$router.push({ name: 'HomeArticleDetail', params: { slug_name_article: record.article_slug + '-id-record-' + record.id } });
        },
        getUsersMention: async function () {
            try {
                const { data } = await UserRequest.get('user/class-room/get-user-mention')
                // console.log(data);
                this.issues = data;
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
            }
        },
        getDataRecords: async function () {
            this.isLoading = true;
            this.query = `?order_by=${this.big_search.order_by}&order_direction=${this.big_search.order_direction}&page=${this.big_search.page}&per_page=${this.big_search.per_page}`;
            // window.history.pushState({}, null, this.query);
            // window.history.replaceState({}, null, this.query);
            try {
                const { data } = await UserRequest.get('home/article/get-data-news' + this.query)
                this.articles = data.data;
                this.total = data.total;
                this.last_page = data.last_page;
                this.isLoading = false;
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
                this.isLoading = false;
            }
            this.reRenderPaginate();
        },
        getCmtRealtime: async function () {
            const latestCommentQuery = query(
                articleCommentsRef,
                orderBy('fb_created_at', 'desc'),
                limit(1)
            );
            onSnapshot(latestCommentQuery, (snapshot) => {
                if (!snapshot.empty) {
                    const doc = snapshot.docs[0];
                    const data = doc.data();
                    // gán cho this thì lại không được mà đặt biến như thế này thì lại được 
                    if (this.articles != null) { // lần đầu tiên là hàm created nên chưa có this.articles 
                        this.articles.forEach(article => {
                            if (article.id == data.fb_article_id) { // real time for this lesson only
                                this.getComment(article);
                                // console.log(data.fb_article_id);
                                // console.log(data);
                            }
                        });
                    }
                }
            });
        },
        getComment: async function (article) {
            this.isLoadingLesson = true;
            try {
                const { data } = await UserRequest.get('home/article/comment/get-data/' + article.id)
                article.comments = data.comments;
                article.total_comment = data.total_comment;
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
            }
        },
        reRenderPaginate: function () {
            if (this.big_search.page > this.last_page) this.big_search.page = this.last_page;
            this.paginateVisible = false;
            this.$nextTick(() => { this.paginateVisible = true; });
        },
        clickCallback: function (pageNum) {
            this.big_search.page = pageNum;
        },

        toggleModalSetting(articleId) {
            // đóng toàn bộ , chỉ toggle cái hiện tại 
            // Tạo một biến trung gian để lưu trạng thái mới
            const updatedSettings = {
                [articleId]: !this.showModalSetting(articleId),
            };
            // Gán lại cho modalSettings
            this.modalSettings = updatedSettings;
        },
        showModalSetting(articleId) {
            return this.modalSettings[articleId] || false;
        },

        // ngoại trừ các class này thì khi click tất cả các vị trí trong html đều reset về lại như cũ 
        handleClickOutside(event) {
            const modals = document.querySelectorAll('.show_setting, .btn_setting');
            let clickedInsideModal = false;

            // Duyệt qua tất cả các modal và kiểm tra xem sự kiện click có nằm trong modal nào không
            modals.forEach(modal => {
                if (modal.contains(event.target)) {
                    clickedInsideModal = true;
                }
            });

            // Nếu không click vào modal nào, thì đóng tất cả các modal
            if (!clickedInsideModal) {
                this.modalSettings = {};
            }
        },
        selectArticle: function (article) {
            this.articleSelected = article;
        },
        deleteArticle: async function (article_id) {
            try {
                const { messages } = await UserRequest.get(`admin/article/destroy/${article_id}`, true);
                this.$emitEvent('eventSuccess', messages[0]);
                this.modalSettings = {};
                this.getDataRecords();
            }
            catch (error) {
                if (error.errors) this.errors = error.errors;
                else for (let key in this.errors) this.errors[key] = null;
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
            }
        },
        toggleLike(article, action) {
            if (action === "like") {
                if (!article.like_user_ids.includes(this.user.id)) {
                    article.like_user_ids.push(this.user.id);
                    article.like_users.push(this.user);
                    article.like_count += 1; // lí do là vì trong modal Liked không dùng article.like_user_ids.length trong v-if được mà phải dùng v-if="article.like_count > 0"
                }
            } else if (action === "unlike") {
                article.like_user_ids = article.like_user_ids.filter((id) => id !== this.user.id);
                article.like_users = article.like_users.filter((user) => user.id !== this.user.id);
                article.like_count -= 1;
            }
            this.updateLikeStatus(article.id);
        },
        async updateLikeStatus(article_id) {
            try {
                await UserRequest.get(`home/article/toggle-like/${article_id}`);
                // this.$emitEvent('eventSuccess', messages[0]);
                // console.log(messages[0]);
            }
            catch (error) {
                if (error.errors) this.errors = error.errors;
                else for (let key in this.errors) this.errors[key] = null;
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
            }
        },
    },
    watch: {
        big_search: {
            handler: function () {
                this.getDataRecords();
            },
            deep: true
        },
    }
}
</script>
<style scoped src="@/components/home/home-news/middle.css"></style>
<style scoped src="@/components/home/home-news/article-details.css"></style>
<style scoped src="./homenews-responsive.css"></style>
<style scoped src="./public-news-responsive.css"></style>
<style scoped>
/* loading */
.container-loader {
    height: 300px;
    align-items: center;
    align-content: center;
    display: flex !important;
    justify-content: center;
}

.container-loader .loading-component {
    margin: 0 !important;
}

.no-article {
    background-color: white;
    border: 1px solid silver;
    color: black;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 16px;
    border-radius: 6px;
    padding: 10px 0px;
    margin-bottom: 10px;
}
.container-paginate {
    display: flex;
    justify-content: center;
}
/* .right-bar-public {
    position: fixed;
    right: 0px;
} */

/* loading */

.big_article {
    border-radius: 0px !important;
}


</style>
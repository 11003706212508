<template>
    <div v-if="recordSelected">
        <div class="modal fade" id="viewRecord" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Xem chi tiết bài học </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" class="text-danger"><i class="fa-regular fa-circle-xmark"></i></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p><label for="exampleInputEmail1"><strong>Tên bài học</strong></label> : {{ recordSelected.lesson_name }}</p>
                        <p><label for="exampleInputEmail1"><strong>Vị trí bài học</strong></label> : {{ recordSelected.position }}</p>
                        <div class="mb-2">
                            <label for="exampleInputEmail1"><strong>Giới thiệu về bài học</strong></label>
                            <p class="container-html" v-html="recordSelected.lesson_introduce"></p>
                        </div>
                        <div class="cover-course mb-2">
                            <label for="exampleInputEmail1"><strong>Video khóa học</strong></label>
                            <div class="video-lesson" :key="videoKey" > <!-- fix không hiện thanh điều khiển video -->
                                <vue3-video-player :src="recordSelected.lesson_video"></vue3-video-player>
                            </div>
                            <!-- <video controls> 
                                <source :src="recordSelected.lesson_video" type="video/mp4"> 
                                Your browser does not support the video tag.
                            </video> -->
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" ref="closeButton"
                            id="close">Đóng</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "ViewRecord",

    props: {
        recordSelected: Object
    },

    data() {
        return {
            videoKey: 0, // fix lỗi video không reload khi modal được bật lên
        };
    },

    methods: {

    },
    watch: { // fix lỗi video không reload khi modal được bật lên 
        recordSelected() {
            this.videoKey += 1;
        }
    }

}
</script>

<style scoped src="@/components/modal-responsive.css"></style>
<style scoped>
/* video */
.vue3-video-player {
  width: 100%;
  margin: 0 auto;
}

/* video */
.modal-header .close {
    outline: none;
}

.modal-dialog {
    max-width: 800px;
}
.container-html {
    border: 1px solid silver;
    padding: 3px;
}
</style>
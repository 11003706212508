<template>
	<div class="demo">
		<quill-editor v-model:value="state.content" :options="state.editorOption" :disabled="state.disabled"
			@change="onEditorChange($event)" />
	</div>
</template>

<script>
import { reactive, watch } from "vue";

export default {
	props: {
		modelValue: String, // Nhận giá trị từ cha qua v-model
	},
	setup(props, { emit }) {
		const state = reactive({
			content: props.modelValue || "<p></p>", // Đồng bộ nội dung
			editorOption: {
				placeholder: "Nhập nội dung...",
				modules: { // để cho dễ responsive
					toolbar: [
						[
							'bold', 'italic', 'underline', 'strike', 'blockquote', 
							'code-block',{ list: 'ordered' }, { list: 'bullet' },
							{ script: 'sub' }, { script: 'super' },
							{ indent: '-1' }, { indent: '+1' },
							{ direction: 'rtl' },
							{ header: [1, 2, 3, 4, 5, 6, false] },
							{ color: [] }, { background: [] },
							{ font: [] },
							{ align: [] },
							'link', 'image', 'video', 'formula', 'clean',
						],
						// ['blockquote', 'code-block'],
						// [{ header: 1 }, { header: 2 }],                           
						// [{ list: 'ordered' }, { list: 'bullet' }],
						// [{ script: 'sub' }, { script: 'super' }],
						// [{ indent: '-1' }, { indent: '+1' }],
						// [{ direction: 'rtl' }],
						// [{ size: ['small', false, 'large', 'huge'] }],            
						// [{ header: [1, 2, 3, 4, 5, 6, false] }],
						// [{ color: [] }, { background: [] }],
						// [{ font: [] }],
						// [{ align: [] }],
						// ['link', 'image', 'video', 'formula', 'clean'],
					],
				},
			},
			disabled: false,
		});

		// Đồng bộ props.modelValue với state.content
		watch(() => props.modelValue, (newValue) => {
			state.content = newValue;
		});

		// Cập nhật giá trị chỉnh sửa
		const onEditorChange = ({ html }) => {
			state.content = html;
			emit("update:modelValue", html); // Phát sự kiện cho cha
		};

		return {
			state,
			onEditorChange,
		};
	},
};
</script>

<style scoped></style>

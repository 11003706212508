<template>
    <div id="chat-component">
        <p class="title-header mb-2"><i class="fa-solid fa-comments"></i>  Thảo luận bài học</p>
        <div v-if="isLoadingLesson" class="d-flex justify-content-center container-loader">
                <flower-spinner class="loading-component" :animation-duration="2000" :size="30" color="#06C755" />
            </div>
        <div class="container-comment-lesson">
            <CommentComp :commentsWithReplies="commentsWithReplies" :issues="issues" />
        </div>
        <div>
            <VueMention :lesson_id="lesson_id" :issues="issues" :comment_parent_id="null"></VueMention>
        </div>
    </div>
</template>
<script>

import CommentComp from '@/components/user/my-class-room/CommentComp.vue';
import UserRequest from '@/restful/UserRequest';
import VueMention from '@/components/user/my-class-room/VueMention.vue';

import { 
	// setDoc, 
	// serverTimestamp, 
	// doc, 
	// addDoc, 
	// updateDoc, 
	// deleteDoc, 
	query, 
	orderBy, 
	limit, 
	onSnapshot, 
} from 'firebase/firestore';
import { 
	// notifiesRef, 
	commentsRef, 
} from '@/firebase';

export default {
    name: "ChatComponent",
    components: {
        CommentComp,
		VueMention,
    },
    setup() {

    },
    props: {
        lesson: Object,
        chapter: Object,
        course: Object,
    },
    data() {
        return {
            isLoadingComment: false,
            lesson_id: null,
            commentsWithReplies: null,
			issues: null,
        }
    },
    created() {
        this.getCmtRealtime();
    },
    mounted() {
        this.$emitEvent('eventActiveTab', '');
		this.lesson_id = parseInt(this.$extractIdFromSlug(this.$route.params.slug_name_lesson), 10);
        this.getComment(); // phải gọi hàm này trước 
		this.getUsersMention();
    },

    methods: {
        getUsersMention: async function () {
			try {
				const { data } = await UserRequest.get('user/class-room/get-user-mention')
				// console.log(data);
				this.issues = data;
			}
			catch (error) {
				if (error.messages) this.$emitEvent('eventError', error.messages[0]);
			}
		},
        getComment: async function () {
            this.isLoadingLesson = true;
            try {
                const { data } = await UserRequest.get('user/comment/get-data/' + this.lesson_id)
                // console.log(data);
                this.commentsWithReplies = data;
                // console.log(data);
                this.isLoadingLesson = false;
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
                this.isLoadingLesson = false;
            }
        },
        getCmtRealtime: async function () {
            const latestCommentQuery = query(
                commentsRef,
                orderBy('fb_created_at', 'desc'),
                limit(1)
            );
            onSnapshot(latestCommentQuery, (snapshot) => {
                if (!snapshot.empty) {
                    const doc = snapshot.docs[0];
					const data = doc.data(); 
                    // gán cho this thì lại không được mà đặt biến như thế này thì lại được 
                    var lesson_id = parseInt(this.$extractIdFromSlug(this.$route.params.slug_name_lesson), 10);
                    this.lesson_id = lesson_id;
                    if(lesson_id == data.fb_lesson_id) { // real time for this lesson only
                        // và có một lỗi gì đó khi event load này càng ngày thì in ra càng nhiều 
                        this.getComment();
                        // this.$emitEvent('eventSuccess', 'load !');
                        // console.log(this.lesson_id);
                        // console.log(data.fb_lesson_id);
                        // console.log(data);
                    }
				}
            });
        },
    },
    watch: {

    }
}
</script>

<style scoped>

.container-comment-lesson {
    width: 100%;
    overflow-y: scroll;
}

.div_microphone {
    cursor: pointer;
}

.title-header {
    font-size: 19px;
    color: var(--user-color);
    transition: all 0.5s ease;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
}

.form-control {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: 0.2rem;
    line-height: 1.5;
}

@media screen and (min-width: 1201px) {
    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .coverRecord {
        min-width: 150px;
    }

    .displaytext {
        min-width: 150px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;
    }

    table img {
        min-width: 60px;
        min-height: 60px;
        /* max-width: 60px; */
        /* max-height: 60px; */
        object-fit: cover;
    }

    td .fa-solid {
        font-size: 20px;
    }

}

@media screen and (max-width: 1200px) {
    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .coverRecord {
        min-width: 120px;
    }

    .displaytext {
        min-width: 100px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 11px;
    }

    .fa-solid {
        font-size: 15px;
    }

    table img {
        min-width: 50px;
        min-height: 50px;
        /* max-width: 50px;
        max-height: 50px; */
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 8px;
    }

    .form-control,
    .pagination {
        font-size: 12px !important;
    }

    #main {
        padding: 1% 1%;
        margin: 0;
    }
}

@media screen and (max-width: 992px) {
    .title-header {
        font-size: 15px;
    }

    .colorTitle {
        font-size: 14px;
    }

    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .coverRecord {
        min-width: 140px;
    }

    .displaytext {
        min-width: 110px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 11px;
    }

    .fa-solid {
        font-size: 16px;
    }

    table img {
        min-width: 50px;
        min-height: 50px;
        max-width: 50px;
        max-height: 50px;
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 8px;
    }

    .form-control,
    .pagination {
        font-size: 12px !important;
    }

    #main {
        padding: 1% 1%;
        margin: 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        padding-left: 0;
        padding-right: 10px;
    }

    .btn {
        padding: 0px 4px;
        margin-top: 3px;
    }

    .input-group-text {
        padding: 0 8px;
    }
}

@media screen and (max-width: 768px) {

    .title-header,
    .colorTitle {
        font-size: 13px;
    }

    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .coverRecord {
        min-width: 100px;
    }

    .displaytext {
        min-width: 90px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 11px;
    }

    .fa-solid {
        font-size: 13px;
    }

    table img {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 5px;
    }

    .form-control,
    .pagination {
        font-size: 12px !important;
    }

    #page {
        min-width: 45px;
    }

    .form-control {
        padding: 1px 1px;
    }

    #main {
        padding: 1% 1%;
        margin: 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        padding-right: 5px;
    }

    .btn {
        padding: 0px 4px;
        margin-top: 3px;
    }

    .input-group-text {
        padding: 0 4px;
    }

    .input-group-prepend {
        font-size: 12px;

    }
}

@media screen and (max-width: 576px) {

    .title-header,
    .colorTitle {
        font-size: 12px;
    }

    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .nameMember {
        margin-left: 8px;
    }

    .coverRecord {
        min-width: 100px;
    }

    .displaytext {
        min-width: 70px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 10px;
    }

    .fa-solid {
        font-size: 10px;
    }

    table img {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 4px;
    }

    .form-control,
    .pagination {
        font-size: 10px !important;
    }

    .form-control {
        padding: 1px 1px;
        height: 25px;
    }

    #page {
        min-width: 45px;
    }

    #main {
        padding: 1% 1%;
        margin: 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        padding-right: 5px;
    }

    .btn {
        padding: 0px 4px;
    }

    .input-group-text {
        padding: 0 0.5px;
    }

    .input-group-prepend {
        font-size: 11px;

    }
}

@media screen and (max-width: 424px) {

    .title-header,
    .colorTitle {
        font-size: 11px;
    }

    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .nameMember {
        margin-left: 8px;
    }

    .coverRecord {
        min-width: 80px;
    }

    .displaytext {
        min-width: 70px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 9px;
    }

    .fa-solid {
        font-size: 10px;
    }

    table img {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 4px;
    }

    .form-control,
    .pagination {
        font-size: 9px !important;
    }

    .form-control {
        padding: 0.5px 0;
        height: 25px;
    }

    #page {
        min-width: 40px;
    }

    #main {
        padding: 1% 1%;
        margin: 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        padding-right: 0;
    }

    .btn {
        padding: 0px 4px;
    }

    .input-group-text {
        padding: 0 0.5px;
    }

    .input-group-prepend {
        font-size: 10px;

    }

    #main .ml-2 {
        margin-left: 3px !important;
    }
}
</style>

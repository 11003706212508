// helper.js
export function formatDate(date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function getYouTubeEmbedUrl(url) {
    const regex = /[?&]v=([^&#]*)/; // Biểu thức chính quy để tách ID video
    const match = url.match(regex); // Tìm kiếm ID trong URL
    const videoId = match ? match[1] : null; // Nếu tìm thấy thì lấy ID, nếu không thì trả về null

    if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
    } else {
        return null; // Trường hợp không tìm thấy ID
    }
}

export function extractIdFromSlug(slug) {
    const parts = slug.split('-id-record-');
    if (parts.length === 2) {
        const idRecord = parts[1];
        if (!isNaN(idRecord) && idRecord.trim() !== '') return idRecord;
    }
    this.$router.push({ name: 'CommonNotFound' });
}

export function formatDate2(date) {
    const formattedDate = new Date(date);

    const day = formattedDate.getDate();
    const month = formattedDate.getMonth() + 1;
    const year = formattedDate.getFullYear();

    const formattedDateString = `${day}/${month}/${year}`;

    return formattedDateString;
}

export function removeStringFromFileLink(inputString, fileLink) {
    if (fileLink) return fileLink.replace(inputString, '');
    else return '';
}

export function formatNumber(input) {
    return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function extractFileName(url) {
    if (url) {
        const urlParts = url.split('/');
        return urlParts[urlParts.length - 1];
    }
}

export function highlightMention(content) {
    return content.replace(/@(\w+)/g, '<span style="background-color: #f8f8f8;font-weight: bold;color: red;padding: 3px;border-radius: 6px;">$1</span>');
}

export function generateFirestoreId() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 20; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
}

// src/helpers.js
export function formatDateTime(timestamp) {
    const date = new Date(timestamp);
    // Định dạng ngày và giờ theo dạng 'YYYY-MM-DD HH:mm:ss'
    const dateFormatted = date.toISOString().split('T')[0];
    const timeFormatted = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    // Kết hợp ngày và giờ thành 1 chuỗi
    return `${dateFormatted} ${timeFormatted}`;
}

export function truncatedTitle(title, maxLength = 150) {
    if (title.length > maxLength) return title.slice(0, maxLength) + '...';
    else return title;
}

export function setDocumentTitle(title) {
    document.title = title + ' | Tech BIM';
}

export function gotoHomePersonal (user_id) {
    this.$router.push({ 
        name: 'HomePublic', 
        query: { user_id: user_id } 
    }).then(() => {
        window.location.reload(); // Reload lại trang sau khi điều hướng hoàn tất
    });
}
import { createRouter, createWebHistory } from 'vue-router'
import useEventBus from '@/composables/useEventBus'
const { emitEvent } = useEventBus();
import NProgress from 'nprogress';

// user 
import UserMain from '@/components/user/UserMain.vue'
import AccountSetting from '@/components/user/account-setting/AccountSetting.vue'
import MemberAccount from '@/components/user/member-account/MemberAccount.vue'
import ManageStudent from '@/components/user/manage-student/ManageStudent.vue'
import ManageCourse from '@/components/user/manage-course/ManageCourse.vue'
import ManageChapter from '@/components/user/manage-chapter/ManageChapter.vue'
import ManageDocument from '@/components/user/manage-document/ManageDocument.vue'
import ManageLesson from '@/components/user/manage-lesson/ManageLesson.vue'
import ManageLibrary from '@/components/user/manage-library/ManageLibrary.vue'
import ManageCoursePermission from '@/components/user/manage-course-permission/ManageCoursePermission.vue'
import ManageLibraryPermission from '@/components/user/manage-library-permission/ManageLibraryPermission.vue'
import UserMyLibrary from '@/components/user/user-my-library/UserMyLibrary.vue'
import UserMyCourse from '@/components/user/user-my-course/UserMyCourse.vue'
import MyClassRoom from '@/components/user/my-class-room/MyClassRoom.vue'
import LessonAndChat from '@/components/user/my-class-room/LessonAndChat.vue'
import ManageChannel from '@/components/user/manage-channel/ManageChannel.vue'
import ManageArticle from '@/components/user/manage-article/ManageArticle.vue'

// import ManageContent from '@/components/user/manage-content/ManageContent'
// import ManageBroadcast from '@/components/user/manage-broadcast/ManageBroadcast'
// import StatisticalChannel from '@/components/user/statistical-channel/StatisticalChannel'
// import RealtimeChat from '@/components/user/chat/RealtimeChat'

// home
import HomeMain from '@/components/home/HomeMain.vue'
import HomeIntroduct from '@/components/home/HomeIntroduct.vue'
import HomeCourse from '@/components/home/HomeCourse.vue';
import HomeCourseDetail from '@/components/home/HomeCourseDetail.vue';
import HomeLibrary from '@/components/home/HomeLibrary.vue';
import HomeLibraryDetail from '@/components/home/HomeLibraryDetail.vue';
// import HomeGallery from '@/components/home/HomeGallery.vue';
import HomeLogin from '@/components/home/auth/HomeLogin.vue';
import UserResetPassword from '@/components/home/auth/UserResetPassword.vue'
import HomeRegister from '@/components/home/auth/HomeRegister.vue';
import HomeChannel from '@/components/home/HomeChannel.vue';
import HomePublic from '@/components/home/HomePublic.vue';
// import HomeNews from '@/components/home/HomeNews.vue';
import HomeArticleDetail from '@/components/home/HomeArticleDetail.vue';

// other 
import CommonNotFound from '@/components/common/CommonNotFound.vue'

// middleware authUser
const authUser = (to, from, next) => {
    const user = localStorage.getItem('user');
    if (user) next();
    else {
        next({ name: 'HomeLogin' });
        emitEvent('eventError', 'Bạn cần phải đăng nhập !');
    }
};

const roleAdmin = (to, from, next) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user.role === 1) next();
    else {
        next({ name: 'AccountSetting' });
        emitEvent('eventError', 'Bạn không có quyền !');
    }
};


// check user logged 
const loggedUser = (to, from, next) => {
    const user = localStorage.getItem('user');
    if (user) next({ name: 'AccountSetting' });
    else next();
};

const routes = [
    {
        path: '',
        component: HomeMain,
        name: 'HomeMain',
        children: [
            { path: '', name: 'HomeIntroduct', component: HomeIntroduct },
            { path: 'login', name: 'HomeLogin', component: HomeLogin },
            { path: 'register', name: 'HomeRegister', component: HomeRegister },
            { path: '/reset-password', component: UserResetPassword, name: 'UserResetPassword', beforeEnter: loggedUser },
            { path: 'khoa-hoc', name: 'HomeCourse', component: HomeCourse },
            { path: 'khoa-hoc/:slug_name_course', name: 'HomeCourseDetail', component: HomeCourseDetail },
            { path: 'library', name: 'HomeLibrary', component: HomeLibrary },
            { path: 'library/:slug_name_library', name: 'HomeLibraryDetail', component: HomeLibraryDetail },
            // { path: 'gallery', name: 'HomeGallery', component: HomeGallery },
            { path: 'channel', name: 'HomeChannel', component: HomeChannel },
            // { path: 'news', name: 'HomeNews', component: HomeNews },
            { path: 'cong-dong', name: 'HomePublic', component: HomePublic },
            { path: 'bai-viet/:slug_name_article', name: 'HomeArticleDetail', component: HomeArticleDetail },
        ]
    },
    {
        path: '/dashboard',
        component: UserMain,
        name: 'UserMain',
        beforeEnter: authUser,
        children: [
            { path: 'account-setting', name: 'AccountSetting', component: AccountSetting },
            { path: 'member-account', name: 'MemberAccount', component: MemberAccount, beforeEnter: roleAdmin },
            { path: 'student-account', name: 'ManageStudent', component: ManageStudent, beforeEnter: roleAdmin },
            { path: 'manage-course', name: 'ManageCourse', component: ManageCourse, beforeEnter: roleAdmin },
            { path: 'manage-library', name: 'ManageLibrary', component: ManageLibrary, beforeEnter: roleAdmin },
            { path: 'manage-chapter/:slug_name_course', name: 'ManageChapter', component: ManageChapter, beforeEnter: roleAdmin },
            { path: 'manage-document/:slug_name_course', name: 'ManageDocument', component: ManageDocument, beforeEnter: roleAdmin },
            { path: 'manage-course-permission/:slug_name_course', name: 'ManageCoursePermission', component: ManageCoursePermission, beforeEnter: roleAdmin },
            { path: 'manage-library-permission/:slug_name_library', name: 'ManageLibraryPermission', component: ManageLibraryPermission, beforeEnter: roleAdmin },
            { path: 'manage-lesson/:slug_name_chapter', name: 'ManageLesson', component: ManageLesson, beforeEnter: roleAdmin },
            { path: 'my-library', name: 'UserMyLibrary', component: UserMyLibrary },
            { path: 'my-course', name: 'UserMyCourse', component: UserMyCourse },
            { 
                path: 'my-class-room/:slug_name_course', 
                name: 'MyClassRoom', 
                component: MyClassRoom,
                children: [
                    { path: ':slug_name_lesson', name: 'LessonAndChat', component: LessonAndChat },
                ]
            },
            { path: 'manage-channel', name: 'ManageChannel', component: ManageChannel, beforeEnter: roleAdmin },
            { path: 'manage-article', name: 'ManageArticle', component: ManageArticle},
        ]
    },
    { path: '/:CommonNotFound(.*)*', component: CommonNotFound, name: 'CommonNotFound' }
];

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes: routes,
    // scrollBehavior(to, from, savedPosition) {
    //     if (to.hash) {
    //         return {
    //             // Cuộn đến phần tử có ID được chỉ định trong hash
    //             selector: to.hash,
    //             behavior: 'smooth' // Cuộn mượt mà
    //         };
    //     } else if (savedPosition) {
    //         // Nếu có vị trí cuộn được lưu, cuộn về vị trí đó
    //         return savedPosition;
    //     } else {
    //         // Mặc định cuộn lên đầu trang
    //         return { top: 0 };
    //     }
    // }
});

router.beforeResolve((to, from, next) => {
    // Nếu đây không phải là lần tải trang đầu tiên.
    if (to.name) {
        // Bắt đầu thanh tiến trình tuyến đường.
        NProgress.start()
    }
    next()
})

router.afterEach(() => {
    // Hoàn thành hoạt ảnh của thanh tiến trình tuyến đường.
    NProgress.done()
})

export default router

<template>
    <div class="home-course">
        <div v-if="isLoading" class="d-flex justify-content-center container-loader">
            <flower-spinner class="loading-component" :animation-duration="2000" :size="30" color="#06C755" />
        </div>
        <div v-if="!isLoading" class="parent">
            <div class="left-bar">
                <div class="left-record">
                    <div v-if="record.library_image" class="image-container mt-2">
                        <img 
                        :src="record.library_image ? record.library_image : require('@/assets/home/item-source.jpg')"
                        alt="Thumbnail">
                    </div>
                    <div class="record-introduce" v-html="record.library_introduce"></div>
                </div>
            </div>
            <div class="right-bar">
                <div class="p-3">
                    <p class="course-price" v-if="record.library_price"> <span><i
                                class="fa-solid fa-tags"></i></span> {{ this.$formatNumber(record.library_price)
                        }} VNĐ</p>
                    <p class="course-detail mt-1 mb-2"><span class="mr-1"><i class="fa-brands fa-unity"></i></span>
                        Tên thư viện: {{ record.library_name }}</p>
                </div>
                <hr>
                <div class="payment p-3">
                    <p class="course-payment mb-2 text-success" > ĐẶT MUA THƯ VIỆN</p>
                    <p class="mb-2 payment-infor"><span><i class="fa-solid fa-headset"></i></span> SĐT LIÊN HỆ TƯ VẤN : <strong>0123456789</strong></p>
                    <p class="mb-2 payment-infor"><span><i class="fa-solid fa-envelope"></i></span> EMAIL : <a
                            href="mailto:lehongphuongweb@gmail.com"><strong>lehongphuongweb@gmail.com</strong></a></p>
                    <p class="mb-2 payment-infor"><strong>QUÉT MÃ QR ĐỂ LIÊN HỆ</strong></p>
                    <img class="zalo-qr-group" :src="require('@/assets/contact-zalo.jpg')" alt="Course Thumbnail">
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import UserRequest from '@/restful/UserRequest';
import { FlowerSpinner } from 'epic-spinners';

export default {
    name: "HomeLibraryDetail",
    components: {
        FlowerSpinner,
    },
    data() {
        return {
            library_id: null,
            isLoading: false,
            record: {
                library_name: null,
                library_introduce: null,
                library_price: null,
                library_slug: null,
                library_image: null,
            },
        }
    },
    mounted() {
        this.$setDocumentTitle('Chi tiết Thư viện');
        this.$emitEvent('NameRouting', 'Libraries');

        this.library_id = this.$extractIdFromSlug(this.$route.params.slug_name_library);
        // console.log('Record ID:', this.library_id);

        this.getDataRecord();

        // v-if thumbnail 
        // window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
        getDataRecord: async function () {
            this.isLoading = true;
            window.history.replaceState({}, null, this.query);
            try {
                const { data } = await UserRequest.get('user/library/get-data/' + this.library_id)
                this.record = data;
                this.isLoading = false;
                this.$emitEvent('NameRouting', this.record.library_name);
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
                this.isLoading = false;
            }
        },
    },
    beforeUnmount() {
        this.$emitEvent('NameRouting', '');
    },
}
</script>
<style scoped src="./homelibrarydetail-responsive.css"></style>
<style scoped>
.home-course {
    padding: 0px 10%;
    background-color: #ECE9E9;
    padding-top: 20px;
    padding-bottom: 20px;
}

.parent {
    display: flex;
    justify-content: space-between;
}

.left-bar {
    flex: 8;
    margin-right: 10px;
}

.left-record {
    background-color: white;
    padding: 20px 20px;
    /* border-radius: 10px; */
    /* border: 1px solid; */
}

.right-bar {
    flex: 4;
    margin-left: 10px;
    background-color: white;
    /* padding: 10px; */
    height: fit-content;
    /* border-radius: 10px; */
    /* border: 1px solid; */
    position: sticky;
    top: 100px;
}

.record-introduce {
    font-size: 20px;
}

/* image */
.image-container {
    max-width: 100%;
    /* position: relative; */
    /* padding-bottom: 56.25%; */
    height: 0;
    /* overflow: hidden; */
}
.image-container img {
    width: 100%;
    border-radius: 10px;
}

.image-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* course-name */
.course-name {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    letter-spacing: 0px;
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: bold;
}

.list-chapter {
    padding-left: 30px;
}

.list-chapter li {
    list-style: initial;
    margin: 5px 0px;
    font-size: 15px;
}

/* intro-teacher */
.instructor-card {
    display: flex;
    align-items: center;
    padding: 20px;
    /* border-radius: 8px; */
    border: 1px solid #ddd;

    margin-top: 30px;
    padding: 40px;
    background-color: #F7F8FA;
}

.instructor-image img {
    width: 80px;
    height: 80px;
    max-width: none !important;
    object-fit: cover;
    border-radius: 100%;
}

.instructor-info {
    margin-left: 15px;
}

.instructor-title {
    font-size: 14px;
    color: #888;
}

.instructor-name {
    font-size: 18px;
    color: #4A90E2;
    font-weight: bold;
}

.instructor-description {
    font-size: 14px;
    color: #333;
    margin-top: 8px;
}

.instructor-description strong {
    font-weight: bold;
}

/* course-price */
.course-price {
    margin-bottom: 20px;
    text-align: left;
    /* color: #D41701; */
    color: #D41701;
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;

}

/* payment */
.course-payment {
    margin-bottom: 20px;
    text-align: left;
    color: black;
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
}

.zalo-qr-group {
    width: 60%;
}

/* loading */
.container-loader {
    height: 300px;
    align-items: center;
    align-content: center;
    display: flex !important;
    justify-content: center;
}
.container-loader .loading-component {
    margin: 0 !important;
}

/* loading */
</style>
<template>
    <div id="main">
        <p class="title-header"><i class="fa-solid fa-bookmark"></i> {{ lesson.lesson_name }}</p>
        <div data-toggle="collapse" data-target="#introLesson" class="mb-1">
            <p v-tippy="{content: 'Collapse Mô tả bài học'}" class="p-description color-span-first"><i class="fa-solid fa-book"></i> Mô tả về bài học </p>
        </div>
        <div class="collapse show mb-2" id="introLesson">
            <div class="card card-body" v-html="lesson.lesson_introduce"></div>
        </div>
        <div class="video-lesson" :key="videoKey" >
            <!-- <video controls @contextmenu.prevent="true" controlsList="nodownload">
                <source :src="lesson.lesson_video" type="video/mp4">
                Your browser does not support the video tag.
            </video> -->
            <vue3-video-player :src="this.lesson.lesson_video"></vue3-video-player>
        </div>
    </div>
</template>
<script>


export default {
    name: "LessonComponent",
    components: {

    },
    props: {
        lesson: Object,
        chapter: Object,
        course: Object,
    },
    setup() {

    },
    data() {
        return {
            videoKey: 0, // fix lỗi video không reload khi modal được bật lên
        };
    },
    mounted() {
        this.$emitEvent('eventActiveTab', '');
    },

    methods: {

    },
    watch: { // fix lỗi video không reload khi modal được bật lên 
        lesson() {
            this.videoKey += 1;
        }
    }
}
</script>

<style scoped>
.video-lesson {
  margin-top: 10px;
}
.vue3-video-player {
  width: 100%;
  margin: 0 auto;
}

/* span-intro */
.span-intro {
    font-size: 18px;
}

.p-description {
    width: fit-content;;
}

.color-span-first {
    font-size: 18px;
    cursor: pointer;
    color: #007BFF;
}

/* video-lesson */
.video-lesson video {
    border-radius: 6px;
}

.div_microphone {
    cursor: pointer;
}

.title-header {
    font-size: 19px;
    color: var(--user-color);
    transition: all 0.5s ease;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
}

.form-control {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: 0.2rem;
    line-height: 1.5;
}

@media screen and (min-width: 1201px) {
    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .coverRecord {
        min-width: 150px;
    }

    .displaytext {
        min-width: 150px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;
    }

    table img {
        min-width: 60px;
        min-height: 60px;
        /* max-width: 60px; */
        /* max-height: 60px; */
        object-fit: cover;
    }

    td .fa-solid {
        font-size: 20px;
    }

}

@media screen and (max-width: 1200px) {
    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .coverRecord {
        min-width: 120px;
    }

    .displaytext {
        min-width: 100px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 11px;
    }

    .fa-solid {
        font-size: 15px;
    }

    table img {
        min-width: 50px;
        min-height: 50px;
        /* max-width: 50px;
        max-height: 50px; */
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 8px;
    }

    .form-control,
    .pagination {
        font-size: 12px !important;
    }

    #main {
        padding: 1% 1%;
        margin: 0;
    }
}

@media screen and (max-width: 992px) {
    .title-header {
        font-size: 15px;
    }

    .colorTitle {
        font-size: 14px;
    }

    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .coverRecord {
        min-width: 140px;
    }

    .displaytext {
        min-width: 110px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 11px;
    }

    .fa-solid {
        font-size: 16px;
    }

    table img {
        min-width: 50px;
        min-height: 50px;
        max-width: 50px;
        max-height: 50px;
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 8px;
    }

    .form-control,
    .pagination {
        font-size: 12px !important;
    }

    #main {
        padding: 1% 1%;
        margin: 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        padding-left: 0;
        padding-right: 10px;
    }

    .btn {
        padding: 0px 4px;
        margin-top: 3px;
    }

    .input-group-text {
        padding: 0 8px;
    }
}

@media screen and (max-width: 768px) {

    .title-header,
    .colorTitle {
        font-size: 13px;
    }

    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .coverRecord {
        min-width: 100px;
    }

    .displaytext {
        min-width: 90px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 11px;
    }

    .fa-solid {
        font-size: 13px;
    }

    table img {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 5px;
    }

    .form-control,
    .pagination {
        font-size: 12px !important;
    }

    #page {
        min-width: 45px;
    }

    .form-control {
        padding: 1px 1px;
    }

    #main {
        padding: 1% 1%;
        margin: 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        padding-right: 5px;
    }

    .btn {
        padding: 0px 4px;
        margin-top: 3px;
    }

    .input-group-text {
        padding: 0 4px;
    }

    .input-group-prepend {
        font-size: 12px;

    }
}

@media screen and (max-width: 576px) {

    .title-header,
    .colorTitle {
        font-size: 12px;
    }

    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .nameMember {
        margin-left: 8px;
    }

    .coverRecord {
        min-width: 100px;
    }

    .displaytext {
        min-width: 70px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 10px;
    }

    .fa-solid {
        font-size: 10px;
    }

    table img {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 4px;
    }

    .form-control,
    .pagination {
        font-size: 10px !important;
    }

    .form-control {
        padding: 1px 1px;
        height: 25px;
    }

    #page {
        min-width: 45px;
    }

    #main {
        padding: 1% 1%;
        margin: 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        padding-right: 5px;
    }

    .btn {
        padding: 0px 4px;
    }

    .input-group-text {
        padding: 0 0.5px;
    }

    .input-group-prepend {
        font-size: 11px;

    }
}

@media screen and (max-width: 424px) {

    .title-header,
    .colorTitle {
        font-size: 11px;
    }

    table {
        max-width: 100%;
        vertical-align: middle;
    }

    .nameMember {
        margin-left: 8px;
    }

    .coverRecord {
        min-width: 80px;
    }

    .displaytext {
        min-width: 70px;
        overflow: hidden;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;

    }

    .break {
        word-break: break-all;
    }

    table {
        font-size: 9px;
    }

    .fa-solid {
        font-size: 10px;
    }

    table img {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        object-fit: cover;
    }

    .table td,
    .table th {
        padding: 4px;
    }

    .form-control,
    .pagination {
        font-size: 9px !important;
    }

    .form-control {
        padding: 0.5px 0;
        height: 25px;
    }

    #page {
        min-width: 40px;
    }

    #main {
        padding: 1% 1%;
        margin: 0;
    }

    .col-1,
    .col-2,
    .col-3 {
        padding-right: 0;
    }

    .btn {
        padding: 0px 4px;
    }

    .input-group-text {
        padding: 0 0.5px;
    }

    .input-group-prepend {
        font-size: 10px;

    }

    #main .ml-2 {
        margin-left: 3px !important;
    }
}
</style>

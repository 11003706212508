<template>
	<div>
		<div v-for="comment in commentsWithReplies" :key="comment.comment_id">
			<div :style="{ marginLeft: level * 60 + 'px' }">
				<div class="container-comment" :id="'div-'+comment.comment_id">
					<div class="avatar-user" @click="this.$gotoHomePersonal(comment.user_id)"><img
							:src="comment.user_avatar ? comment.user_avatar : require('@/assets/home/avatar-default.jpg')"
							alt="Avatar"></div>
					<div class="main-user">
						<div class="infor-user">
							<div class="infor-user-bg">
								<p class="name-user" @click="this.$gotoHomePersonal(comment.user_id)">{{ comment.user_fullname }}</p>
								<p class="content-comment" v-html="this.$highlightMention(comment.comment_content)"></p>
							</div>
							<div class="container-edit-delete" v-if="isUserLoggedIn && (comment.user_id === user.id || user.role === 1)">
								<!-- Chỉ hiện với người sở hữu hoặc admin -->
								<p class="open-modal-edit" @click="toggleModalEditCmtBox(comment.comment_id)"><button><i
											class="fa-solid fa-ellipsis"></i></button></p>
								<div class="modal-edit-delete-cmt" v-if="showModalEditCmtBox(comment.comment_id)">
									<!-- admin chỉ được xóa, không được chỉnh sửa -->
									<p class="p-edit-delete p-edit" v-if="comment.user_id === user.id"
										@click="toggleEditCmtBox(comment.comment_id)">Chỉnh sửa</p>
									<p class="p-edit-delete p-delete" @click="deleteComment(comment.comment_id)">Xóa</p>
								</div>
							</div>
						</div>
						<div class="edit-cmt-box" v-if="showEditCmtBox(comment.comment_id)">
							<VueMentionUpdate :article_id="article_id" :issues="issues" :comment_id="comment.comment_id"
								:comment_parent_id="comment.comment_id" :comment_content="comment.comment_content">
							</VueMentionUpdate>
						</div>
						<div v-if="isUserLoggedIn" class="container-reply">
							<p class="container-reply-btn">
								<span class="sp-date">{{ this.$formatDateTime(comment.updated_at) }}</span>
								<span class="sp-reply" v-if="level < 3" @click="toggleReplyBox(comment.comment_id)"><i class="fa-solid fa-reply"></i> Trả lời</span>
							</p>
							<div v-if="showReplyBox(comment.comment_id)">
								<div>
									<VueMention :article_id="article_id" :issues="issues"
										:comment_parent_id="comment.comment_id"></VueMention>
								</div>
								<!-- <textarea style="width: 30vw;" class="form-control" id="exampleFormControlTextarea1" rows="1"></textarea> -->
							</div>
						</div>
						<div class="show-comment" @click="toggleCollapse(comment.comment_id)" data-toggle="collapse"
							:href="'#collapseExample' + comment.comment_id" aria-expanded="false"
							aria-controls="collapseExample">
							<strong v-if="comment.replies && comment.replies.length">
								{{ isCollapsed(comment.comment_id) ? 'Xem' : 'Ẩn' }} {{ countTotalReplies(comment) }}
								phản hồi
							</strong>
						</div>
					</div>
				</div>
			</div>
			<div v-if="comment.replies && comment.replies.length" :id="'collapseExample' + comment.comment_id"
				class="collapse show">
				<div>
					<CommentComp :isUserLoggedIn="isUserLoggedIn" :article_id="article_id" :issues="issues" :commentsWithReplies="comment.replies" :level="level + 1" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UserRequest from '@/restful/UserRequest';
import VueMention from '@/components/home/home-public/VueMention.vue';
import VueMentionUpdate from '@/components/home/home-public/VueMentionUpdate.vue';
import {
	setDoc,
	serverTimestamp,
	doc,
	// addDoc, 
	// updateDoc, 
	// deleteDoc, 
	// query, 
	// orderBy, 
	// limit, 
	// onSnapshot, 
} from 'firebase/firestore';
import {
	// notifiesRef, 
	articleCommentsRef,
} from '@/firebase';

export default {
	name: 'CommentComp',
	components: {
		VueMention,
		VueMentionUpdate,
	},
	props: {
		commentsWithReplies: {
			type: Array,
			required: true
		},
		level: {
			type: Number,
			default: 0
		},
		issues: Array,
		article_id: Number,
		isUserLoggedIn: Boolean,
	},
	data() {
		return {
			collapsedComments: [],
			replyBoxes: {},
			modalEditCmtBoxes: {},
			editCmtBoxes: {},
			user: {
				id: null,
				email: null,
                google_id: null,
                is_have_password: null,
				user_name: null,
				role: null,
				is_delete: null,
				is_block: null,
				name: null,
				phone: null,
				email_verified_at: null,
				created_at: null,
				updated_at: null,
				access_token: null,
				token_type: null,
				expires_in: null,
			},
		};
	},
	mounted() {
		this.user = JSON.parse(window.localStorage.getItem('user'));

		// Đăng ký sự kiện click toàn cục khi component được mounted
		document.addEventListener('click', this.handleClickOutside);

		// Lắng nghe sự kiện update comment success để close modal 
		this.$onEvent('closeEditCmtBox', () => {
            this.resetModal();
        });

		// đi đến comment có id = "div-comment-id"
		// Lấy hash từ URL
		const hash = window.location.hash.substring(1);

		if (hash) {
			const element = document.getElementById(hash);
			if (element) {
				// Lấy vị trí của phần tử
				const elementPosition = element.getBoundingClientRect().top + window.scrollY;

				// Cuộn đến vị trí phần tử trừ đi 60px
				window.scrollTo({
					top: elementPosition,
					behavior: 'smooth'
				});
			}
		}
	},
	beforeUnmount() {
		// Gỡ bỏ sự kiện click toàn cục khi component bị destroyed
		document.removeEventListener('click', this.handleClickOutside);

	},
	methods: {
		deleteComment: async function (comment_id) {
			try {
				const { data, messages } = await UserRequest.get('home/article/comment/delete/' + comment_id)
				this.deleteCommentToFirebase(data, messages);
				// console.log(data);
			}
			catch (error) {
				if (error.messages) this.$emitEvent('eventError', error.messages[0]);
			}
		},
		async deleteCommentToFirebase(data, messages) { // phải đảm bảo là comment_id này để hàm Get comments[0] từ firebase  
			try { // thông báo cho firebase một thông báo để firebase xóa comment 
				await setDoc(doc(articleCommentsRef, data.comment_id), {
					fb_article_id: data.article_id,
					fb_from_user_id: data.comment_from_user_id,
					fb_to_user_ids: data.comment_to_user_ids,
					fb_content: 'Bình luận này đã được xóa !',
					fb_parent_id: data.comment_parent_id,
					fb_created_at: serverTimestamp(),
					fb_updated_at: serverTimestamp(),
				});
				this.$emitEvent('eventSuccess', messages[0]);
				this.text = '';
			} catch (error) {
				console.error("Error adding document: ", error);
			}
		},

		toggleCollapse(commentId) {
			this.collapsedComments = {
				...this.collapsedComments,
				[commentId]: !this.isCollapsed(commentId)
			};
		},
		isCollapsed(commentId) {
			return this.collapsedComments[commentId] || false;
		},
		countTotalReplies(comment) { // tính tổng số comment con trong n cấp (chứ không chỉ tính cấp con gần nhất)
			let count = 0;
			if (comment.replies && comment.replies.length > 0) {
				count += comment.replies.length; // Đếm các comment con trực tiếp
				comment.replies.forEach(reply => {
					count += this.countTotalReplies(reply); // Đệ quy để đếm các comment con của reply
				});
			}
			return count;
		},

		toggleReplyBox(commentId) {
			this.replyBoxes = {
				...this.replyBoxes,
				[commentId]: !this.showReplyBox(commentId),
			};
		},
		showReplyBox(commentId) {
			return this.replyBoxes[commentId] || false;
		},

		toggleModalEditCmtBox(commentId) {
			this.modalEditCmtBoxes = {
				...this.modalEditCmtBoxes,
				[commentId]: !this.showModalEditCmtBox(commentId),
			};
		},
		showModalEditCmtBox(commentId) {
			return this.modalEditCmtBoxes[commentId] || false;
		},

		toggleEditCmtBox(commentId) {
			this.editCmtBoxes = {
				...this.editCmtBoxes,
				[commentId]: !this.showEditCmtBox(commentId),
			};
		},
		showEditCmtBox(commentId) {
			return this.editCmtBoxes[commentId] || false;
		},

		// ngoại trừ các class này thì khi click tất cả các vị trí trong html đều reset về lại như cũ 
		handleClickOutside(event) {
			const modals = document.querySelectorAll('.container-edit-delete, .edit-cmt-box'); 
			let clickedInsideModal = false;

			// Duyệt qua tất cả các modal và kiểm tra xem sự kiện click có nằm trong modal nào không
			modals.forEach(modal => {
				if (modal.contains(event.target)) {
					clickedInsideModal = true;
				}
			});

			// Nếu không click vào modal nào, thì đóng tất cả các modal
			if (!clickedInsideModal) {
				this.modalEditCmtBoxes = {};
				this.editCmtBoxes = {}; // đóng hộp chỉnh sửa 
			}
		},
		resetModal() {
			this.modalEditCmtBoxes = {}; // đóng modal 
			this.editCmtBoxes = {}; // đóng hộp chỉnh sửa 
		}
	},
	watch: {
		// đi đến comment có id = "div-comment-id"
        // Theo dõi thay đổi của route, nếu có thay đổi hash trong URL thì cuộn đến phần tử mới
        '$route'(to, from) {
			console.log(from);
            const hash = to.hash.substring(1);
            if (hash) {
                const element = document.getElementById(hash);
                if (element) {
					// // console.log(element);
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }
        }
    },
};
</script>

<style scoped src="./comment-comp-responsive.css"></style>
<style scoped>
.container-comment {
	display: flex;
	margin-bottom: 8px;
}

.avatar-user {
	margin-right: 10px;
	cursor: pointer;
}

.main-user {
	width: 100%;
	margin-bottom: 10px;
}

.avatar-user img {
	min-width: 40px;
	max-width: 40px;
	height: 40px;
	object-fit: cover;
	object-position: center;
	border-radius: 50%;
}

.name-user {
	font-weight: bold;
	cursor: pointer;
}

.infor-user {
	display: flex;
	width: fit-content;
	align-items: center;
	width: 100%;
}

.infor-user-bg {
	max-width: 95%;
	border-radius: 10px;
	padding: 8px 12px;
	background-color: #F0F2F5;
}

.show-comment {
	cursor: pointer;
	margin-left: 8px;
}

.show-comment:hover {
	text-decoration: underline;
}

.container-reply {
	width: 100%;
}

.container-reply-btn {
	margin-top: 3px;
	margin-left: 8px;
	display: flex;
	align-items: center;
}
.sp-date {
	margin-right: 10px;
	font-size: 12px;
	color: #007BFF;
}
.sp-reply {
	cursor: pointer;
}

.sp-reply:hover {
	text-decoration: underline;
}

/* group edit and delete comment */
.container-edit-delete {
	position: relative;
	display: none;
}

.main-user:hover .container-edit-delete {
	display: block;
}

.open-modal-edit button {
	border-radius: 100%;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 6px;
}

.open-modal-edit button:hover {
	background-color: #F0F2F5;
}

.modal-edit-delete-cmt {
	width: max-content;
	background-color: white;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	position: absolute;
	bottom: 45px;
	right: 10px;
	padding: 6px;
	border-radius: 10px;
}

.p-edit-delete {
	cursor: pointer;
	font-weight: bold;
	border-radius: 6px;
	padding: 6px;
}

.p-edit-delete:hover {
	background-color: rgb(229, 229, 229);
}

.p-edit:hover {
	color: #007BFF;

}

.p-delete:hover {
	color: red;
}
</style>
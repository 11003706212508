<template>
    <div class="home-footer">
        <div class="top-footer">
            <div class="top-footer-inner">
                <p class="title-contact">LIÊN HỆ</p>
                <ul class="ul-infor">
                    <li><span class="square-icon"><i class="fa-solid fa-square"></i></span> <span><i class="fa-solid fa-mobile-screen-button"></i></span> <a target="_blank" class="a-infor" href="tel:+097 515 9695">097 515 9695</a></li>
                    <li><span class="square-icon"><i class="fa-solid fa-square"></i></span> <span><i class="fa-solid fa-envelope"></i></span> <a class="a-infor" target="_blank" href="mailto:lehongphuongweb@gmail.com"> lehongphuongweb@gmail.com</a></li>
                    <li><span class="square-icon"><i class="fa-solid fa-square"></i></span> <span><i class="fa-solid fa-house"></i></span> <a class="a-infor" target="_blank" href="https://techbim.vn">https://techbim.vn</a></li>
                </ul>
            </div>
            <div class="top-footer-inner">
                <p class="title-contact">HỖ TRỢ HỌC VIÊN</p>
                <ul class="ul-infor">
                    <li><span class="square-icon"><i class="fa-solid fa-square"></i></span> <a class="a-infor" href="#">Hỗ trợ học viên</a></li>
                    <li><span class="square-icon"><i class="fa-solid fa-square"></i></span> <a class="a-infor" href="#"> Câu hỏi thường gặp</a></li>
                </ul>
            </div>
            <div class="top-footer-inner">
                <p class="title-contact">CHÍNH SÁCH ĐIỀU KHOẢN</p>
                <ul class="ul-infor">
                    <li><span class="square-icon"><i class="fa-solid fa-square"></i></span> <a class="a-infor" href="#">Điều khoản dịch vụ</a></li>
                    <li><span class="square-icon"><i class="fa-solid fa-square"></i></span> <a class="a-infor" href="#">Chính sách bảo mật</a></li>
                </ul>
            </div>
        </div>
        <div class="bottom-footer">
            <div class="social text-center">
                <a target="_blank" href="https://www.facebook.com/profile.php?id=100075925281383" class="mr-2"><span ><i class="fa-brands fa-facebook-f"></i></span></a> <a href="https://www.youtube.com/@phuongdrawingvn" target="_blank"><span><i class="fa-brands fa-youtube"></i></span></a>
            </div>
            <div class="copyright">
                <p class="text-center">COPYRIGHT 2020 TECH BIM, ALL RIGHT RESERVED</p>
                <p class="text-center">Made with <span class="text-danger"><i class="fa-solid fa-heart"></i></span> by TECH BIM</p>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "HomeFooter",
    components: {

    },
}
</script>
<style src="./home-footer-responsive.css"></style>
<style scoped>
.home-footer {
    /* margin-top: 10px; */
    background-color: #4F4F4F;
    color: white;
    padding: 60px 10%;
    padding-bottom: 10px;
}

.top-footer {
    display: flex;
    justify-content: space-between;
}

.top-footer-inner {
    width: 30%;
}

.title-contact {
    font-size: 20px ;
}

.ul-infor li {
    background-color: #4D4D4D;
    padding: 10px 0px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    font-size: 14px;
    position: relative;
}

a:hover {
    color: white;
}

.ul-infor li:before {
  content : "";
  position: absolute;
  left    : 0;
  bottom  : 0;
  height  : 1px;
  width   : 20%;  
  border-bottom:1px solid rgb(89, 89, 89);
}
.square-icon {
    font-size: 6px;
    margin-right: 20px;
    color: #A8D0E6;
}
.a-infor {
    margin-left: 6px;
}

/* bottom-footer */
.bottom-footer {
    margin-top: 20px;
}

.social {
    font-size: 30px;
    display: block;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

</style>
<template>
    <div class="row mr-2 recent-articles">
        <div v-if="isLoading" class="d-flex justify-content-center container-loader">
            <flower-spinner class="loading-component" :animation-duration="2000" :size="30" color="#06C755" />
        </div>
        <div v-if="!isLoading">
            <div class="title-header">BÀI VIẾT GẦN ĐÂY</div>
            <ul>
                <li class="container-article" v-for="(record, index) in records" :key="index" @click="gotoArticleDetail(record)">
                    <div class="recent-image">
                        <img :src="record.article_image ? record.article_image : require('@/assets/admin/image-default.jpg')" alt="">
                    </div>
                    <div class="recent-title">
                        {{ this.$truncatedTitle(record.article_title,80) }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import UserRequest from '@/restful/UserRequest';
import { FlowerSpinner } from 'epic-spinners';
export default {
    name: "RecentArticles",
    components: {
        FlowerSpinner,
    },
    data() {
        return {
            isLoading: false,
            records: null,
        }
    },
    mounted() {
        this.getDataRecords();
    },
    beforeUnmount() {

    },
    methods : {
        gotoArticleDetail: function(record) {
            this.$router.push({ name: 'HomeArticleDetail', params: { slug_name_article: record.article_slug + '-id-record-' + record.id } });
        },
        getDataRecords: async function () {
            this.isLoading = true;
            try {
                const { data } = await UserRequest.get('home/article/recent-articles')
                this.records = data;
                this.isLoading = false;
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
                this.isLoading = false;
            }
        },
       
    },
    watch: {
       
    }
}
</script>
<style scoped src="./recentarticles-responsive.css"></style>
<style scoped>
.loading-component {
    margin: 150px 0px
}

</style>
import { createStore } from "vuex";

const storeConfigs = createStore({
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {

    }
});

export default storeConfigs
<template>
    <div class="home-course">
        <div class="row m-0 pb-2 d-flex search-sort" id="search-sort">
            <div class="form-search pl-0">
                <div content="Tìm kiếm theo tên Thư viện" v-tippy class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text"><i class="fa-solid fa-magnifying-glass"></i></div>
                    </div>
                    <input v-model="search.library_name" type="text" class="form-control " id="inline-form-input-group"
                        placeholder="Tìm kiếm theo tên Thư viện">
                </div>
            </div>
            <div class="form-sort pl-0">
                <select content="Sắp xếp theo" v-tippy class="form-control " v-model="big_search.order_by">
                    <option value="id">Mới nhất</option>
                    <option value="library_price">Giá</option>
                    <option value="library_name">Tên</option>
                </select>
            </div>
            <div class="form-sort pl-0">
                <select content="Kiểu sắp xếp" v-tippy class="form-control " v-model="big_search.order_direction">
                    <option value="asc">Tăng dần</option>
                    <option value="desc">Giảm dần</option>
                </select>
            </div>
        </div>
        <div v-if="isLoading" class="d-flex justify-content-center container-loader">
            <flower-spinner class="loading-component" :animation-duration="2000" :size="30" color="#06C755" />
        </div>
        <ul v-if="!isLoading" class="ul-course">
            <li class="course-item" v-for="(record, index) in records" :key="index" @click="gotoRecordDetail(record)">
                <img class="img-cover-course" :src="record.library_image ? record.library_image : require('@/assets/home/item-source.jpg')"
                    alt="Course Thumbnail">
                <div class="content">
                    <h2 class="h2-name-course">{{ this.$truncatedTitle(record.library_name, 100) }}</h2>
                    <p class="p-author"><img :src="require('@/assets/home/avatar-logo.jpg')" alt=""><span>Tech BIM</span></p>
                    <h1 class="h1-price">{{ this.$formatNumber(record.library_price) }} VNĐ</h1>
                </div>
            </li>
        </ul>
        <div v-if="!isLoading" id="divpaginate" class="mt-2">
            <paginate v-if="paginateVisible" :page-count="last_page" :page-range="3" :margin-pages="1"
                :click-handler="clickCallback" :initial-page="big_search.page" :prev-text="'Prev'" :next-text="'Next'"
                :container-class="'pagination'" :page-class="'page-item'">
            </paginate>
        </div>
    </div>
</template>
<script>
import UserRequest from '@/restful/UserRequest';
import Paginate from 'vuejs-paginate-next';
import { FlowerSpinner } from 'epic-spinners';
import _ from 'lodash';
export default {
    name: "HomeLibrary",
    components: {
        FlowerSpinner,
        paginate: Paginate,
    },
    data() {
        return {
            isLoading: false,
            total: 0,
            last_page: 1,
            paginateVisible: true,
            search: {
                library_name: '',
            },
            big_search: {
                per_page: 6, // const 
                page: 1,
                order_by: 'id',
                order_direction: 'desc',
            },
            query: '',
            records: null,
        }
    },
    mounted() {
        this.$setDocumentTitle('Danh sách Thư viện');
        this.$emitEvent('NameRouting', 'Thư viện');

        const queryString = window.location.search;
        const searchParams = new URLSearchParams(queryString);
        this.search.library_name = searchParams.get('library_name') || '';
        this.big_search = {
            per_page: parseInt(searchParams.get('per_page')) || 6,
            page: searchParams.get('page') || 1,
            order_by: searchParams.get('order_by') || 'id',
            order_direction: searchParams.get('order_direction') || 'desc',
        }

        this.getDataRecords();
    },
    beforeUnmount() {
        this.$emitEvent('NameRouting', '');
    },
    methods: {
        gotoRecordDetail: function (record) {
            this.$router.push({ name: 'HomeLibraryDetail', params: { slug_name_library: record.library_slug + '-id-record-' + record.id } });
        },
        getDataRecords: async function () {
            this.isLoading = true;
            this.query = `?library_name=${this.search.library_name}&order_by=${this.big_search.order_by}&order_direction=${this.big_search.order_direction}&page=${this.big_search.page}&per_page=${this.big_search.per_page}`;
            // window.history.pushState({}, null, this.query);
            window.history.replaceState({}, null, this.query);
            try {
                const { data } = await UserRequest.get('user/library/get-all' + this.query)
                this.records = data.data;
                this.total = data.total;
                this.last_page = data.last_page;
                this.isLoading = false;
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
                this.isLoading = false;
            }
            this.reRenderPaginate();
        },
        reRenderPaginate: function () {
            if (this.big_search.page > this.last_page) this.big_search.page = this.last_page;
            this.paginateVisible = false;
            this.$nextTick(() => { this.paginateVisible = true; });
        },
        clickCallback: function (pageNum) {
            this.big_search.page = pageNum;
        },
    },
    watch: {
        big_search: {
            handler: function () {
                this.getDataRecords();
            },
            deep: true
        },
        search: {
            handler: _.debounce(function () {
                this.getDataRecords();
            }, 500),
            deep: true,
        },
    }
}
</script>
<style scoped src="./homelibrary-responsive.css"></style>
<style scoped>
/* loading */
.container-loader {
    height: 300px;
    align-items: center;
    align-content: center;
    display: flex !important;
    justify-content: center;
}

.container-loader .loading-component {
    margin: 0 !important;
}

/* loading */

.home-course {
    /* background-color: #FFFFFF; */
    background-image: linear-gradient(180deg, #eeeeee 0%, #ffffff 100%);
    padding: 20px 10%;
}

.container-course {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    padding: 0px 15%;
}

.ul-course {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    list-style-type: none;
}

li.course-item {
    cursor: pointer;
    box-sizing: border-box;
    list-style: none;
    display: flex;
    border: 1px solid #ddd;
    background-color: white;
    /* border-radius: 10px; */
    margin: 10px;
    /* padding: 10px; */
    transition: all 0.5s ease;
    scale: 1;
}

li.course-item:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
    scale: 1.03;
}

li.course-item>img {
    width: 300px;
    height: 200px;
    object-fit: cover;
    /* border-radius: 10px; */
    margin-right: 20px;
}

li.course-item .content {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
}

li.course-item .content h2 {
    font-size: 18px;
    margin: 0 0 10px;
}

li.course-item .content p {
    font-size: 14px;
    color: #666;
}

.content .p-author {
    display: flex;
    margin-top: 6px;
    margin-bottom: 10px;
}

.content .p-author img {
    max-width: 20px;
    border-radius: 100px;
    margin-right: 6px;
}

.content h1 {
    font-size: 20px;
    font-weight: 500;
}
</style>
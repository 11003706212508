<template>
    <div>
        <div id="big">
            <div class="bigContainer">
                <div class="modal fade" id="updateRecord" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel"><strong>
                                    <i class="fa-solid fa-pen-to-square"></i> Chỉnh sửa bài viết</strong></h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" class="text-danger"><i
                                            class="fa-regular fa-circle-xmark"></i></span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="updateRecord()">
                                    <div class="form-group">
                                        <label><strong>Tiêu đề bài viết</strong></label>
                                        <input v-model="record.article_title" type="text"
                                            class="form-control form-control-sm" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Tiêu đề bài viết">
                                        <span v-if="errors.article_title" class="text-danger">{{ errors.article_title[0]
                                            }}<br></span>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                        <div class="big-container-image">
                                            <div class="inner-image-upload">
                                                <label class="bold"><i class="fa-solid fa-wand-magic-sparkles"></i> Ảnh bìa</label>
                                                <div class="min-image-upload">
                                                    <input class="input-file" type="file" @change="previewImage"
                                                        accept="image/*" ref="fileInput" />
                                                    <span class="iconClound" v-if="previewImageSrc == null"><i
                                                            class="fa-solid fa-cloud-arrow-up"></i></span>
                                                    <div v-if="previewImageSrc" class="box-preview">
                                                        <img class="preview" :src="previewImageSrc" alt="Preview" />
                                                        <img src="@/assets/error.png" @click="removeFileImage"
                                                            class="close-img" alt="Remove">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span v-if="errors.article_image" class="text-danger">{{ errors.article_image[0] }}<br></span>
                                    </div>
                                    </div>
                                    <div class="form-group mt-2">
                                        <label><strong>Nội dung bài viết</strong></label>
                                        <div>
                                            <RichTextEditor v-model="record.article_content" />
                                        </div>
                                        <span v-if="errors.article_content" class="text-danger">{{
                                            errors.article_content[0] }}<br></span>
                                    </div>
                                    <button type="submit" class="btn-pers" id="login_button"><i class="fa-solid fa-paper-plane"></i> Cập nhật</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserRequest from '@/restful/UserRequest';
import RichTextEditor from '@/components/common/RichTextEditor'

export default {
    name: "UpdateRecord",
    components: {
        RichTextEditor,
    },
    setup() {

    },
    props : {
    },
    data() {
        return {
            previewImageSrc: null,
            linkImageSrc: null,
            updateImage: false,
            updateFile: false,
            record: {
                article_title: '',
                article_content: ``,
                article_image: null,
            },
            errors: {
                article_title: null,
                article_content: null,
                article_image: null,
            }
        }
    },
    mounted() {
        this.$onEvent('eventSelectedRecord', (recordSelected) => {
            this.record = { ...recordSelected };
            this.previewImageSrc = this.record.article_image;
            this.linkImageSrc = this.record.article_image;
        });
    },
    created() {
    },
    computed: {

    },
    methods: {
        previewImage(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.previewImageSrc = e.target.result;
                    this.record.article_image = file;
                    this.updateImage = true;
                };
                reader.readAsDataURL(file);
            } else this.removeFileImage();
        },
        removeFileImage: function () {
            // this.record.course_image = this.previewImageSrc;
            this.previewImageSrc = null;
            // this.user.avatar = null;
            this.$refs.fileInput.value = '';
            this.updateImage = false;
        },
        updateRecord: async function () {
            try {
                const formData = new FormData();
                var fields = ['id', 'article_title', 'article_content'];
                for (let key in fields) formData.append(fields[key], this.record[fields[key]]);
                if (this.updateImage) {
                    formData.append('article_image', this.record.article_image);
                } else {
                    formData.append('article_image', this.linkImageSrc);
                }
                
                const { messages } = await UserRequest.post(`admin/article/update/${this.record.id}`, formData, true);
                this.$emitEvent('eventSuccess', messages[0]);
                for (let key in this.errors) this.errors[key] = null;
                var closePW = window.document.getElementById('updateRecord');
                closePW.click();
                this.$emitEvent('eventRegetDataRecords', '');
            }
            catch (error) {
                // console.log(error);
                if (error.errors) this.errors = error.errors;
                else for (let key in this.errors) this.errors[key] = null;
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
            }

        },
    },
    watch: {

    },
}
</script>

<style scoped src="@/components/modal-responsive.css"></style>
<style scoped>

/* image upload */
.big-container-image {
    display: flex;
    align-items: center;
    align-content: center;
    /* height: 100%; */
}

.inner-image-upload {
    height: 50%;
    width: 100%;
}

.min-image-upload {
    background-color: #e9ecef;
    position: relative;
    text-align: center;
    /* width: 170px; */
    height: 170px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    width: 100%;
}

.min-image-upload .preview {
    /* width: 150px; */
    height: 150px;
    object-fit: cover;
    border-radius: 6px;
    cursor: default;
    width: 100%;
}

.min-image-upload:hover {
    transition: all 0.5s ease;
    background: #E8F5E9;
}

.input-file {
    opacity: 0;
    top: 0px;
    left: 0px;
    position: absolute;
    cursor: pointer;
    /* width: 150px; */
    height: 150px;
    width: 100%;
}

.box-preview {
    position: relative;
}

.iconClound {
    cursor: pointer;
    font-size: 60px;
    color: var(--user-color);
}

.close-img {
    position: absolute;
    top: -6px;
    right: -6px;
    width: 16px;
}

/* image upload */

/*  */
.modal-dialog {
    max-width: 1000px;
}

/*  */


.modal.fade.show {
    padding-left: 0px;
}

.modal-content {
    /* margin-top: 100px; */
    border-radius: 10px;
}

.bigContainer .input-form {
    height: 40px;
    width: 100%;
    position: relative;
}

.bigContainer .input-form input {
    height: 100%;
    width: 100%;
    border: none;
    font-size: 17px;
    border-bottom: 2px solid silver;
    outline: none !important;
}

.input-form input:focus~label,
.input-form input:valid~label {
    transform: translateY(-20px);
    font-size: 15px;
    color: var(--user-color);
}

.input-form .underline.fix2:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: var(--user-color);
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease;
}

.bigContainer .input-form label {
    position: absolute;
    bottom: 0px;
    left: 0;
    color: grey;
    pointer-events: none;
    transition: all 0.3s ease;
}

.input-form .underline {
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: 0;
}

.input-form .underline:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: var(--user-color);
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease;
}

.input-form input:focus~.underline:before,
.input-form input:valid~.underline:before {
    transform: scaleX(1);
}

@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi+Ink');

#big {
    display: flex;
    position: relative;
}

.btn-pers {
    position: relative;
    left: 50%;
    padding: 1em 2.5em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 700;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    transform: translateX(-50%);
}

.btn-pers:hover {
    background-color: var(--user-color);
    box-shadow: var(--btn-hover);
    color: #fff;
    transform: translate(-50%, -7px);
}

.btn-pers:active {
    transform: translate(-50%, -1px);
}

#inputPassword {
    padding-right: 26px;
}
</style>

<template>
    <div>
        <div v-if="article" class="modal fade" id="modalShare" tabindex="-1" role="dialog" aria-labelledby="modalShare"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Chia sẻ</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="link-share mx-2">
                            <div class="copy-content mt-2 row pt-0 pb-0">
                                <input readonly class="p-0 col-9 form-control form-control-sm" ref="textToCopy"
                                    :value="this.getFullUrl(article)"
                                    type="text">
                                <div class="p-2 col-3">
                                    <button @click="copyText" type="button" class="col-12 p-0 btn btn-primary">Sao
                                        chép</button>
                                </div>
                            </div>
                        </div>
                        <div class="container-social mt-2">
                            <share-network v-for="network in networks" :key="network.network" v-slot="{ share }"
                                :network="network.network" :title="sharingInfo.title"
                                :url="this.getFullUrl(article)"
                                :description="sharingInfo.description" :quote="sharingInfo.quote"
                                :hashtags="sharingInfo.hashtags" :twitterUser="sharingInfo.twitterUser">
                                <div class="social-network" @click="share" :style="{ backgroundColor: network.color }">
                                    <i :class="network.icon"></i>
                                    <span>{{ network.name }}</span>
                                </div>
                            </share-network>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Đóng</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalShare",
    props: {
        article: Object,
    },
    mounted() {

    },
    data() {
        return {
            sharingInfo: {
                title: 'My perfect test title',
                url: 'https://academy-pro.vercel.app/sharing2',
                description: 'My perfect description',
                quote: 'My perfect quote',
                hashtags: '#Le_Hong_Phuong,#BIM_Academy',
                twitterUser: 'LeHongPhuong',
                media: require('@/assets/avatar.jpg'),
            },
            networks: [
                {
                    network: 'facebook',
                    name: 'Facebook',
                    icon: 'fab fah fa-lg fa-facebook-f',
                    color: '#1877f2',
                },
                {
                    network: 'linkedin',
                    name: 'LinkedIn',
                    icon: 'fab fah fa-lg fa-linkedin',
                    color: '#007bb5',
                },
                {
                    network: 'skype',
                    name: 'Skype',
                    icon: 'fab fah fa-lg fa-skype',
                    color: '#00aff0',
                },
                {
                    network: 'x',
                    name: 'X',
                    icon: 'fab fah fa-lg fa-twitter',
                    color: '#000000',
                },
                {
                    network: 'whatsapp',
                    name: 'Whatsapp',
                    icon: 'fab fah fa-lg fa-whatsapp',
                    color: '#25d366',
                },
                {
                    network: 'telegram',
                    name: 'Telegram',
                    icon: 'fab fah fa-lg fa-telegram-plane',
                    color: '#0088cc',
                },
                {
                    network: 'flipboard',
                    name: 'Flipboard',
                    icon: 'fab fah fa-lg fa-flipboard',
                    color: '#e12828',
                },
                {
                    network: 'instapaper',
                    name: 'Instapaper',
                    icon: 'fas fah fa-lg fa-italic',
                    color: '#428bca',
                },
                {
                    network: 'line',
                    name: 'Line',
                    icon: 'fab fah fa-lg fa-line',
                    color: '#00c300',
                },
                {
                    network: 'email',
                    name: 'Email',
                    icon: 'far fah fa-lg fa-envelope',
                    color: '#333333',
                },
                {
                    network: 'pinterest',
                    name: 'Pinterest',
                    icon: 'fab fah fa-lg fa-pinterest',
                    color: '#bd081c',
                },
                {
                    network: 'reddit',
                    name: 'Reddit',
                    icon: 'fab fah fa-lg fa-reddit-alien',
                    color: '#ff4500',
                },
                {
                    network: 'odnoklassniki',
                    name: 'Odnoklassniki',
                    icon: 'fab fah fa-lg fa-odnoklassniki',
                    color: '#ed812b',
                },
                {
                    network: 'pocket',
                    name: 'Pocket',
                    icon: 'fab fah fa-lg fa-get-pocket',
                    color: '#ef4056',
                },
                {
                    network: 'quora',
                    name: 'Quora',
                    icon: 'fab fah fa-lg fa-quora',
                    color: '#a82400',
                },
                {
                    network: 'viber',
                    name: 'Viber',
                    icon: 'fab fah fa-lg fa-viber',
                    color: '#59267c',
                },
                { network: 'vk', name: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8' },
                {
                    network: 'weibo',
                    name: 'Weibo',
                    icon: 'fab fah fa-lg fa-weibo',
                    color: '#e9152d',
                },
                {
                    network: 'stumbleupon',
                    name: 'StumbleUpon',
                    icon: 'fab fah fa-lg fa-stumbleupon',
                    color: '#eb4924',
                },
                {
                    network: 'tumblr',
                    name: 'Tumblr',
                    icon: 'fab fah fa-lg fa-tumblr',
                    color: '#35465c',
                },
                {
                    network: 'wordpress',
                    name: 'Wordpress',
                    icon: 'fab fah fa-lg fa-wordpress',
                    color: '#21759b',
                },
                {
                    network: 'xing',
                    name: 'Xing',
                    icon: 'fab fah fa-lg fa-xing',
                    color: '#026466',
                },
                {
                    network: 'yammer',
                    name: 'Yammer',
                    icon: 'fab fah fa-lg fa-yammer',
                    color: '#0072c6',
                },
            ],
        }
    },
    methods: {
        copyText() {
            const text = this.$refs.textToCopy.value; // Lấy nội dung từ div
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    this.$emitEvent('eventSuccess', 'Đã sao chép thành công !');
                })
                .catch((err) => {
                    console.log(err);
                    this.$emitEvent('eventError', 'Sao chép thất bại !');
                });
        },
        getFullUrl(article) {
            const route = this.$router.resolve({
                name: 'HomeArticleDetail',
                params: { slug_name_article: article.article_slug  + '-id-record-' + article.id}
            });
            const fullUrl = `${window.location.origin}${route.href}`;
            return fullUrl;
        },
    },
}
</script>
<style scoped src="./modalshare-responsive.css"></style>
<style scoped>
.link-share input {
    background-color: black;
    color: white;
    padding: 0px !important;
}

.copy-content button {
    padding: 3px !important;
    border-radius: 20px;
}

.copy-content {
    background-color: black;
    color: white;
    border-radius: 10px;
    border: 1px solid silver;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.modal-open .modal {
    /* background-color: transparent; */
}

.container-social {
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* justify-content: space-between; */
    height: 50vh;
    overflow: hidden;
    overflow-y: scroll;
}

.logo {
    height: 6em;
    padding: 1.5em;
    will-change: filter;
    transition: filter 300ms;
}

.logo:hover {
    filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.vue:hover {
    filter: drop-shadow(0 0 2em #42b883aa);
}

.social-list {
    max-width: 900px;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 0.6rem;
}

.social-network {
    border-radius: 3px;
    margin: 3px 0px;
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
}

.social-network i {
    width: 50px;
    margin-left: 3px;
    padding: 1rem;
    display: flex;
    background-color: rgba(0, 0, 0, 0.3);
    justify-content: center;
}

.social-network span {
    font-weight: 700;
    padding: 0.7rem;
    text-shadow: 2px 1px 2px rgb(0 0 0 / 80%);
}
</style>
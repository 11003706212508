<template>
    <div>
        <div v-if="article" class="modal fade" id="modalLiked" tabindex="-1" role="dialog" aria-labelledby="modalLike"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body" id="body-list-like">
                        <template v-if="article.like_count > 0" >
                            <div id="title_like" class="bold">{{ article.like_user_ids.length }} người thích bài viết này !
                                <button style="outline: none" type="button" class="close" data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="list-like">
                                <div @click="this.$gotoHomePersonal(like_user.id)" v-for="(like_user, index3) in article.like_users" :key="index3" class="user_like">
                                    <a v-if="article.like_user_ids.includes(like_user.id)" href="#">
                                        <img :src="like_user.avatar ? like_user.avatar : require('@/assets/avatar.jpg')"
                                            alt="Avatar User">
                                        <span>{{ like_user.name }}</span>
                                    </a>
                                    <span v-if="article.like_user_ids.includes(like_user.id)" class="icon-heart"><i class="fa-solid fa-heart"></i></span>
                                </div>
                            </div>
                        </template>
                        <template v-else>Bài viết chưa được thích !</template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalLiked",
    props: {
        article: Object,
    },
}
</script>
<style scoped src="@/components/home/home-public/middle.css"></style>
<style scoped src="@/components/home/home-public/article-details.css"></style>
<style scoped>
#modalLiked .list-like {
    max-height: 60vh;
    overflow: hidden;
    overflow-y: scroll;
}

</style>
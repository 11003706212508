<template>
    <div>
        <div class="btn-open-notify">
            <!-- <p class="number-notify">{{ notifies ? notifies.length : 0 }}</p> -->
            <p class="number-notify">{{ count_no_seen }}</p>
            <button type="button" class="btn btn-outline-primary" data-toggle="modal" data-target="#openNotifyUser"><i
                    class="fa-solid fa-bell"></i></button>
        </div>
        <div class="container-notify">
            <div class="modal fade" id="openNotifyUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                <i class="fa-solid fa-bell"></i> Thông báo của bạn
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" class="text-danger"><i
                                        class="fa-regular fa-circle-xmark"></i></span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div>
                                    <div class="delete-all">
                                        <input type="checkbox" class="form-check-input" :checked="isAllSelected"
                                        ref="selectAllCheckbox" @change="selectAll()" /> Chọn tất cả
                                        <button @click="deleteNotify()" v-if="selectedNotifies.length > 0" type="button" class="btn btn-outline-danger btn-sm ml-6"><i
                                                class="fa-solid fa-trash"></i></button>
                                    </div>
                                </div>
                                <ul class="ul-notify">
                                    <li :class="{'no-seen': notify.is_seen === 0}" class="li-item-notify" v-for="(notify, index) in notifies" :key="index">
                                        <input type="checkbox" :checked="isSelected(notify.id)"
                                        @change="handleSelect(notify.id)" />
                                        <img @click="gotoLesson(notify)"  class="img-notify"
                                            :src="notify.avatar ? notify.avatar : require('@/assets/avatar.jpg')"
                                            alt="Avatar User">
                                        <div @click="gotoLesson(notify)" class="p-notify">
                                            <!-- Kiểm tra loại thông báo và hiển thị thông điệp tương ứng -->
                                            <!-- <span v-if="notify.notify_type === 'root_comment'">
                                                Học viên
                                            </span>  -->
                                            <span class="user_name_notify">{{ notify.from_user_name }} </span>
                                            <span v-if="notify.notify_type === 'mention'">
                                                đã nhắc đến bạn trong bài học
                                            </span>
                                            <span v-if="notify.notify_type === 'reply'">
                                                đã phản hồi bình luận của bạn trong bài học
                                            </span>
                                            <span v-if="notify.notify_type === 'root_comment'">
                                                đã bình luận trong bài học
                                            </span>
                                            <span class="lesson_name_notify"><strong> {{ this.$truncatedTitle(notify.lesson_name,100) }}. </strong></span>
                                            <span class="sp-icon sp-icon-mention" v-if="notify.notify_type === 'mention'">
                                                <i class="fa-solid fa-at"></i>
                                            </span>
                                            <span class="sp-icon sp-icon-reply" v-if="notify.notify_type === 'reply'">
                                                <i class="fa-solid fa-reply"></i>
                                            </span>
                                            <span class="sp-icon sp-icon-root_comment" v-if="notify.notify_type === 'root_comment'">
                                                <i class="fa-regular fa-comment-dots"></i>
                                            </span>
                                            <div class="mt-1">
                                                <span class="sp-time-notify">{{ this.$formatDateTime(notify.created_at) }}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="isLoading" class="d-flex justify-content-center container-loader">
                                <flower-spinner class="loading-component" :animation-duration="2000" :size="30"
                                    color="#06C755" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import UserRequest from '@/restful/UserRequest';
import { FlowerSpinner } from 'epic-spinners';
import { 
	// setDoc, 
	// serverTimestamp, 
	// doc, 
	// addDoc, 
	// updateDoc, 
	// deleteDoc, 
	query, 
	orderBy, 
	limit, 
	onSnapshot, 
} from 'firebase/firestore';
import { 
	notifiesRef, 
} from '@/firebase';

export default {
    name: "NotifyUser",
    components: {
        FlowerSpinner,
    },
    props: {

    },
    data() {
        return {
            isLoading: false,
            notifies: null,
            count_no_seen: 0,
            selectedNotifies: [], // Mảng lưu id của các thông báo được chọn
            user: {
				id: null,
				email: null,
                google_id: null,
                is_have_password: null,
				user_name: null,
				role: null,
				is_delete: null,
				is_block: null,
				name: null,
				phone: null,
				email_verified_at: null,
				created_at: null,
				updated_at: null,
				access_token: null,
				token_type: null,
				expires_in: null,
			},
        }
    },
    created() {
        this.getNotifyRealtime();
    },
    mounted() {
		this.user = JSON.parse(window.localStorage.getItem('user'));
        this.getNotify();
    },
    methods: {
        gotoLesson: function (record) {
            this.seenNotify(record);
            // console.log(record);
            // Tạo slug cho khóa học và bài học
            const courseSlug = record.course_slug + '-id-record-' + record.course_id;
            const lessonSlug = record.lesson_slug + '-id-record-' + record.lesson_id;

            // Kiểm tra nếu có `comment_id` thì thêm phần hash vào URL
            const commentId = record.comment_id ? `#div-${record.comment_id}` : '';

            // Điều hướng đến LessonAndChat với cả params và hash
            this.$router.push({
                name: 'LessonAndChat',
                params: {
                    slug_name_course: courseSlug, // Thêm slug_course
                    slug_name_lesson: lessonSlug  // Thêm slug_name_lesson
                },
                hash: commentId // Thêm phần hash vào URL
            });

            // const commentSection = document.getElementById(commentId);
            // if (commentSection) {
            //     commentSection.scrollIntoView({ behavior: 'smooth' });
            // }
        },
        seenNotify: async function (record) {
            this.isLoading = true;
            try {
                const { messages } = await UserRequest.get(`user/notify/seen/${record.id}`)
                if(messages[0]) // console.log(messages[0]);
                this.getNotify();
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
                this.isLoading = false;
            }
        },
        getNotify: async function () {
            this.isLoading = true;
            try {
                const { data } = await UserRequest.get('user/notify/get-all')
                // console.log(data);
                this.notifies = data.notifies;
                this.count_no_seen = data.count_no_seen;
                this.isLoading = false;
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
                this.isLoading = false;
            }
        },
        isSelected(notify_id) {
            return this.selectedNotifies.includes(notify_id);
        },
        handleSelect: function (notify_id) {
            const index = this.selectedNotifies.indexOf(notify_id);
            if (index === -1) this.selectedNotifies.push(notify_id);
            else this.selectedNotifies.splice(index, 1);
        },
        selectAll: function () {
            const checkbox = this.$refs.selectAllCheckbox;
            if (checkbox.checked) this.selectedNotifies = this.notifies.map(notify => notify.id);
            else this.selectedNotifies = [];
        },
        deleteNotify: async function () {
            try {
                var dataSubmit = {
                    ids_notify: this.selectedNotifies
                };
                const { messages } = await UserRequest.post('user/notify/deletes', dataSubmit)
                // console.log(messages);
                this.$emitEvent('eventSuccess', messages[0]);
                this.getNotify();
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
            }
        },
        getNotifyRealtime: async function () {
            const latestNotifyQuery = query(
                notifiesRef,
                orderBy('fb_created_at', 'desc'),
                limit(1)
            );
            onSnapshot(latestNotifyQuery, (snapshot) => {
                if (!snapshot.empty) {
                    const doc = snapshot.docs[0];
					const data = doc.data(); 
                    // console.log(data);
                    // Chuyển đổi ids_notify_to thành mảng các giá trị
                    const notifyToIds = Object.values(data.ids_notify_to);
                    // Kiểm tra user ID
                    if (notifyToIds.includes(this.user.id)) { 
                        this.getNotify();
                        // console.log(data.fb_lesson_id);
                        // console.log(data);
                    }
				}
            });
        },
    }
}
</script>
<style>
    body {
        padding-right: 0px !important;
    }
    body.modal-open {
        padding-right: 0px !important;
    }
    .modal-backdrop.fade.show {
        position: unset !important;
    }
</style>
<style scoped>
/* modal */
.btn-open-notify {
    position: relative;
    font-weight: bold;
}
.number-notify {
    top: 4px;
    right: 4px;
    position: absolute;
    color: white;
    font-weight: bold;
    background-color: red;
    border-radius: 10px;
    width: fit-content;
    display: flex;
    padding: 0px 2px;
    min-width: 16px;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 9px;
    height: 16px;
}
.modal-header .close {
    outline: none;
}
.modal-open .modal {
    background-color: transparent;
}
.modal-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: .3rem;
    outline: 0;
    right: -100%;
    top: 30px;
}
.modal-dialog {
    /* max-width: 800px; */
}
/* modal */

/* li-item-notify */
.no-seen {
    background-color: #afeeff84;
}

.ul-notify {
    height: 60vh;
    overflow: hidden;
    overflow-y: scroll;
}
.li-item-notify {
    cursor: pointer;
    display: flex;
    padding: 6px;
    border-radius: 6px;
    margin: 3px 0px;
    /* align-items: center; */
    /* align-content: center; */
    position: relative;
}
.li-item-notify:hover {
    background-color: rgb(228, 228, 228);
}

.img-notify {
    min-width: 50px;
    max-width: 50px;
    border-radius: 100%;
    min-height: 50px;
    height: 50px;
    object-fit: cover;
    margin: 0px 10px;
}
.p-notify {
    line-height: 100%;
}
.user_name_notify {
    font-weight: bold;
    /* color: #2E92FF; */
}

/* delete-all */
.delete-all {
    width: 100%;
    padding-left: 26px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

/* sp-icon */
.sp-icon {
    position: absolute;
    top: 30px;
    left: 55px;
    border-radius: 100%;
    padding: 6px;
    color: white;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}
.sp-icon-mention {
    background-color: #DC3545;
}
.sp-icon-reply {
    background-color: #28A745;
}
.sp-icon-root_comment {
    background-color: #007BFF;
}
.sp-icon-like {
    background-color: rgb(255, 87, 116);
}
.sp-time-notify {
    color: rgb(162, 162, 162);
    color: #007BFF;
    font-size: 14px
}
.title-seen {
    font-size: 10px;
    margin: 0px 3px;
    background-color: #007BFF;
    border-radius: 3px;
    padding: 1px;
    color: white;
}


.cover-course {
    width: 100%;
    height: 40vh;
}

.inner-cover-course {
    border: 1px solid silver;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.cover-course img {
    object-fit: cover;
    height: 100%;
    margin: auto;
}

.course-video {
    margin-top: 60px;
}

.modal-dialog {
    max-width: 400px;
    font-size: 13px;
}

.sp-time-notify {
    font-size: 12px;
}

@media screen and (min-width: 1301px) {
    .modal-content {
        right: -110%;
    }
    .modal-header .close {
        font-size: 20px;
    }
}

@media screen and (max-width: 1300px) {
    .modal-content {
        right: -88%;
    }
}

/* Breakpoint: Màn hình 993px - 1200px (Tablet lớn) */
@media screen and (max-width: 1250px) {
    .modal-content {
        right: -85%;
    }
}

/* Breakpoint: Màn hình 993px - 1200px (Tablet lớn) */
@media screen and (max-width: 1200px) {
    .modal-content {
        right: -80%;
    }
    /* btn-open-notify */
    .btn-open-notify .number-notify {
        font-size: 8px;
        height: 14px;
        display: flex;
        border-radius: 100px;
        min-width: 14px;
        justify-content: center;
        align-items: center;
        align-items: center;
        top: 3px;
        right: 5px;
    }
    .btn-open-notify button {
        font-size: 13px;
        padding: 5px 10px;
    }
}

@media screen and (max-width: 1100px) {
    .modal-content {
        right: -72%;
    }
}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 1024px) {
    .img-notify {
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        height: 40px;
    }
    .sp-icon {
        font-size: 10px;
        padding: 4px;
    }
}

/* Breakpoint: Màn hình 769px - 992px (Tablet nhỏ) */
@media screen and (max-width: 992px) {
    .modal-dialog {
        max-width: 350px;
        font-size: 11px;
    }
    .modal-content {
        right: -70%;
    }
    .btn-open-notify {
        margin-right: 60px;
    }
    .modal-body {
        padding: 14px;
    }
    .sp-time-notify {
        font-size: 10px;
    }
}

@media screen and (max-width: 900px) {
    .modal-content {
        right: -60%;
    }
    .btn-open-notify {
        margin-right: 50px;
    }
}

@media screen and (max-width: 800px) {
    .modal-content {
        right: -50%;
    }
    .btn-open-notify {
        margin-right: 40px;
    }
}

/* Breakpoint: Màn hình 577px - 768px (Điện thoại lớn) */
@media screen and (max-width: 768px) {
    .modal-dialog {
        max-width: 320px;
        font-size: 9px;
    }
    .modal-content {
        right: -45%;
    }
    .modal-header .close {
        font-size: 11px;
    }
    .btn-open-notify {
        margin-right: 30px;
    }
    .alert {
        padding: 8px;
    }

    .img-notify {
        min-width: 30px;
        max-width: 30px;
        min-height: 30px;
        height: 30px;
    }
    .sp-icon {
        font-size: 8px;
        padding: 3px;
        top: 25px;
        left: 50px;
    }
}

@media screen and (max-width: 700px) {
    .modal-content {
        right: -35%;
    }
    .sp-time-notify {
        font-size: 8px;
    }
    .btn-open-notify {
        margin-right: 20px;
    }
}

/* Breakpoint: Màn hình 425px - 576px (Điện thoại vừa) */
@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 275px;
        font-size: 8px;
    }

    .modal-header,
    .modal-footer {
        padding: 5px;
    }

    .modal-content {
        right: -80%;
        top: 50px;
    }
    .btn-open-notify {
        margin-right: 10px;
    }
    .sp-time-notify {
        font-size: 6px;
    }

    .sp-icon {
        top: 22px;
        left: 46px;
        padding: 4px;
    }
}

@media screen and (max-width: 550px) {
    .modal-content {
        right: -70%;
    }
}

@media screen and (max-width: 500px) {
    .modal-content {
        right: -50%;
    }
}

@media screen and (max-width: 450px) {
    .modal-content {
        right: -35%;
    }
}


@media screen and (max-width: 400px) {
    .modal-content {
        right: -30%;
    }
}

/* Breakpoint: Màn hình <= 374px (Điện thoại nhỏ) */
@media screen and (max-width: 374px) {
    .modal-dialog {
        max-width: 260px;
        font-size: 7px;
    }
}

</style>
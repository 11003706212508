<template>
    <div>
        <div id="dashboard_left">
            <div class="main_right col-12 pl-0 pr-0 d-flex">
                <!-- <div class="logo_blog"><img src="@/assets/logo.png" /></div> -->
                <div class="form_search" content="Tìm kiếm theo tên tác giả hoặc tên bài viết" v-tippy>
                    <div class="input-group">
                        <input type="text" class="shadow-none form-control input-search-article input-border-radius"
                            v-model="search.search_text" id="text_search" placeholder="Nhập tên tác giả hoặc bài viết">
                        <div class="input-group-prepend">
                            <div class="input-group-text div-border-radius"><i class="fa-solid fa-magnifying-glass"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="search.search_text != ''" id="list_search">
                <div class="col-12 p-1" id="inner_search">
                    <div v-if="search_user != [] || search_article != []" class="item_user_article">
                        <div v-if="search_user">
                            <div @click="this.$gotoHomePersonal(user.id)" v-for="(user, index) in search_user" :key="index" class="item_user">
                                <img :src="user.avatar ? user.avatar : require('@/assets/avatar.jpg')"
                                    alt="Avatar User">
                                <span> {{ user.name }} </span>
                            </div>
                        </div>
                        <div v-if="search_article">
                            <div @click="gotoArticleDetail(article)" v-for="(article, index2) in search_article" :key="index2" class="item_article">
                                <i class="fa-solid fa-blog" style="margin-right: 8px;"></i> {{ article.article_title }}
                            </div>
                        </div>
                    </div>
                    <div v-if="search_user.length == 0 && search_article.length == 0" class="no_result"><i
                            class="fa-solid fa-magnifying-glass"></i> Không kết quả !</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import UserRequest from '@/restful/UserRequest';
import _ from 'lodash';

export default {
    name: "HomePublicLeft",
    props: {

    },
    data() {
        return {
            search_user: [],
            search_article: [],
            search: {
                search_text: '',
            },
        }
    },
    methods: {
        gotoArticleDetail: function(record) {
            this.$router.push({ name: 'HomeArticleDetail', params: { slug_name_article: record.article_slug + '-id-record-' + record.id } });
        },
        getDataRecords: async function () {
            this.isLoading = true;
            try {
                const { data } = await UserRequest.get(`home/search-author-or-article?&search_text=${this.search.search_text}`)
                this.search_user = data.search_user;
                this.search_article = data.search_article;
                this.isLoading = false;
            }
            catch (error) {
                if (error.messages) this.$emitEvent('eventError', error.messages[0]);
                this.isLoading = false;
            }
        },
    },
    watch: {
        search: {
            handler: _.debounce(function () {
                this.getDataRecords();
            }, 500),
            deep: true,
        },
    }
}
</script>
<style scoped src="./homepublicleft-responsive.css"></style>
<style scoped src="@/components/home/home-public/left.css"></style>
<style scoped></style>
<template>
    <div class="account_setting">
        <form class="col-12" @submit.prevent="updateProfile">
            <div class="row">
                <div class="colorTitle"><i class="fa-solid fa-pen-to-square"></i> Cài đặt tài khoản</div>
            </div>
            <div class="contact-info row">
                <div class="col-5">
                    <div class="row colorTitle bigTitle"><span><i class="fa-solid fa-square"></i>
                            THÔNG TIN</span></div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <label><i class="fa-solid fa-signature"></i> Tên </label><input required
                                v-model="user.name" placeholder="Full Name" type="text" class="form-control">
                            <span v-if="errors.name" class="text-danger">{{ errors.name[0] }}</span>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <label> <i class="fa-solid fa-graduation-cap"></i> Username</label>
                            <input class="form-control" v-model="user.user_name" placeholder="Username"
                                type="text">
                            <span v-if="errors.user_name" class="text-danger">{{ errors.user_name[0] }}</span>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <label><i class="fa-solid fa-user-check"></i> Role</label>
                            <input class="form-control" disabled :value="user.role === 1 ? 'Admin' : 'User'" placeholder="Role" type="text">
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <div class="row colorTitle bigTitle"><span><i class="fa-solid fa-mobile-screen-button"></i> THÔNG TIN LIÊN HỆ</span></div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <label for="formGroupExampleInput"><i class="fa-solid fa-phone"></i> Phone</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">+84</div>
                                </div>
                                <input type="text" required v-model="user.phone" class="form-control"
                                    placeholder="Number Phone">
                            </div>
                            <span v-if="errors.phone" class="text-danger">{{ errors.phone[0] }}</span>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <label><i class="fa-solid fa-envelope"></i> Email</label><input v-model="user.email"
                                placeholder="Email" disabled type="email" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="avatarUser col-2">
                        <div class="innerAvatar">
                            <label><i class="fa-solid fa-wand-magic-sparkles"></i> Ảnh đại diện</label>
                            <div class="minAvatar">
                                <input class="input-file" type="file" @change="previewImage" accept="image/*"
                                    ref="fileInput" />
                                <span class="iconClound" v-if="previewImageSrc == null"><i
                                        class="fa-solid fa-cloud-arrow-up"></i></span>
                                <div v-if="previewImageSrc" class="box-preview">
                                    <img class="preview" :src="previewImageSrc" alt="Preview" />
                                    <img src="@/assets/error.png" @click="removeFile" class="close" alt="Remove">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <button type="submit" class="btn-pers" id="login_button"><i class="fa-solid fa-floppy-disk"></i>
                    LƯU</button>
            </div>
        </form>
        <br>
        <hr>
        <div class="col-12 mt-3" v-if="user.is_have_password">
            <div class="row">
                <div class="colorTitle"><i class="fa-solid fa-key"></i> Đổi mật khẩu</div>
            </div>
            <div class="row">
                <ChangePassword></ChangePassword>
            </div>
        </div>
        <div class="col-12 mt-3" v-if="!user.is_have_password">
            <div class="row">
                <div class="colorTitle"><i class="fa-solid fa-key"></i> Tạo mật khẩu</div>
            </div>
            <div class="row">
                <CreateNewPassword></CreateNewPassword>
            </div>
        </div>
        <br>
    </div>
</template>

<script>
import UserRequest from '@/restful/UserRequest';
import useEventBus from '@/composables/useEventBus'
import ChangePassword from '@/components/user/account-setting/ChangePassword'
import CreateNewPassword from '@/components/user/account-setting/CreateNewPassword'
const { emitEvent } = useEventBus();

export default {
    name: "AccountSetting",
    data() {
        return {
            user: {
                id: null,
                email: null,
                google_id: null,
                is_have_password: null,
                role: null,
                name: null,
                user_name: null,
                phone: null,
                avatar: null,
                is_block: null,
                is_delete: null,
                email_verified_at: null,
                created_at: null,
                updated_at: null,
                expires_in: null,
                token_type: null,
                access_token: null,
            },
            previewImageSrc: null,
            updateImage: false,
            errors: {
                name: null,
                phone: null,
                user_name: null,
            }
        }
    },
    setup() {
        document.title = "Cài đặt tài khoản | Tech BIM";
    },
    async mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.previewImageSrc = this.user.avatar;
        emitEvent('eventTitleHeader', 'Cài đặt tài khoản');
        this.$emitEvent('eventActiveTab', '');

    },
    components: {
        ChangePassword,
        CreateNewPassword,
    },
    methods: {
        previewImage(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.previewImageSrc = e.target.result;
                    this.user.avatar = file;
                    this.updateImage = true;
                };
                reader.readAsDataURL(file);
            } else this.removeFile();
        },
        removeFile: function () {
            this.previewImageSrc = null;
            // this.user.avatar = null;
            this.$refs.fileInput.value = '';
            this.updateImage = false;
        },
        updateProfile: async function () { // (1)
            try {
                const formData = new FormData();
                var fields = ['name', 'user_name', 'phone'];
                for (var field of fields) formData.append(field, this.user[field]);
                const { data, messages } = await UserRequest.post('update-profile', formData, true);
                this.user.name = data.name;
                this.user.user_name = data.user_name;
                this.user.phone = data.phone;

                for (let key in this.errors) this.errors[key] = null; // reset
                localStorage.setItem('user', JSON.stringify(this.user));
                emitEvent('eventSuccess', messages[0]);
                emitEvent('updateProfileUser', JSON.stringify(this.user));
            } catch (error) {
                if (error.errors) {
                    this.errors = error.errors;
                } else {
                    for (let key in this.errors) {
                        this.errors[key] = null;
                    }
                }
                if (error.messages) error.messages.forEach(message => { emitEvent('eventError', message); });
            }

            // update avatar
            try {
                const formData = new FormData();
                if (this.updateImage) {
                    formData.append('avatar', this.user.avatar);
                    const { data, messages } = await UserRequest.post('update-avatar', formData, true);
                    this.user.avatar = data.avatar;
                    this.previewImageSrc = this.user.avatar;
                    this.updateImage = false;
                    localStorage.setItem('user', JSON.stringify(this.user));
                    emitEvent('eventSuccess', messages[0]);
                    emitEvent('updateProfileUser', JSON.stringify(this.user));
                }
                this.previewImageSrc = this.user.avatar;
                this.updateImage = false;

            } catch (error) {
                if (error.errors) {
                    this.errors = error.errors;
                } else {
                    for (let key in this.errors) {
                        this.errors[key] = null;
                    }
                }
                if (error.messages) error.messages.forEach(message => { emitEvent('eventError', message); });
            }
        }

    },
}

</script>
<style scoped>
.account_setting {
    font-weight: bold;
}

.account_setting input {
    color: #0085FF;
    font-weight: bold;
}

.account_setting label {
    color: var(--user-color);
    margin-bottom: 1px;
}

.colorTitle {
    color: gray;
}

.contact-info {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    padding: 0 15px
}

.bigTitle {
    margin-top: 10px;
    margin-bottom: 10px;
}

.groupCheckbox {
    border: 1px solid #ced4da;
    padding: 4px;
    padding-left: 10px;
    border-radius: 0.25rem;
    display: flex;
    align-content: center;
    align-items: center;
    height: 36px;
    background-color: rgba(255, 255, 255, 0.605);
}

.avatarUser {
    display: flex;
    align-items: center;
    align-content: center;
    height: 100%;
}

.innerAvatar {
    height: 50%;
}

.minAvatar {
    background-color: #e9ecef;
    position: relative;
    text-align: center;
    width: 170px;
    height: 170px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
}

.minAvatar .preview {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 6px;
    cursor: default;
}

.minAvatar:hover {
    transition: all 0.5s ease;
    background: #E8F5E9;
}

.input-file {
    opacity: 0;
    top: 0px;
    left: 0px;
    position: absolute;
    cursor: pointer;
    width: 150px;
    height: 150px;
}

.box-preview {
    position: relative;
}

.iconClound {
    cursor: pointer;
    font-size: 60px;
    color: var(--user-color);
}

.close {
    position: absolute;
    top: -6px;
    right: -6px;
    width: 16px;
}

@media screen and (min-width: 1201px) {

    .col-5,
    .col-2 {
        padding-right: 30px;
    }
}

@media screen and (max-width: 1200px) {
    .minAvatar {
        width: 150px;
        height: 150px;
    }

    .minAvatar .preview {
        width: 130px;
        height: 130px;
    }

    .col-5,
    .col-2 {
        padding-right: 2%;
    }
}

@media screen and (max-width: 992px) {
    .innerContent>div {
        padding: 8px 11px;
        font-size: 15px;
    }

    .minAvatar {
        width: 110px;
        height: 110px;
    }

    .minAvatar .preview {
        width: 100px;
        height: 100px;
    }

    .contact-info .col-12 {
        padding-right: 20px;
        padding-left: 3px;
    }

    .col-5,
    .col-2 {
        padding-right: 2%;
        padding-left: 1%;
        font-size: 14px;
    }

    .form-control,
    .groupCheckbox,
    .input-group-text {
        font-size: 13px !important;
    }
    .btn-pers {
        font-size: 11px;
    }
}

@media screen and (max-width: 768px) {
    .contact-info{
        flex-direction: column;
    }

    .innerContent>div {
        padding: 8px 11px;
        font-size: 13px;
    }

    .minAvatar {
        width: 150px;
        height: 150px;
    }

    .minAvatar .preview {
        width: 130px;
        height: 130px;
    }

    .contact-info .col-12 {
        padding-right: 20px;
        padding-left: 3px;
    }

    .col-5,
    .col-2 {
        padding: 0 5%;
        font-size: 13px;
        max-width: 100% !important;
    }

    .col-2{
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    .form-control,
    .groupCheckbox,
    .input-group-text {
        font-size: 13px !important;
    }

    .btn-pers {
        font-size: 11px;
    }
}

@media screen and (min-width: 375px) and (max-width: 576px) {
    .contact-info{
        flex-direction: column;
    }

    .innerContent>div {
        padding: 8px 11px;
        font-size: 12px;
    }

    .minAvatar {
        width: 150px;
        height: 150px;
    }

    .minAvatar .preview {
        width: 130px;
        height: 130px;
    }

    .contact-info .col-12 {
        padding-right: 20px;
        padding-left: 3px;
    }

    .col-5,
    .col-2 {
        padding: 0 5%;
        font-size: 13px;
        max-width: 100% !important;
    }

    .col-2{
        display: flex;
        justify-content: center;
        margin-top: 25px;
    }

    .form-control,
    .groupCheckbox,
    .input-group-text {
        font-size: 12px !important;
    }

    .btn-pers{
        font-size: 11px;
    }
}
</style>